import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GeneralArrayType = Record<string, any>;

export const getHeaderCounterText = (
  items: GeneralArrayType,
  selectedItems?: GeneralArrayType
) => {
  return selectedItems && selectedItems.length > 0
    ? `(${selectedItems.length}/${items.length})`
    : `(${items.length})`;
};

export const getFilterCounterText = (count: number): string =>
  `${count} ${count === 1 ? 'match' : 'matches'}`;

export const i18nFilterStrings: PropertyFilterProps.I18nStrings = {
  filteringAriaLabel: 'Filter Devices',
  filteringPlaceholder: 'Filter Devices',
  dismissAriaLabel: 'Dismiss',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',
  operationAndText: 'and',
  operationOrText: 'or',
  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',
  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',
  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
  removeTokenButtonAriaLabel: () => 'Remove token',
  enteredTextLabel: (text: string) => `Use: ${text}"`,
};
