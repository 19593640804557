import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DeviceFromDDV, LinkDevice, TreeDevice } from 'src/types';
import { Site } from 'src/API';
import { log } from 'src/utils/helpers';

export interface DevicesSliceInterface {
  allowedSites: Site[];
  childDevices: TreeDevice[];
  linkDevices: Record<string, LinkDevice>;
  linkingTrackingNumber: string | null;
  parentDevices: TreeDevice[];
  subchildDevices: TreeDevice[];
}

export const initialDevicesState: DevicesSliceInterface = {
  parentDevices: [],
  childDevices: [],
  subchildDevices: [],
  allowedSites: [],
  linkDevices: {},
  linkingTrackingNumber: null,
};

export const devicesSlice = createSlice({
  name: 'devicesState',
  initialState: initialDevicesState,
  reducers: {
    setDevices: (state, action: PayloadAction<DeviceFromDDV[]>) => {
      const payload: DeviceFromDDV[] = action.payload;
      if (!payload) return;
      const parents: TreeDevice[] = [];
      const children: TreeDevice[] = [];
      const subchildren: TreeDevice[] = [];

      for (const device of payload) {
        if (device.child_device_id == 0 && device.subchild_device_id == 0) {
          parents.push({
            ...device,
            deviceKey: `${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}`,
            invalidName: false,
            checked: false,
            expanded: false,
          });
        } else if (
          device.child_device_id != 0 &&
          device.subchild_device_id == 0
        ) {
          children.push({
            ...device,
            deviceKey: `${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}`,
            invalidName: false,
            checked: false,
            expanded: false,
          });
        } else {
          subchildren.push({
            ...device,
            deviceKey: `${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}`,
            invalidName: false,
            checked: false,
            expanded: false,
          });
        }
      }
      state.parentDevices = parents;
      state.childDevices = children;
      state.subchildDevices = subchildren;
    },
    setAllowedSites: (state, action: PayloadAction<Site[]>) => {
      state.allowedSites = action.payload;
    },
    setCheckedParent: (
      state,
      action: PayloadAction<{ checked: boolean; device: TreeDevice }>
    ) => {
      log(
        `Checking device ${action.payload.device.deviceKey} to ${action.payload.checked}`
      );
      const deviceIndex = state.parentDevices.findIndex(device => {
        return device.deviceKey == action.payload.device.deviceKey;
      });
      if (deviceIndex == -1) {
        log('No device found');
        return;
      }
      state.parentDevices[deviceIndex] = {
        ...state.parentDevices[deviceIndex],
        checked: action.payload.checked,
      };
    },
    setCheckedChild: (
      state,
      action: PayloadAction<{ checked: boolean; device: TreeDevice }>
    ) => {
      const deviceIndex = state.childDevices.findIndex(device => {
        return device.deviceKey == action.payload.device.deviceKey;
      });
      if (deviceIndex == -1) {
        log('No device found');
        return;
      }
      state.childDevices[deviceIndex] = {
        ...state.childDevices[deviceIndex],
        checked: action.payload.checked,
      };
    },
    setCheckedSubChild: (
      state,
      action: PayloadAction<{ checked: boolean; device: TreeDevice }>
    ) => {
      const deviceIndex = state.subchildDevices.findIndex(device => {
        return device.deviceKey == action.payload.device.deviceKey;
      });
      if (deviceIndex == -1) {
        log('No device found');
        return;
      }
      state.subchildDevices[deviceIndex] = {
        ...state.subchildDevices[deviceIndex],
        checked: action.payload.checked,
      };
    },
    setChildrenChecked: (
      state,
      action: PayloadAction<{ checked: boolean; device: TreeDevice }>
    ) => {
      state.childDevices = state.childDevices.map(childDevice => {
        if (
          childDevice.parent_device_id == action.payload.device.parent_device_id
        ) {
          if (action.payload.checked) {
            childDevice.checked = true;
            childDevice.expanded = true;
          } else {
            childDevice.checked = false;
          }
        }
        return childDevice;
      });
    },
    setSubChildrenChecked: (
      state,
      action: PayloadAction<{ checked: boolean; device: TreeDevice }>
    ) => {
      log('Subchildren');
      state.subchildDevices = state.subchildDevices.map(scDevice => {
        if (
          scDevice.parent_device_id == action.payload.device.parent_device_id &&
          scDevice.child_device_id == action.payload.device.child_device_id &&
          action.payload.device.subchild_device_id != 0
        ) {
          if (action.payload.checked) {
            scDevice.checked = true;
            scDevice.expanded = true;
          } else {
            scDevice.checked = false;
          }
        }
        return scDevice;
      });
    },
    setParentExpanded: (
      state,
      action: PayloadAction<{ device: TreeDevice; expanded: boolean }>
    ) => {
      state.parentDevices = state.parentDevices.map(device => {
        if (device.deviceKey == action.payload.device.deviceKey) {
          device.expanded = action.payload.expanded;
        }
        return device;
      });
    },
    setChildExpanded: (
      state,
      action: PayloadAction<{ device: TreeDevice; expanded: boolean }>
    ) => {
      state.childDevices = state.childDevices.map(device => {
        if (device.deviceKey == action.payload.device.deviceKey) {
          device.expanded = action.payload.expanded;
        }
        return device;
      });
    },
    setSubchildExpanded: (
      state,
      action: PayloadAction<{ device: TreeDevice; expanded: boolean }>
    ) => {
      state.subchildDevices = state.subchildDevices.map(device => {
        if (device.deviceKey == action.payload.device.deviceKey) {
          device.expanded = action.payload.expanded;
        }
        return device;
      });
    },
    setParentDeviceName: (
      state,
      action: PayloadAction<{ device: TreeDevice; newName: string }>
    ) => {
      state.parentDevices = state.parentDevices.map(device => {
        if (device.deviceKey == action.payload.device.deviceKey) {
          device.device_name = action.payload.newName;
        }
        return device;
      });
    },
    setChildDeviceName: (
      state,
      action: PayloadAction<{ device: TreeDevice; newName: string }>
    ) => {
      state.childDevices = state.childDevices.map(device => {
        if (device.deviceKey == action.payload.device.deviceKey) {
          device.device_name = action.payload.newName;
        }
        return device;
      });
    },
    setSubChildDeviceName: (
      state,
      action: PayloadAction<{ device: TreeDevice; newName: string }>
    ) => {
      state.subchildDevices = state.subchildDevices.map(device => {
        if (device.deviceKey == action.payload.device.deviceKey) {
          device.device_name = action.payload.newName;
        }
        return device;
      });
    },
    setLinkErrorText: (
      state,
      action: PayloadAction<{ deviceName: string; errorText: string }>
    ) => {
      state.linkDevices[action.payload.deviceName] = {
        done: false,
        loading: false,
        errorText: action.payload.errorText,
      };
    },
    setLinkTrackingNumber: (
      state,
      action: PayloadAction<{ trackingNumber: string | null }>
    ) => {
      state.linkingTrackingNumber = action.payload.trackingNumber;
    },
    setParentErrorText: (
      state,
      action: PayloadAction<{ device: TreeDevice; errorText: string }>
    ) => {
      state.parentDevices = state.parentDevices.map(device => {
        if (device.deviceKey == action.payload.device.deviceKey) {
          device.errorText = action.payload.errorText;
        }
        return device;
      });
    },
    setChildErrorText: (
      state,
      action: PayloadAction<{ device: TreeDevice; errorText: string }>
    ) => {
      state.childDevices = state.childDevices.map(device => {
        if (device.deviceKey == action.payload.device.deviceKey) {
          device.errorText = action.payload.errorText;
        }
        return device;
      });
    },
    setSubChildErrorText: (
      state,
      action: PayloadAction<{ device: TreeDevice; errorText: string }>
    ) => {
      state.subchildDevices = state.subchildDevices.map(device => {
        if (device.deviceKey == action.payload.device.deviceKey) {
          device.errorText = action.payload.errorText;
        }
        return device;
      });
    },
    setParentLoading: (
      state,
      action: PayloadAction<{ device: TreeDevice; loading: boolean }>
    ) => {
      state.parentDevices = state.parentDevices.map(device => {
        if (device.deviceKey == action.payload.device.deviceKey) {
          device.loading = action.payload.loading;
        }
        return device;
      });
    },
    setChildLoading: (
      state,
      action: PayloadAction<{ device: TreeDevice; loading: boolean }>
    ) => {
      state.childDevices = state.childDevices.map(device => {
        if (device.deviceKey == action.payload.device.deviceKey) {
          device.loading = action.payload.loading;
        }
        return device;
      });
    },
    setLinkLoading: (
      state,
      action: PayloadAction<{ deviceName: string; loading: boolean }>
    ) => {
      state.linkDevices[action.payload.deviceName] = {
        done: false,
        loading: true,
      };
    },
    setSubChildLoading: (
      state,
      action: PayloadAction<{ device: TreeDevice; loading: boolean }>
    ) => {
      state.subchildDevices = state.subchildDevices.map(device => {
        if (device.deviceKey == action.payload.device.deviceKey) {
          device.loading = action.payload.loading;
        }
        return device;
      });
    },
    setLinkDone: (state, action: PayloadAction<{ deviceName: string }>) => {
      state.linkDevices[action.payload.deviceName] = {
        done: true,
        loading: false,
      };
    },
    setResetState: (state, action: PayloadAction<{ deviceName: string }>) => {
      state.linkDevices[action.payload.deviceName] = {
        done: false,
        loading: false,
      };
    },
  },
});

export function setDeviceErrorText(payload: {
  device: TreeDevice;
  errorText: string;
}): ReturnType<
  | typeof setSubChildErrorText
  | typeof setChildErrorText
  | typeof setParentErrorText
> {
  const { parent_device_id, child_device_id, subchild_device_id } =
    payload.device;
  if (subchild_device_id) {
    return setSubChildErrorText(payload);
  } else if (child_device_id) {
    return setChildErrorText(payload);
  } else {
    return setParentErrorText(payload);
  }
}

export const {
  setDevices,
  setAllowedSites,
  setCheckedParent,
  setCheckedSubChild,
  setCheckedChild,
  setChildrenChecked,
  setSubChildrenChecked,
  setParentExpanded,
  setChildExpanded,
  setSubchildExpanded,
  setChildDeviceName,
  setParentDeviceName,
  setSubChildDeviceName,
  setParentErrorText,
  setChildErrorText,
  setSubChildErrorText,
  setParentLoading,
  setChildLoading,
  setLinkLoading,
  setSubChildLoading,
  setLinkErrorText,
  setLinkDone,
  setResetState,
  setLinkTrackingNumber,
} = devicesSlice.actions;

export default devicesSlice.reducer;
