/* eslint-disable no-console */
import { Recorder } from '@features/milestones/types/api-types';
import { UINode } from 'src/features/milestones/types/ui-types';
import { OptimisticRenamePayload, SettledRenamePayload } from './treeSlice';

/**
 * This may not work on top nodes (Recorder, Site), but supposed to work on Encoder, Camera, etc.
 */
export const updateNodeNameAndIdAction = (
  nodes: UINode[],
  payload: SettledRenamePayload,
  found: { isRenamed: boolean } // use this to stop the search/update immediately when node is found
) => {
  if (found.isRenamed) return;
  nodes.forEach(node => {
    if (node.id === payload.id) {
      node.isLoading = false;
      node.id = payload.newId;
      node.name = payload.newName;
      node.renamingError = payload.renamingError; //if undefined, it means no error
      found.isRenamed = true;
      return;
    }
    if (node.children) {
      updateNodeNameAndIdAction(node.children, payload, found);
    }
  });
};

/**
 * Optimistically updating node name, we do not have id at this point
 */
export const optimisticUpdateNameAction = (
  nodes: UINode[],
  payload: OptimisticRenamePayload,
  found: { isRenamed: boolean } // use this to stop the search/update immediately when node is found
) => {
  if (found.isRenamed) return;
  nodes.forEach(node => {
    if (node.id === payload.id) {
      node.name = payload.newName;
      found.isRenamed = true;
      node.isLoading = true;
      node.renamingError = undefined; //when we start mutating, remove the error icon if it is there
      return;
    }
    if (node.children) {
      optimisticUpdateNameAction(node.children, payload, found);
    }
  });
};

export const updateRecorderNameAndIdAction = (
  recorders: Recorder[],
  selectedRecorders: Recorder[],
  payload: SettledRenamePayload
) => {
  recorders.forEach(recorder => {
    if (recorder.id === payload.id) {
      recorder.id = payload.newId;
      recorder.name = payload.newName;
      return;
    }
  });
  selectedRecorders.forEach(recorder => {
    if (recorder.id === payload.id) {
      recorder.id = payload.newId;
      recorder.name = payload.newName;
      return;
    }
  });
};

export const optimisticUpdateRecorderNameAction = (
  recorders: Recorder[],
  selectedRecorders: Recorder[],
  payload: OptimisticRenamePayload
) => {
  recorders.forEach(recorder => {
    if (recorder.id === payload.id) {
      recorder.name = payload.newName;
      return;
    }
  });
  selectedRecorders.forEach(recorder => {
    if (recorder.id === payload.id) {
      recorder.name = payload.newName;
      return;
    }
  });
};
