import { createErrorsMessage } from '@features/milestones//notifications/utils';

export const UNKNOWN = 'Unknown';
export const UNKNOWN_NUMBER = -1;

export const ERROR_LOADING_MULTI_RECORDERS_DEVICES = createErrorsMessage(
  'Cannot retrieve devices from one or mutliple recorders, Data may still be stale or partially loaded!'
);

export const ERROR_LOADING_RECORDER_DEVICES = createErrorsMessage(
  'Cannot retrieve devices from the single selected recorder, Data may still be stale or partially loaded!'
);

export const ERROR_LOADING_RECORDER_SERVERS = createErrorsMessage(
  'Cannot retrieve recorders for the selected site code, Data may still be stale or partially loaded!'
);

export const Labels = {
  Titles: {
    HeadingTitle: 'Devices',
    LoadingText: 'Loading Devices...',
    EmptyData: 'No Devices',
    FailureTitle: 'Failed to get devices',
    NoItemsTitle: 'There are no device items',
    NoMatchFilter: 'No matches',
    FilteringMatches: (count: number): string => `${count} matches`,
  },
  Buttons: {
    Action: 'Action',
  },
  Placeholders: {
    Filter: 'Filter Devices',
  },
};

export const AriaLabels = {
  RefreshDevices: 'Refresh devices',
  ActionMenu: 'Action menu',
  ClearLogs: 'Clear logs',
};

export const TestIds = {
  DevicesHeader: 'DeviceHeader',
};

export enum ActionMenuIds {
  AddDcmsn = 'addDcmsn',
  AddUc = 'addUc',
  ExportCsv = 'exportCsv',
  RemoveAll = 'removeAll',
  RemoveDcmsn = 'removeDcmsn',
  RemoveUc = 'removeUc',
  UploadCsv = 'uploadCsv',
}

export enum TreeErrorCodes {
  CameraRenameError = 'Failed to rename camera',
  EncoderCameraRenameError = 'Failed to rename encoder camera',
  EncoderMetadataRenameError = 'Failed to rename encoder metadata',
  EncoderRenameError = 'Failed to rename encoder',
  GenericHardwareRenameError = 'Recently failed renaming',
  RecorderServerRenameError = 'Failed to rename recorder server',
  UNKNOWN = 'Unknown',
}

export enum TreeSuccessCodes {
  CameraRenameSuccess = 'Successfully renamed camera',
  EncoderCameraRenameSuccess = 'Successfully renamed encoder camera',
  EncoderMetadataRenameSuccess = 'Successfully renamed encoder metadata',
  EncoderRenameSuccess = 'Successfully renamed encoder',
  RecorderServerRenameSuccess = 'Successfully renamed recorder server',
  UNKNOWN = 'Unknown',
}
