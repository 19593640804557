/* eslint-disable prettier/prettier */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { UINode } from 'src/features/milestones/types/ui-types';
import { ExpandableSection } from '@amzn/awsui-components-react';
import {
  AutoSizer,
  Index,
  List,
  ListRowProps,
  WindowScroller,
} from 'react-virtualized';
import { useAppDispatch } from 'src/stores/slices/hooks';
import { toggleNode } from 'src/stores/slices/milestones/tree/treeSlice';
import TreeNode from './TreeNode';

export interface TreeViewTableProps {
  data: UINode[];
}

const ROW_HEIGHT = 22;

const TreeTable = ({ data }: TreeViewTableProps): ReactElement => {
  const dispatch = useAppDispatch();
  const [listRef, setRef] = useState<List | null>(null);
  const listRerender = useCallback((): void => {
    if (listRef) {
      listRef.recomputeRowHeights();
      listRef.forceUpdate();
    }
  }, [listRef]);

  useEffect(() => {
    listRerender();
  }, [data, listRerender]);

  function renderItem(node: UINode, keyPrefix: string): ReactElement | null {
    if (node.isHidden) {
      return null;
    }
    const onClick = (): void => {
      dispatch(toggleNode({ id: node.id, isExpanded: !node.isExpanded }));
      listRerender();
    };

    let children: ReactElement[] = [];
    let lineText: ReactElement;

    if (node?.children?.length) {
      if (node.isExpanded) {
        children = node.children.reduce(function (result, child, index) {
          const elem = renderItem(child, keyPrefix + '-' + index);
          if (elem) {
            result.push(elem);
          }
          return result;
        }, [] as ReactElement[]);
      }
      lineText = (
        <ExpandableSection
          variant="navigation"
          headerText={<TreeNode node={node} />}
          onChange={onClick}
          expanded={node.isExpanded}></ExpandableSection>
      );
    } else {
      lineText = <TreeNode node={node} />;
    }

    return lineText ? (
      <ul className="list" key={`list-item-${keyPrefix}`}>
        <li className="list-item">
          <div
            className={
              'tree-node' + (node?.children?.length ? '' : ' tree-node-last')
            }>
            {lineText}
          </div>
          {children}
        </li>
      </ul>
    ) : null;
  }

  function getExpandedItemCount(item: UINode): number {
    if (item.isHidden) {
      return 0;
    }

    let count = 1;

    if (item.isExpanded && item.children) {
      count += item.children
        .map(getExpandedItemCount)
        .reduce(function (total, count) {
          return total + count;
        }, 0);
    }

    return count;
  }

  function cellRenderer(params: ListRowProps): ReactElement {
    const renderedCell: ReactElement | null = renderItem(
      data[params.index],
      params.index.toString()
    );

    return renderedCell ? (
      <ul className="list" key={params.key + ''} style={params.style}>
        {renderedCell}
      </ul>
    ) : (
      <></>
    );
  }

  function rowHeight(params: Index): number {
    return getExpandedItemCount(data[params.index]) * ROW_HEIGHT;
  }

  return (
    <WindowScroller>
      {({ height, scrollTop }): ReactElement => (
        <AutoSizer disableHeight>
          {({ width }): ReactElement => (
            <List
              autoHeight
              height={height}
              rowCount={data.length}
              rowHeight={rowHeight}
              rowRenderer={cellRenderer}
              ref={setRef}
              width={width}
              scrollTop={scrollTop}
              overscanRowCount={1}
            />
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};

export default TreeTable;
