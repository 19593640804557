import * as React from 'react';

import Flashbar, {
  FlashbarProps,
} from '@amzn/awsui-components-react/polaris/flashbar';

import {
  ERROR_TEMPLATE,
  FlashBarMessage,
  FlashBarMessageStatus,
  LOADING_TEMPLATE,
  SUCCESS_TEMPLATE,
  TestIds,
} from '@features/milestones/notifications/utils';
import { useAppDispatch, useAppSelector } from '@stores/slices/hooks';
import {
  removeFlashBarMessage,
  selectFlashBarItems,
} from '@stores/slices/milestones/flashBar/flashBarSlice';

function getMessageDefinition(
  items: FlashBarMessage[],
  dismissFlashbarMessage: (item: FlashBarMessage) => void
): FlashbarProps.MessageDefinition[] {
  if (items.length === 0) {
    return [];
  }

  const messages: FlashbarProps.MessageDefinition[] = [];

  items.map(item => {
    switch (item.status) {
      case FlashBarMessageStatus.Started: {
        messages.push({
          ...LOADING_TEMPLATE,
          content: item.message,
        });
        break;
      }
      case FlashBarMessageStatus.Error: {
        messages.push({
          ...ERROR_TEMPLATE,
          onDismiss: (): void => {
            dismissFlashbarMessage(item);
          },
          content: item.message,
        });
        break;
      }
      case FlashBarMessageStatus.Success: {
        messages.push({
          ...SUCCESS_TEMPLATE,
          onDismiss: (): void => {
            dismissFlashbarMessage(item);
          },
          content: item.message,
        });
        break;
      }
      default: {
        break;
      }
    }
  });

  return messages;
}

const Notifications = (): React.ReactElement => {
  // const [displayItems, setDisplayItems] = React.useState<FlashBarMessage[]>([]);
  const dispatch = useAppDispatch();

  const flashBarItems = useAppSelector(selectFlashBarItems);
  function dismissFlashbarMessage(item: FlashBarMessage) {
    dispatch(removeFlashBarMessage(item));
  }

  return (
    <Flashbar
      data-testid={TestIds.NOTIFICATIONS}
      items={getMessageDefinition(flashBarItems, dismissFlashbarMessage)}
    />
  );
};

export default Notifications;
