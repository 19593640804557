import * as React from 'react';

import Button from '@amzn/awsui-components-react/polaris/button';

import { EmptyState } from './EmptyState';
import { tableLabels } from '@components/Table/constants';

interface NoMatchClearFilterProps {
  cleanFilterHandler: () => void;
}
export const NoMatchClearFilter = ({
  cleanFilterHandler,
}: NoMatchClearFilterProps): JSX.Element => {
  return (
    <EmptyState
      title={tableLabels.titles.NO_MATCHES}
      subtitle={tableLabels.titles.CANT_FIND_MATCH}
      action={
        <Button onClick={cleanFilterHandler}>
          {tableLabels.titles.CLEAR_FILTER}
        </Button>
      }
    />
  );
};
