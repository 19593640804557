/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import * as React from 'react';

import PropertyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import { UseCollectionResult } from '@amzn/awsui-collection-hooks';

import {
  ariaLabels,
  labels,
  testIds,
} from '@features/milestones/recorders/constants';
import { getFilterCounterText, i18nFilterStrings } from 'src/utils/table';
import { RecordersFilteringOptions } from '@features/milestones/recorders/tableConfig';

interface RecordersFilterProps<T> {
  filteredItemsCount: number | undefined;
  propertyFilterProps: UseCollectionResult<T>['propertyFilterProps'];
}

const RecordersFilter = <T extends unknown>({
  propertyFilterProps,
  filteredItemsCount,
}: RecordersFilterProps<T>): JSX.Element => {
  return (
    <PropertyFilter
      data-testid={testIds.TABLE_FILTER}
      {...propertyFilterProps}
      filteringOptions={RecordersFilteringOptions}
      i18nStrings={{
        ...i18nFilterStrings,
        filteringAriaLabel: ariaLabels.FILTERING_LABEL,
        filteringPlaceholder: labels.placeholders.FILTER,
      }}
      countText={getFilterCounterText(
        filteredItemsCount === undefined ? 0 : filteredItemsCount
      )}
      expandToViewport={true}
    />
  );
};

export { RecordersFilter };
