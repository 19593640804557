import { duplicateName } from '@utils/helpers';
import { Entity, NodeTypes } from 'src/features/milestones/types/api-types';
import {
  getDecommissionedRecorderErrorMessage,
  getDuplicateNameErrorMessage,
  getInvalidNameErrorMessage,
  getNameLengthErrorMessage,
} from 'src/utils/validations/constants/ErrorMessageConstant';

export const getRecorderServerNameFieldError = (
  allNodeNames: string[],
  node: Entity,
  newName: string
): string[] => {
  const errorMessages: string[] = [];
  const siteCode: string | undefined = node?.siteCode;

  if (node.name !== newName && duplicateName(allNodeNames, newName)) {
    errorMessages.push(getDuplicateNameErrorMessage(NodeTypes.Recorder));
  }
  if (newName.length > 96) {
    errorMessages.push(getNameLengthErrorMessage(NodeTypes.Recorder));
  }

  /* original - restore when renaming is allowed
   * const regex = new RegExp(
   *   `^(?:UC_|uc_|DCMSN_|dcmsn_)?${siteCode}-MNVR-\\d{2}`
   * );
   */
  const mnvrName = node.name.substring(
    node.name.indexOf(`${siteCode}-MNVR`),
    node.name.length
  );
  const isMnvrDecommissioned = node.name.startsWith('dcmsn_');
  const regex = new RegExp(`^(?:uc_|dcmsn_)?${mnvrName}$`);

  if (isMnvrDecommissioned) {
    errorMessages.push(getDecommissionedRecorderErrorMessage());
  } else {
    if (!regex.test(newName)) {
      errorMessages.push(
        getInvalidNameErrorMessage(
          NodeTypes.Recorder,
          '[dcmsn_|uc_]' + mnvrName
        )
      );
    }
  }

  return errorMessages;
};
