import * as React from 'react';
import { useNavigate } from 'react-router';

import {
  TopNavigation,
  TopNavigationProps,
} from '@amzn/awsui-components-react';

import whiteLogo from 'src/assets/logo_white.png';
import { AppPaths } from 'src/routes/Routes';
import {
  toggleSelectSite,
  toggleShowDeviceLinkFileUpload,
} from '@stores/slices/userSlice';
import { useAppDispatch } from '@stores/slices/hooks';
import {
  ariaLabels,
  elementIds,
  i18nStrings,
  labels,
  styles,
} from './constants';

/**
 *
 * Important Notes:
 * Do not use href attribute, as it cause reloading when navigation
 * Only use React Router <NavLink> or useNavigate to avoid react wasted rendering
 */
const MilestonesTopNav = (): React.ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const utilities = [
    {
      type: 'button',
      text: labels.buttons.SELECT_SITES,
      iconName: 'edit',
      ariaLabel: ariaLabels.SELECT_SITES,
      badge: false,
      disableUtilityCollapse: true,
      onClick: (): void => {
        dispatch(toggleSelectSite());
      },
    },
    {
      type: 'button',
      text: 'Link Device(s)',
      iconName: 'upload',
      ariaLabel: 'Link Devices',
      badge: false,
      disableUtilityCollapse: true,
      onClick: (): void => {
        dispatch(toggleShowDeviceLinkFileUpload());
      },
    },
    {
      type: 'button',
      text: labels.buttons.SWITCH_TO_ISC,
      iconName: 'upload',
      ariaLabel: ariaLabels.SWITCH_TO_ISC,
      onClick: () => navigate(AppPaths.Devices),
    },
  ] as TopNavigationProps.Utility[];

  return (
    <div
      className="awsui"
      id={elementIds.MILESTONES_TOP_NAVIGATION_ID}
      style={{
        position: 'sticky',
        top: 0,
        zIndex: styles.TOP_NAVIGATION_Z_INDEX,
      }}>
      <TopNavigation
        identity={{
          href: '#',
          onFollow: () => {
            navigate(AppPaths.Milestones);
          },
          title: labels.titles.TOP_NAVIGATION,
          logo: {
            src: whiteLogo,
            alt: labels.alt.LOGO,
          },
        }}
        utilities={utilities}
        i18nStrings={i18nStrings}
      />
    </div>
  );
};

export default MilestonesTopNav;
