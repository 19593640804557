import { AppPaths } from 'src/routes/Routes';
import { router } from 'src/routes/Routes';

export const SearchParameters = {
  Recoder: 'recorder',
  Site: 'site',
};

/**
 * This approach can work, even outside of component scope
 * Inside component Scope, it is better to use <NavLink>, <Navigate>, or useNavigate() directly
 * This is available with the newest React Router, but we may pass {navigate} from useNavigate() as a prop
 * If we observe any unwanted side effects
 */
export const useMilestonesNavigate = (): {
  navigateToMilestonesHome: () => void;
  navigateToRecodersView: () => void;
  navigateToSitesView: () => void;
  navigateToTreeBySite: (siteId: string) => void;
  navigateToTreeWithMultipleRecorders: () => void;
} => {
  // /milestones/tree?site=123
  const navigateToTreeBySite = (siteId: string): Promise<void> =>
    router.navigate(
      AppPaths.TreeSingleView +
        `?${SearchParameters.Site}=${encodeURIComponent(siteId)}`
    );

  const navigateToTreeWithMultipleRecorders = (): Promise<void> =>
    router.navigate(AppPaths.TreeRecordersView);

  const navigateToRecodersView = (): Promise<void> =>
    router.navigate(AppPaths.RecordersView);

  const navigateToSitesView = (): Promise<void> =>
    router.navigate(AppPaths.SitesView);

  const navigateToMilestonesHome = (): Promise<void> =>
    router.navigate(AppPaths.Milestones);

  return {
    navigateToTreeBySite,
    navigateToTreeWithMultipleRecorders,
    navigateToRecodersView,
    navigateToSitesView,
    navigateToMilestonesHome,
  };
};
