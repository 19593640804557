import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import Splash from 'src/components/Splash';
import { AppLayout } from '@amzn/awsui-components-react';
import IscTopNav from 'src/pages/isc/layout/IscTopNav';
import DeviceRenameTestContent from 'src/features/isc/DeviceRenameTestContent';

function DeviceRenameTest(): React.ReactElement {
  useAppDispatch();
  const allowedSites = useAppSelector(state => state.deviceState.allowedSites);

  if (!allowedSites.length) {
    return <Splash />;
  } else {
    return (
      <>
        <IscTopNav />
        <AppLayout
          headerSelector={'#header'}
          navigationHide
          content={<DeviceRenameTestContent />} // Main content on the page, defined below
          toolsHide
        />
      </>
    );
  }
}

export default DeviceRenameTest;
