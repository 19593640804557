import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import {
  MilestoneRenameResponse,
  RenameEncoderCameraMutation,
  RenameEncoderCameraMutationVariables,
} from 'src/API';
import { renameEncoderCamera } from 'src/graphql/mutations';

const apiRenameEncoderCameraServer = async (
  variables: RenameEncoderCameraMutationVariables
): Promise<MilestoneRenameResponse> => {
  const response = (await API.graphql(
    graphqlOperation(renameEncoderCamera, variables)
  )) as GraphQLResult<RenameEncoderCameraMutation>;

  const renameEncoderCameraResponse = response.data?.renameEncoderCamera;
  if (renameEncoderCameraResponse?.status !== 200) {
    throw new Error(
      `Failed renameEncoderCamera with status: ${renameEncoderCameraResponse?.status}`
    );
  }

  if (!renameEncoderCameraResponse.device) {
    throw new Error(
      `Failed renameEncoderCamera with empty data ${renameEncoderCameraResponse?.device}`
    );
  }
  return renameEncoderCameraResponse;
};

export const useEncoderCameraRenaming = () => {
  const {
    mutate: renameEncoderCameraMutation,
    mutateAsync: renameEncoderCameraAsync,
    data: response,
    isSuccess,
    isError,
    isPending,
  } = useMutation({
    mutationFn: apiRenameEncoderCameraServer,
  });

  return {
    renameEncoderCameraMutation,
    renameEncoderCameraAsync,
    response,
    isSuccess,
    isError,
    isPending,
  };
};
