/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import React, { ReactElement, useEffect } from 'react';
import SearchTree from './components/SearchTree';
import { useAppDispatch, useAppSelector } from '@stores/slices/hooks';
import { setGlobalIsLoading } from '@stores/slices/userSlice';
import {
  clearAllTreeMessages,
  initData,
  setTreeLoading,
} from '@stores/slices/milestones/tree/treeSlice';
import {
  getSelectedTreeRecorders,
  selectAllRecorders,
} from '@stores/slices/milestones/milestonesSlice';
import { useMultiRecorderDevices } from 'src/services/useMultiRecorderDevices';
import { getAllNames, getRecorderIdsBySiteCode, log } from 'src/utils/helpers';
import { transformRecorderTuple } from './utils';
import {
  addFlashBarMessage,
  clearAllMessages,
  removeFlashBarMessage,
  setSingleFlashBarMessage,
} from '@stores/slices/milestones/flashBar/flashBarSlice';
import { ERROR_LOADING_MULTI_RECORDERS_DEVICES } from './constants';
import { FETCHING_RECORDER_DEVICES } from '@features/milestones/recorders/constants';

const MultiRecordersTree = (): ReactElement => {
  const selectedTreeRecorders = useAppSelector(getSelectedTreeRecorders);
  const allRecorders = useAppSelector(selectAllRecorders);
  const dispatch = useAppDispatch();
  const recorderServerIdsBySiteCode = getRecorderIdsBySiteCode(
    selectedTreeRecorders
  );
  const {
    isLoading,
    errors,
    isError,
    recordersMap,
    refetchMultiRecorderDevices,
    removeQueriesForId,
  } = useMultiRecorderDevices(recorderServerIdsBySiteCode);

  const invalidateQueryCache = (oldId: string): void => {
    removeQueriesForId(oldId);
    refetchMultiRecorderDevices();
  };

  useEffect(() => {
    if (isError) {
      dispatch(addFlashBarMessage(ERROR_LOADING_MULTI_RECORDERS_DEVICES));
    } else {
      dispatch(clearAllMessages());
    }
  }, [isError, dispatch, errors]);

  useEffect(() => {
    setTimeout(async () => {
      if (isLoading) {
        dispatch(setSingleFlashBarMessage(FETCHING_RECORDER_DEVICES));
      } else {
        dispatch(removeFlashBarMessage(FETCHING_RECORDER_DEVICES));
      }
    }, 200);
  }, [dispatch, isLoading]);

  useEffect(() => {
    dispatch(clearAllTreeMessages());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearAllMessages());
    setTimeout(() => {
      log('*** MultiRecordersTree: recordersMap=', false, recordersMap);
      const rootNodes = selectedTreeRecorders.map(selectedRecorder =>
        transformRecorderTuple(
          selectedRecorder,
          recordersMap.find(r => r.recorderId === selectedRecorder.id)
        )
      );
      dispatch(
        initData({
          allNames: getAllNames(allRecorders, recordersMap),
          rootNode: rootNodes,
        })
      );
    }, 500);
  }, [allRecorders, dispatch, recordersMap, selectedTreeRecorders]);

  //may need to use isLoading from useState, to synchronize
  useEffect(() => {
    dispatch(setTreeLoading(isLoading));
    dispatch(setGlobalIsLoading(isLoading));
  }, [dispatch, isLoading]);

  return <SearchTree onRename={invalidateQueryCache} />;
};

export default MultiRecordersTree;
