/* eslint-disable no-console */
import React, { ReactElement } from 'react';

import Table from '@amzn/awsui-components-react/polaris/table';
import CollectionPreferences from '@amzn/awsui-components-react/polaris/collection-preferences';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import { useCollection } from '@amzn/awsui-collection-hooks';

import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import {
  addFlashBarMessage,
  removeFlashBarMessage,
  setSingleFlashBarMessage,
} from '@stores/slices/milestones/flashBar/flashBarSlice';
import { selectSelectedSiteCodes } from '@stores/slices/userPrefsSlice';

import { EmptyState, NoMatchClearFilter } from '@components/Table';
import { useEffect } from 'react';
import { usePreferences } from 'src/hooks/usePreferences';
import { SitesHeader } from './components/SitesHeader';
import { useRecorders } from 'src/features/milestones/recorders/hooks/useRecorders';
import { useMilestonesNavigate } from '@features/milestones/hooks/useMilestonesNavigate';
import { Recorder, Site } from 'src/features/milestones/types/api-types';
import { getFilterCounterText } from 'src/utils/table';

import {
  DEFAULT_PAGE_SIZE,
  ERROR_LOADING_SITES,
  FETCHING_SITES,
  ariaLabels,
  labels,
  testIds,
} from './constants';
import {
  DEFAULT_VISIBLE_COLUMNS,
  PageSizePreference,
  VISIBLE_CONTENT_OPTIONS,
  getSitesColumnDefinitions,
} from './tableConfig';

const SitesTable = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedSiteCodes = useAppSelector(selectSelectedSiteCodes);
  const { visibleColumns, pageSize, updatePreferencesHandler } = usePreferences(
    {
      defaultPageSize: DEFAULT_PAGE_SIZE,
      defaultVisibleColumns: DEFAULT_VISIBLE_COLUMNS,
    }
  );
  const { recorders, isLoading, isError, error } = useRecorders();
  const sites = transformRecordersToSites(recorders, selectedSiteCodes);
  const { navigateToTreeBySite } = useMilestonesNavigate();
  const emptyStateComponent = <EmptyState title={labels.titles.EMPTY_TITLE} />;

  const {
    items,
    actions,
    filterProps,
    paginationProps,
    collectionProps,
    filteredItemsCount,
  } = useCollection(sites, {
    filtering: {
      empty: emptyStateComponent,
      noMatch: (
        <NoMatchClearFilter
          cleanFilterHandler={() => actions.setFiltering('')}
        />
      ),
    },
    pagination: { pageSize: pageSize },
    sorting: {
      defaultState: { sortingColumn: getSitesColumnDefinitions()[0] },
    },
    selection: {},
  });

  useEffect(() => {
    setTimeout(async () => {
      if (isLoading) {
        dispatch(setSingleFlashBarMessage(FETCHING_SITES));
      } else {
        dispatch(removeFlashBarMessage(FETCHING_SITES));
      }
    }, 200);
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (isError) {
      dispatch(addFlashBarMessage(ERROR_LOADING_SITES));
    }
  }, [isError, dispatch, error]);

  return (
    <>
      <SpaceBetween direction="vertical" size="m">
        <Table
          data-testid={testIds.TABLE}
          {...collectionProps}
          ariaLabels={{
            selectionGroupLabel: ariaLabels.SELECTION_GROUP_LABEL,
            allItemsSelectionLabel: ({ selectedItems }) =>
              ariaLabels.getAllItemsSelectionLabel(selectedItems.length),
            itemSelectionLabel: ({ selectedItems }, item) =>
              ariaLabels.getItemSelectionLabel(selectedItems, item.name),
          }}
          columnDefinitions={getSitesColumnDefinitions(navigateToTreeBySite)}
          items={items}
          loadingText={labels.titles.LOADING_TEXT}
          trackBy="name"
          filter={
            <TextFilter
              data-testid={testIds.TABLE_FILTER}
              {...filterProps}
              filteringPlaceholder={labels.placeholders.FILTER}
              countText={getFilterCounterText(
                filteredItemsCount === undefined ? 0 : filteredItemsCount
              )}
            />
          }
          header={<SitesHeader items={items} />}
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: ariaLabels.NEXT_PAGE_LABEL,
                previousPageLabel: ariaLabels.PREVIOUS_PAGE_LABEL,
                pageLabel: pageNumber => ariaLabels.getPageLabel(pageNumber),
              }}
            />
          }
          preferences={
            <CollectionPreferences
              data-testid={testIds.PREFERENCES}
              onConfirm={({ detail }) => updatePreferencesHandler(detail)}
              title={labels.tablePreferences.titles.HEADER}
              confirmLabel={labels.tablePreferences.buttons.CONFIRM_BUTTON}
              cancelLabel={labels.tablePreferences.buttons.CANCEL_BUTTON}
              preferences={{
                pageSize: pageSize,
                visibleContent: visibleColumns,
              }}
              pageSizePreference={PageSizePreference}
              visibleContentPreference={{
                title: labels.tablePreferences.titles.VISIBLE_COLUMNS_HEADER,
                options: VISIBLE_CONTENT_OPTIONS,
              }}
            />
          }
          columnDisplay={visibleColumns.map(c => {
            return { id: c, visible: true };
          })}
        />
      </SpaceBetween>
    </>
  );
};

interface TempSite {
  recorderCount: number;
  region: string;
  totalDevices: number;
}

const transformRecordersToSites = (
  recorders: Recorder[],
  selectedSiteCodes: string[]
): Site[] => {
  const tempSites: { [siteCode: string]: TempSite } = {};
  recorders.forEach(recorder => {
    const siteCode = recorder.siteCode;
    if (tempSites[siteCode]) {
      tempSites[siteCode].totalDevices += recorder.totalDevices;
      tempSites[siteCode].recorderCount += 1;
    } else {
      tempSites[siteCode] = {
        region: recorder.region,
        totalDevices: recorder.totalDevices,
        recorderCount: 1,
      };
    }
  });

  const addedSites = Object.keys(tempSites);
  const finalSites = addedSites.sort().map(siteCode => {
    const { region, totalDevices, recorderCount } = tempSites[siteCode];
    return {
      region,
      totalDevices,
      recorderCount,
      name: siteCode,
      id: siteCode,
      type: 'Site',
    } as Site;
  });

  // account for sites with no recorders, getRecorderServers response lacks them
  const emptySites = getEmptySites(addedSites, selectedSiteCodes);

  // if we must have region, will need to reference allowedSites via
  // const allowedSites = useAppSelector(state => state.deviceState.allowedSites);
  emptySites.forEach(siteCode =>
    finalSites.push({
      region: '', // see comment above
      totalDevices: 0,
      recorderCount: 0,
      name: siteCode,
      id: siteCode,
      type: 'Site',
    } as Site)
  );

  return finalSites;
};

const getEmptySites = (
  addedSites: string[],
  selectedSiteCodes: string[]
): string[] => {
  return selectedSiteCodes.filter(siteCode => !addedSites.includes(siteCode));
};

export default SitesTable;
