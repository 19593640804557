import { Icon } from '@amzn/awsui-components-react';
import { IconProps } from '@amzn/awsui-components-react/uxdg';
import React from 'react';

/*
 * https://heroicons.com/
 * All icons must be wrapped inside AWS Cloudscape component for proper styling
 * We may utilize variant to mark a node as under construction or errors
 */
const SingleEncoderIcon = ({ variant }: IconProps) => {
  return (
    <Icon
      variant={variant || 'link'}
      svg={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.75 17.25v-.228a4.5 4.5 0 0 0-.12-1.03l-2.268-9.64a3.375 3.375 0 0 0-3.285-2.602H7.923a3.375 3.375 0 0 0-3.285 2.602l-2.268 9.64a4.5 4.5 0 0 0-.12 1.03v.228m19.5 0a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3m19.5 0a3 3 0 0 0-3-3H5.25a3 3 0 0 0-3 3m16.5 0h.008v.008h-.008v-.008Zm-3 0h.008v.008h-.008v-.008Z"
          />
        </svg>
      }
    />
  );
};

export default SingleEncoderIcon;
