import * as React from 'react';

import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';

interface UseSchedulePreferencesProps {
  defaultPageSize: number;
  defaultVisibleColumns: ReadonlyArray<string>;
}
const usePreferences = ({
  defaultPageSize,
  defaultVisibleColumns,
}: UseSchedulePreferencesProps) => {
  const [visibleColumns, setVisibleColumns] = React.useState<
    ReadonlyArray<string>
  >(defaultVisibleColumns);
  const [pageSize, setPageSize] = React.useState<number>(defaultPageSize);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updatePreferencesHandler = async (
    details: CollectionPreferencesProps.Preferences<any>
  ) => {
    setPageSize(details?.pageSize ?? defaultPageSize);
    setVisibleColumns(details?.visibleContent ?? defaultVisibleColumns);
  };

  return { updatePreferencesHandler, visibleColumns, pageSize };
};

export { usePreferences };
