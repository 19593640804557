import { Recorder } from '@features/milestones/types/api-types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  optimisticUpdateRecorderNameAction,
  updateRecorderNameAndIdAction,
} from '@stores/slices/milestones/tree/rename.actions';
import {
  OptimisticRenamePayload,
  SettledRenamePayload,
} from '@stores/slices/milestones/tree/treeSlice';
import { RootState } from 'src/stores/slices/store';

export interface MilestonesSliceInterface {
  milestonesError: string | undefined;
  recorders: Recorder[];
  //global milestone error, if needed
  selectedTreeRecorders: Recorder[];
}

export const initialMilestonesState: MilestonesSliceInterface = {
  selectedTreeRecorders: [],
  recorders: [],
  milestonesError: undefined,
};

/**
 * Add anything we need to share within milestone spaces
 * Such as selectedTreeRecorders, selectedTreeSites, idToRecodersMap, idToSitesMap, idToCamerasMap, etc
 * Other stuffs could be added are: SitesUnderUcs, CamerasUnderUcs, RecodersUnderUcs
 * CamerasDcmsn, SitesDcmsn, RecodersDcmsn
 * Only pass states and related state actions here. For any non-state, create seprate hooks inside feature/milestones
 */
const milestonesSlice = createSlice({
  name: 'milestonesState',
  initialState: initialMilestonesState,
  reducers: {
    reset: () => {
      return initialMilestonesState;
    },
    initData: () => {
      //this could be changed in the future, if we want to fetch sites or recorders into the store
      return initialMilestonesState;
    },
    setSelectedTreeRecorders: (state, action: PayloadAction<Recorder[]>) => {
      state.selectedTreeRecorders = action.payload;
    },
    setRecorders: (state, action: PayloadAction<Recorder[]>) => {
      state.recorders = [...action.payload];
    },
    updateSelectedWithRemovedSiteCodes: (
      state,
      action: PayloadAction<string[]>
    ) => {
      if (state.selectedTreeRecorders.length > 0) {
        const updatedRecorders = state.selectedTreeRecorders.filter(
          recorder => !action.payload.includes(recorder.siteCode)
        );
        state.selectedTreeRecorders = [...updatedRecorders];
      }
    },
    optimisticUpdateRecorder(
      state,
      action: PayloadAction<OptimisticRenamePayload>
    ) {
      optimisticUpdateRecorderNameAction(
        state.recorders,
        state.selectedTreeRecorders,
        action.payload
      );
    },
    updateRecorderNameAndId(
      state,
      action: PayloadAction<SettledRenamePayload>
    ) {
      updateRecorderNameAndIdAction(
        state.recorders,
        state.selectedTreeRecorders,
        action.payload
      );
    },
  },
});

export const getSelectedTreeRecorders = (state: RootState): Recorder[] =>
  state.milestonesState.selectedTreeRecorders;

export const selectAllRecorders = (state: RootState): Recorder[] =>
  state.milestonesState.recorders;

export const {
  reset,
  initData,
  setSelectedTreeRecorders,
  setRecorders,
  optimisticUpdateRecorder,
  updateRecorderNameAndId,
  updateSelectedWithRemovedSiteCodes,
} = milestonesSlice.actions;

export default milestonesSlice.reducer;
