import * as React from 'react';

import TextContent from '@amzn/awsui-components-react/polaris/text-content';

const RecordersFooter = () => {
  return (
    <TextContent>
      <ul>
        <li>
          Users can select multiple Recorders and click View Selected Tree
        </li>
        {/*<li>
          Users may save a tree configuration to Favorite tab, so next time he
          can just generate tree from Favorites
        </li>*/}
      </ul>
    </TextContent>
  );
};

export { RecordersFooter };
