import * as React from 'react';

import Alert from '@amzn/awsui-components-react/polaris/alert';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import {
  removeErrorMessage,
  selectErrorMessages,
} from 'src/stores/slices/milestones/tree/treeSlice';

const TreeErrorMessages = () => {
  const errorMessages = useAppSelector(selectErrorMessages);
  const dispatch = useAppDispatch();
  const handleOnDismiss = (message: string) => {
    dispatch(removeErrorMessage(message));
  };

  return (
    <>
      {errorMessages.map(message => (
        <Alert
          key={message}
          dismissible={true}
          type="error"
          onDismiss={() => handleOnDismiss(message)}>
          {message}
        </Alert>
      ))}
    </>
  );
};

export default TreeErrorMessages;
