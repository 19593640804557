/* eslint-disable @typescript-eslint/no-explicit-any */
import { TreeDevice } from 'src/types';
import { ThunkAction } from '@reduxjs/toolkit';
import {
  DevicesSliceInterface,
  setCheckedChild,
  setCheckedParent,
  setCheckedSubChild,
  setChildDeviceName,
  setChildErrorText,
  setChildExpanded,
  setChildLoading,
  setDeviceErrorText,
  setLinkDone,
  setLinkErrorText,
  setLinkLoading,
  setLinkTrackingNumber,
  setParentDeviceName,
  setParentErrorText,
  setParentExpanded,
  setParentLoading,
  setResetState,
  setSubChildDeviceName,
  setSubChildErrorText,
  setSubChildLoading,
} from 'src/stores/slices/isc/devicesSlice';
import { TAppState, TDispatch, TGetState } from 'src/stores/slices/store';
import { log } from 'src/utils/helpers';
import { API } from 'aws-amplify';
import { graphqlOperation } from '@aws-amplify/api';
import { linkDevices, renameDevices } from 'src/graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  DeviceInput,
  GetDeviceLinkingStatusQuery,
  LinkDevicesMutation,
  RenameDevicesMutation,
} from 'src/API';
import { CsvLinkDevice } from 'src/features/isc/DeviceLinkFileUpload';
import { getDeviceLinkingStatus } from 'src/graphql/queries';

interface DispatchProps {
  login: (username: string, password: string) => void;
}

interface IState {
  deviceState: DevicesSliceInterface;
}

// type TPromise = Promise<{ error: string } | Error>; // Return type instead of void
export const setChecked =
  (props: {
    checked: boolean;
    device: TreeDevice;
  }): ThunkAction<
    void, // thunk return type
    TAppState, // state type
    any, // extra argument, (not used)
    { payload: { checked: boolean; device: TreeDevice }; type: 'SET_CHECKED' } // action type
  > =>
  (dispatch: TDispatch, getState: TGetState): void => {
    // use getState
    // dispatch start saveEntry action
    // make async call
    const toCheckExpand: TreeDevice[] = [];
    if (
      props.device.child_device_id == 0 &&
      props.device.subchild_device_id == 0
    ) {
      dispatch(
        setCheckedParent({ device: props.device, checked: props.checked })
      );
      if (props.checked) {
        dispatch(setParentExpanded({ device: props.device, expanded: true }));
      }
      for (const cDevice of getState().deviceState.childDevices) {
        if (cDevice.parent_device_id == props.device.parent_device_id) {
          dispatch(
            setCheckedChild({ device: cDevice, checked: props.checked })
          );
          // Expand children if parent was selected
          if (props.checked) {
            dispatch(setChildExpanded({ device: cDevice, expanded: true }));
          }
        }
      }
      for (const scDevice of getState().deviceState.subchildDevices) {
        if (props.device.parent_device_id == scDevice.parent_device_id) {
          dispatch(
            setCheckedSubChild({ device: scDevice, checked: props.checked })
          );
        }
      }
      // dispatch(setSubChildrenChecked({ device: props.device, checked: props.checked }));
    } else if (
      props.device.child_device_id != 0 &&
      props.device.subchild_device_id == 0
    ) {
      dispatch(
        setCheckedChild({ device: props.device, checked: props.checked })
      );
      if (props.checked) {
        dispatch(setChildExpanded({ device: props.device, expanded: true }));
      }
      // dispatch(setSubChildrenChecked({ device: props.device, checked: props.checked }));
      for (const scDevice of getState().deviceState.subchildDevices) {
        if (
          props.device.parent_device_id == scDevice.parent_device_id &&
          props.device.child_device_id == scDevice.child_device_id
        ) {
          dispatch(
            setCheckedSubChild({ device: scDevice, checked: props.checked })
          );
        }
      }
    } else {
      dispatch(
        setCheckedSubChild({ device: props.device, checked: props.checked })
      );
    }
    return;
  };

function isChecked(device: TreeDevice): boolean | undefined {
  if (device.checked) {
    return true;
  }
}

export const addTextToSelected =
  (props: {
    toAdd: string;
  }): ThunkAction<
    void, // thunk return type
    TAppState, // state type
    any, // extra argument, (not used)
    { payload: { toAdd: string }; type: 'ADD_TEXT_TO_SELECTED' } // action type
  > =>
  (dispatch: TDispatch, getState: TGetState): void => {
    const parentDevices =
      getState().deviceState.parentDevices.filter(isChecked);
    const childDevices = getState().deviceState.childDevices.filter(isChecked);
    const subchildDevices =
      getState().deviceState.subchildDevices.filter(isChecked);

    for (const device of parentDevices
      .concat(childDevices)
      .concat(subchildDevices)) {
      if (!device.device_name.includes(`${props.toAdd}`)) {
        dispatch(
          deviceNameChanged({
            device,
            newName: `${props.toAdd}${device.device_name}`,
          })
        );
      }
    }
  };

export const removeTextFromSelected =
  (props: {
    toRemove: string;
  }): ThunkAction<
    void, // thunk return type
    TAppState, // state type
    any, // extra argument, (not used)
    { payload: { toRemove: string }; type: 'REMOVE_TEXT_FROM_SELECTED' } // action type
  > =>
  (dispatch: TDispatch, getState: TGetState): void => {
    const parentDevices =
      getState().deviceState.parentDevices.filter(isChecked);
    const childDevices = getState().deviceState.childDevices.filter(isChecked);
    const subchildDevices =
      getState().deviceState.subchildDevices.filter(isChecked);

    for (const device of parentDevices
      .concat(childDevices)
      .concat(subchildDevices)) {
      if (device.device_name.includes(`${props.toRemove}`)) {
        const newName = device.device_name.replace(`${props.toRemove}`, '');
        dispatch(deviceNameChanged({ device, newName }));
      }
    }
  };

export const setDeviceLoading =
  (props: {
    device: TreeDevice;
    loading: boolean;
  }): ThunkAction<
    void, // thunk return type
    TAppState, // state type
    any, // extra argument, (not used)
    {
      payload: { device: TreeDevice; loading: boolean };
      type: 'CHANGE_DEVICE_NAME';
    } // action type
  > =>
  async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
    if (
      props.device.child_device_id == 0 &&
      props.device.subchild_device_id == 0
    ) {
      dispatch(
        setParentLoading({ device: props.device, loading: props.loading })
      );
    } else if (
      props.device.child_device_id != 0 &&
      props.device.subchild_device_id == 0
    ) {
      dispatch(
        setChildLoading({ device: props.device, loading: props.loading })
      );
    } else {
      dispatch(
        setSubChildLoading({ device: props.device, loading: props.loading })
      );
    }
  };

export const setLinkDeviceLoading =
  (props: {
    deviceName: string;
    loading: boolean;
  }): ThunkAction<
    void, // thunk return type
    TAppState, // state type
    any, // extra argument, (not used)
    {
      payload: { deviceName: string; loading: boolean };
      type: 'LINK_DEVICE';
    } // action type
  > =>
  async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
    dispatch(
      setLinkLoading({ deviceName: props.deviceName, loading: props.loading })
    );
  };
export const resetState =
  (props: {
    device: CsvLinkDevice;
  }): ThunkAction<
    void,
    TAppState,
    any,
    { payload: { device: CsvLinkDevice }; type: 'LINK_DEVICE' }
  > =>
  async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
    dispatch(setResetState({ deviceName: props.device.deviceName }));
  };
export const setDone =
  (props: {
    deviceName: string;
  }): ThunkAction<
    void, // thunk return type
    TAppState, // state type
    any, // extra argument, (not used)
    {
      payload: { deviceName: string };
      type: 'LINK_DEVICE';
    } // action type
  > =>
  async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
    dispatch(setLinkDone({ deviceName: props.deviceName }));
  };
export const changeNameById =
  (props: {
    childId: number;
    newName: string;
    parentId: number;
    subchildId: number;
  }): ThunkAction<
    void, // thunk return type
    TAppState, // state type
    any, // extra argument, (not used)
    {
      payload: { device: TreeDevice; newName: boolean };
      type: 'CHANGE_DEVICE_NAME';
    } // action type
  > =>
  async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
    log(`Device by id ${props.newName}`);
    if (props.childId == 0 && props.subchildId == 0) {
      // panel
      getState().deviceState.parentDevices.forEach(device => {
        if (device.parent_device_id == props.parentId) {
          dispatch(
            deviceNameChanged({ device: device, newName: props.newName })
          );
          log(
            `Changing ${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}: ${device.device_name} -> ${props.newName}`
          );
        }
      });
    } else if (props.subchildId == 0) {
      // child
      getState().deviceState.childDevices.forEach(device => {
        if (
          device.parent_device_id == props.parentId &&
          device.child_device_id == props.childId
        ) {
          dispatch(
            deviceNameChanged({ device: device, newName: props.newName })
          );

          log(
            `Changing ${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}: ${device.device_name} -> ${props.newName}`
          );
        }
      });
    } else {
      // subchild
      getState().deviceState.subchildDevices.forEach(device => {
        if (
          device.parent_device_id == props.parentId &&
          device.child_device_id == props.childId &&
          device.subchild_device_id == props.subchildId
        ) {
          dispatch(
            deviceNameChanged({ device: device, newName: props.newName })
          );

          log(
            `Changing ${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}: ${device.device_name} -> ${props.newName}`
          );
        }
      });
    }
  };

export const deviceNameChanged =
  (props: {
    device: TreeDevice;
    newName: string;
  }): ThunkAction<
    void, // thunk return type
    TAppState, // state type
    any, // extra argument, (not used)
    {
      payload: { device: TreeDevice; newName: boolean };
      type: 'CHANGE_DEVICE_NAME';
    } // action type
  > =>
  async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
    log(`Device name to appsync ${props.newName}`);
    let errorText = '';

    dispatch(setDeviceLoading({ device: props.device, loading: true }));

    const newSiteCode = props.newName.split('-')[0].trim();

    log('checking site code changes');
    if (!newSiteCode.endsWith(props.device.SiteCode)) {
      dispatch(setDeviceLoading({ device: props.device, loading: false }));
      dispatch(
        setDeviceErrorText({
          device: props.device,
          errorText: 'Attempting to change device site code not allowed',
        })
      );
      return;
    }

    function checkDupes(
      deviceList: TreeDevice[],
      parentId: number,
      childId: number,
      subchildId: number
    ): boolean {
      log(`Checking for duplicate name: ${props.device.device_name}`);
      const allDevices = [
        ...getState().deviceState.parentDevices,
        ...getState().deviceState.childDevices,
        ...getState().deviceState.subchildDevices,
      ];
      for (const device of allDevices) {
        if (
          /*!(
            device.parent_device_id == props.device.parent_device_id &&
            device.parent_device_id == props.device.child_device_id &&
            device.subchild_device_id == props.device.subchild_device_id
          ) &&*/
          device.device_name === props.newName
        ) {
          dispatch(setDeviceLoading({ device: props.device, loading: false }));
          dispatch(
            setParentErrorText({
              device: props.device,
              errorText: 'Duplicate name found',
            })
          );
          return true;
        }
      }
      return false;
    }

    if (props.device.device_name != props.newName) {
      log('Checking for duplicates');
      let dupefound = false;
      let dupeFoundChild = false;
      let dupeFoundSubChild = false;

      if (
        props.device.child_device_id == 0 &&
        props.device.subchild_device_id == 0
      ) {
        dupefound = checkDupes(
          getState().deviceState.parentDevices,
          props.device.parent_device_id,
          props.device.child_device_id,
          props.device.subchild_device_id
        );
      } else if (props.device.subchild_device_id == 0) {
        dupeFoundChild = checkDupes(
          getState().deviceState.childDevices,
          props.device.parent_device_id,
          props.device.child_device_id,
          props.device.subchild_device_id
        );
      } else {
        dupeFoundSubChild = checkDupes(
          getState().deviceState.subchildDevices,
          props.device.parent_device_id,
          props.device.child_device_id,
          props.device.subchild_device_id
        );
      }
      if (dupeFoundChild) {
        dispatch(setDeviceLoading({ device: props.device, loading: false }));
        dispatch(
          setChildErrorText({
            device: props.device,
            errorText: 'Duplicate name found',
          })
        );
        return;
      }
      if (dupeFoundSubChild) {
        dispatch(setDeviceLoading({ device: props.device, loading: false }));
        dispatch(
          setSubChildErrorText({
            device: props.device,
            errorText: 'Duplicate name found',
          })
        );
        return;
      }

      log('No duplicates found');
      const newDevice: DeviceInput = {
        DeviceSource: props.device.DeviceSource,
        SiteCode: props.device.SiteCode,
        child_device_id: props.device.child_device_id,
        child_device_name: props.device.child_device_name,
        device_name: props.newName,
        device_type_id: props.device.device_type_id,
        device_type_name: props.device.device_type_name,
        device_type_name_special: props.device.device_type_name_special,
        parent_device_id: props.device.parent_device_id,
        parent_device_name: props.device.parent_device_name,
        region_id: props.device.region_id,
        subchild_device_id: props.device.subchild_device_id,
        subchild_device_name: props.device.subchild_device_name,
        device_key: props.device.device_key as string,
        device_href: props.device.device_href,
        previous_name: props.device.device_name,
      };

      // if (checkDeviceNameValid(newDevice as unknown as TreeDevice)) { // Allow only valid device names to be set
      let appsyncResponse: GraphQLResult<RenameDevicesMutation> = {};
      try {
        appsyncResponse = await (API.graphql(
          graphqlOperation(renameDevices, { devices: [newDevice] })
        ) as Promise<GraphQLResult<RenameDevicesMutation>>);
      } catch (e) {
        log('Error with renaming device', false, { error: e });
      }
      if (!appsyncResponse || !appsyncResponse.data?.renameDevices) {
        // Set error text if response is not true
        errorText = 'Server error, please try again later';
        log(`Appsync server error ${props.device.deviceKey}`, true);
      } else {
        if (
          props.device.child_device_id == 0 &&
          props.device.subchild_device_id == 0
        ) {
          dispatch(
            setParentDeviceName({
              device: props.device,
              newName: props.newName,
            })
          );
        } else if (
          props.device.child_device_id != 0 &&
          props.device.subchild_device_id == 0
        ) {
          dispatch(
            setChildDeviceName({
              device: props.device,
              newName: props.newName,
            })
          );
        } else {
          dispatch(
            setSubChildDeviceName({
              device: props.device,
              newName: props.newName,
            })
          );
        }
      }
      // } else {
      //   errorText = 'Device name is invalid';
      // }
    } else {
      log('No change needed');
      errorText = '';
    }
    // Update error text field or remove it if empty
    if (
      props.device.child_device_id == 0 &&
      props.device.subchild_device_id == 0
    ) {
      dispatch(setParentErrorText({ device: props.device, errorText }));
    } else if (
      props.device.child_device_id != 0 &&
      props.device.subchild_device_id == 0
    ) {
      dispatch(setChildErrorText({ device: props.device, errorText }));
    } else {
      dispatch(setSubChildErrorText({ device: props.device, errorText }));
    }

    dispatch(setDeviceLoading({ device: props.device, loading: false }));
  };

export const devicesLinked =
  (
    props: {
      cameraName: string;
      deviceName: string;
      region: number;
    }[]
  ): ThunkAction<
    void, // thunk return type
    TAppState, // state type
    any, // extra argument, (not used)
    {
      payload: { cameraName: string; deviceName: string; region: number };
      type: 'LINK_DEVICE';
    } // action type
  > =>
  async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
    log('Device linking to appsync', false, props);
    let errorText = '';

    props.forEach(link => {
      dispatch(
        setLinkDeviceLoading({ deviceName: link.deviceName, loading: true })
      );
    });
    let appsyncResponse: GraphQLResult<LinkDevicesMutation> = {};
    try {
      appsyncResponse = await (API.graphql(
        graphqlOperation(linkDevices, {
          devices: props,
        })
      ) as Promise<GraphQLResult<LinkDevicesMutation>>);
    } catch (e) {
      log('Error with linking device', false, { error: e });
      props.forEach(link => {
        dispatch(
          setLinkErrorText({
            deviceName: link.deviceName,
            errorText: e instanceof Error ? e.message : 'Unknown error',
          })
        );
      });
    }
    if (
      !appsyncResponse ||
      appsyncResponse.errors?.length ||
      !appsyncResponse.data?.linkDevices
    ) {
      // Set error text if response is not true
      errorText = 'Server error, please try again later';
      log('Appsync server error', true);
      props.forEach(link => {
        dispatch(setLinkErrorText({ deviceName: link.deviceName, errorText }));
      });
    } else {
      log(`Setting tracking number: ${appsyncResponse.data?.linkDevices}`);
      dispatch(
        pollLinking({
          trackingNumber: appsyncResponse.data?.linkDevices || null,
        })
      );
    }
  };

export const pollLinking =
  (props: {
    trackingNumber: string | null;
  }): ThunkAction<
    void, // thunk return type
    TAppState, // state type
    any, // extra argument, (not used)
    {
      payload: { trackingNumber: string | null };
      type: 'POLL_LINKING';
    } // action type
  > =>
  async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
    dispatch(
      setLinkTrackingNumber({
        trackingNumber: props.trackingNumber,
      })
    );

    const tickNum = setInterval(async () => {
      try {
        const appsyncResponse = await (API.graphql(
          graphqlOperation(getDeviceLinkingStatus, {
            trackingNumber: props.trackingNumber,
          })
        ) as Promise<GraphQLResult<GetDeviceLinkingStatusQuery>>);

        if (appsyncResponse.errors?.length) {
          log('error polling device linking', true, appsyncResponse.errors);
        } else if (appsyncResponse.data?.getDeviceLinkingStatus) {
          const results = appsyncResponse.data?.getDeviceLinkingStatus;
          results.forEach(device => {
            if (!device || !device.deviceName) {
              return;
            }

            if (device.status === 'done') {
              dispatch(
                setLinkDone({
                  deviceName: device.deviceName,
                })
              );
            } else if (device.status === 'error') {
              dispatch(
                setLinkErrorText({
                  deviceName: device.deviceName,
                  errorText: device.message || 'Unknown error',
                })
              );
            }
          });

          const isNotDone = results?.find(d => d && d.status === 'started');
          if (!isNotDone) {
            clearInterval(tickNum);
          }
        }
      } catch (e) {
        log('error polling device linking', true, e);
      }
    }, 5000);
  };
