import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import {
  Alert,
  Box,
  Button,
  Container,
  FormField,
  Header,
  Link,
  Modal,
  SpaceBetween,
  StatusIndicator,
  Table,
} from '@amzn/awsui-components-react';
import { getDeviceById, log } from 'src/utils/helpers';
import { useTranslation } from 'react-i18next';
import { TreeDevice } from 'src/types';
import {
  selectShowFileUpload,
  setShowFileUpload,
} from 'src/stores/slices/userSlice';
import { changeNameById } from 'src/features/isc/actions/thunks';
import { CONSTANTS } from 'src/resources/constants';

export interface CsvDevice {
  childId: number;
  currentName?: string;
  deviceName: string;
  errorText?: string;
  loading?: boolean;
  newName?: string;
  parentId: number;
  status?: boolean;
  subchildId: number;
}

function DeviceList(props: { devices: CsvDevice[] }): React.ReactElement {
  const { t } = useTranslation();
  const parentDevices = useAppSelector(
    state => state.deviceState.parentDevices
  );
  const childDevices = useAppSelector(state => state.deviceState.childDevices);
  const subchildDevices = useAppSelector(
    state => state.deviceState.subchildDevices
  );

  if (!props.devices.length) {
    log('No CSV devices');

    return <></>;
  }

  const deviceList: CsvDevice[] = props.devices.map(device => {
    let siteDevice: TreeDevice | undefined;
    if (device.childId == 0 && device.subchildId == 0) {
      siteDevice = getDeviceById(
        parentDevices,
        device.parentId,
        device.childId,
        device.subchildId
      );
    } else if (device.subchildId == 0) {
      siteDevice = getDeviceById(
        childDevices,
        device.parentId,
        device.childId,
        device.subchildId
      );
    } else {
      siteDevice = getDeviceById(
        subchildDevices,
        device.parentId,
        device.childId,
        device.subchildId
      );
    }

    if (siteDevice) {
      return {
        ...device,
        currentName: siteDevice.device_name,
        loading: siteDevice.loading,
        errorText: siteDevice.errorText,
      };
    } else {
      return {
        ...device,
        currentName: device.deviceName,
        loading: device.loading,
        errorText: 'Device not found',
      };
    }
  });

  if (deviceList.length > 0) {
    return (
      <div style={{ overflow: 'auto', width: '100%', height: '70vh' }}>
        <Table
          columnDefinitions={[
            {
              id: 'oldName',
              header: t('Current Name'),
              cell: (item): string => item.currentName || '-',
            },
            {
              id: 'newName',
              header: t('New Name'),
              cell: (item): string => item.newName || '-',
            },
            {
              id: 'status',
              header: t('Status'),
              cell: (item): React.ReactElement => {
                if (item.errorText) {
                  return (
                    <StatusIndicator type={'error'}>
                      {t(item.errorText)}
                    </StatusIndicator>
                  );
                }
                if (item.currentName === item.newName) {
                  return (
                    <StatusIndicator type={'success'}>
                      {t('Success')}
                    </StatusIndicator>
                  );
                }
                if (item.loading) {
                  return (
                    <StatusIndicator type={'loading'}>
                      {t('Working')}
                    </StatusIndicator>
                  );
                }
                return <StatusIndicator type={'stopped'}></StatusIndicator>;
              },
            },
          ]}
          visibleColumns={['oldName', 'newName', 'status']}
          items={deviceList}
          sortingDisabled
          empty={<></>}
          header={<Header> Names to be changed </Header>}
        />
      </div>
    );
  } else {
    return <></>;
  }
}

function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = (): void => {
      if (fr.result) resolve(fr.result?.toString());
      else reject([]);
    };
    fr.onerror = reject;
    fr.readAsText(file);
  });
}

async function loadCsv(file: File): Promise<CsvDevice[]> {
  let outDevices: CsvDevice[] | undefined = [];

  try {
    if (file) {
      const fileText = await readFile(file);
      const csvToArray = fileText
        .split('\n') // split string to lines
        .slice(1) // Remove header
        .map(e => e.trim()) // remove white spaces for each line
        .map(e => e.split(',').map(e => e.trim())) // split each line to array
        .filter(e => e.length > 2); // removes lines without data (sometimes there are lines wih just quotes at the end of file)
      log('Csv to array', false, { csv: csvToArray });
      outDevices = csvToArray?.map(line => {
        return {
          parentId: parseInt(line[0]),
          childId: parseInt(line[1]),
          subchildId: parseInt(line[2]),
          deviceName: line[3],
          newName: line[4],
        } as CsvDevice;
      });
      log('Devices: ', false, { devices: outDevices });
      return outDevices ?? ([] as CsvDevice[]);
    }
  } catch (err) {
    log('Error importing CSV', true, { err: err });
  }
  return [];
}

export default function FileUpload(): React.ReactElement {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [csvDevices, setCsvDevices] = useState<CsvDevice[]>([]);
  const showFileUpload = useAppSelector(selectShowFileUpload);
  const hiddenFileInput = React.createRef<HTMLInputElement>();
  const [importError, setImportError] = useState<string>('');
  const [renamingState, setRenamingState] = useState<boolean>(false);

  function closeFileUpload(): void {
    setImportError('');
    setCsvDevices([]);
    dispatch(setShowFileUpload(false));
    setRenamingState(false);
  }

  async function fileChangeHandler(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    setRenamingState(false);
    setImportError('');
    if (event.target.files && event.target.files[0]) {
      loadCsv(event.target.files[0])
        .then(devices => {
          log('Devices from loadCsv', false, { devices: devices });
          if (devices.length) {
            log('Devices loaded', false, { devices: devices });
            setCsvDevices(devices);
          } else {
            log('No devices loaded');
            setImportError(
              'No Devices Found, please check the format of the file and try again'
            );
          }
        })
        .catch(() => {
          log('Error loading csv', true);
        });
    }
    if (hiddenFileInput.current?.value) hiddenFileInput.current.value = '';
  }

  function acceptHandler(): void {
    // Push to appsync, devices will be updated async
    const newDevices = csvDevices;
    for (const device of newDevices) {
      if (device.newName) {
        device.loading = true;
        dispatch(
          changeNameById({
            newName: device.newName,
            parentId: device.parentId,
            childId: device.childId,
            subchildId: device.subchildId,
          })
        );
      }
    }
    setRenamingState(true);
    setCsvDevices(newDevices);
  }

  return (
    <Modal
      visible={showFileUpload}
      onDismiss={closeFileUpload}
      closeAriaLabel={'close csv file upload window'}
      header={'Select file to upload'}
      size={'max'}
      footer={
        <>
          <Box float={'right'}>
            <SpaceBetween size={'m'} direction={'horizontal'}>
              {!renamingState && (
                <Button
                  onClick={acceptHandler}
                  disabled={csvDevices.length <= 0}>
                  Accept/Continue
                </Button>
              )}

              <Button onClick={closeFileUpload}>
                {renamingState ? 'Close' : 'Cancel'}
              </Button>
            </SpaceBetween>
          </Box>
        </>
      }>
      <Alert visible={importError != ''}>{importError}</Alert>
      <Container>
        <p>
          {t(
            'Choose a file to upload. The file must be an export from Site Testing Tool.'
          )}
        </p>
        <p>
          For CSV template please see&nbsp;
          <Link href={CONSTANTS.HELP_LINK} external target={'_blank'}>
            user documentation
          </Link>
        </p>
        <FormField
          id="csvUploadForm"
          // label={t('CSV Upload')}
        >
          <input
            ref={hiddenFileInput}
            id="chooseCsvInput"
            type="file"
            hidden
            multiple={false}
            accept="text/csv"
            onChange={fileChangeHandler}
          />
          <Button
            iconName={'upload'}
            formAction="none"
            onClick={(): void => {
              hiddenFileInput.current?.click();
            }}>
            Choose CSV File
          </Button>
        </FormField>
      </Container>
      <DeviceList devices={csvDevices} />
    </Modal>
  );
}
