import * as React from 'react';

import Alert from '@amzn/awsui-components-react/polaris/alert';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import {
  removeSuccessMessage,
  selectSuccessMessages,
} from 'src/stores/slices/milestones/tree/treeSlice';

const TreeSuccessMessages = () => {
  const successMessages = useAppSelector(selectSuccessMessages);
  const dispatch = useAppDispatch();

  const handleOnDismiss = (message: string) => {
    dispatch(removeSuccessMessage(message));
  };

  return (
    <>
      {successMessages.map(message => (
        <Alert
          key={message}
          dismissible={true}
          type="success"
          onDismiss={() => handleOnDismiss(message)}>
          {message}
        </Alert>
      ))}
    </>
  );
};

export default TreeSuccessMessages;
