export interface EnvironmentVariables {
  readonly AWS_ACCESS_KEY_ID: string;
  readonly AWS_SECRET_ACCESS_KEY: string;
  readonly appsyncEndpoint: string;
  readonly awsAccount: string;
  readonly awsRegion: string;
  readonly cloudFrontDomain: string;
  readonly cloudFrontProtocol: string;
  readonly identifyPoolDomain: string;
  readonly identityPoolId: string;
  readonly stage: string;
  readonly userPoolClientId: string;
  readonly userPoolId: string;
}

export const envVariables: EnvironmentVariables = {
  awsAccount: '803225719980',
  identityPoolId: 'us-east-1:3ef4a19b-30ac-4024-b028-1c49a67b23dc',
  userPoolClientId: '6d6rbqcmmsp4prrsd4nr026doi',
  userPoolId: 'us-east-1_HDFcuAnFR',
  identifyPoolDomain: 'acstools-devicerename-prod.auth.us-east-1.amazoncognito.com',
  awsRegion: 'us-east-1',
  stage: 'prod',
  cloudFrontProtocol: 'https',
  cloudFrontDomain: 'd217llmxzil1xb.cloudfront.net',
  AWS_ACCESS_KEY_ID: '{{AWS_ACCESS_KEY_ID}}',
  AWS_SECRET_ACCESS_KEY: '{{AWS_SECRET_ACCESS_KEY}}',
  appsyncEndpoint: 'api.device-rename.acs.amazon.dev',
};

export const MIDWAY = {
  URL: 'https://midway-auth.amazon.com/SSO',
  LOGOUT_URL: 'https://midway-auth.amazon.com/SSO/redirect',
  SCOPE: 'openid',
  RESPONSE_TYPE: 'id_token',
  SENTRY_HANDLER_VERSION: 'MidwayNginxModule-1.3-1',
};
