import {
  AnyAction,
  Store,
  ThunkDispatch,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { Reducer } from 'react';
import { log } from 'src/utils/helpers';

import userStateReducer, { initalUserState } from 'src/stores/slices/userSlice';
import userPrefsStateReducer, {
  IUserPreferences,
} from 'src/stores/slices/userPrefsSlice';
import rootSaga from '@features/isc/sagas';
import createSagaMiddleware from 'redux-saga';
import devStateReducer, {
  initalDevState,
} from 'src/stores/slices/isc/devSlice';
import devicesStateReducer, {
  initialDevicesState,
} from 'src/stores/slices/isc/devicesSlice';
import treeStateReducer, {
  initialTreeState,
} from '@stores/slices/milestones/tree/treeSlice';
import milestonesStateReducer, {
  initialMilestonesState,
} from '@stores/slices/milestones/milestonesSlice';
import {
  flashBarReducer,
  initialFlashBarState,
} from '@stores/slices/milestones/flashBar/flashBarSlice';

export type TAppState = ReturnType<typeof rootReducer>;
export type TDispatch = ThunkDispatch<TAppState, void, AnyAction>;
export type TStore = Store<TAppState, AnyAction> & { dispatch: TDispatch };
export type TGetState = () => TAppState;

type TAction = AnyAction;

export type TRootReducer = Reducer<
  {
    devState: typeof devStateReducer;
    deviceState: typeof devicesStateReducer;
    treeState: typeof treeStateReducer;
    userPrefsState: typeof userPrefsStateReducer;
    userState: typeof userStateReducer;
  },
  TAction
>;

export const loadUserPrefsState = (): IUserPreferences | undefined => {
  try {
    const serializedState = localStorage.getItem('userPrefsState');
    if (!serializedState) return undefined;
    else {
      const prefs: IUserPreferences = JSON.parse(serializedState);
      return prefs;
    }
  } catch (err) {
    return undefined;
  }
};

export const saveUserPrefsState = (state: any): void => {
  try {
    const serializedState = JSON.stringify(state.userPrefsState);
    localStorage.setItem('userPrefsState', serializedState);
  } catch (err) {
    log(err, true);
  }
};

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  devState: devStateReducer,
  deviceState: devicesStateReducer,
  userState: userStateReducer,
  userPrefsState: userPrefsStateReducer,
  treeState: treeStateReducer,
  milestonesState: milestonesStateReducer,
  flashBarState: flashBarReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(sagaMiddleware),
  preloadedState: {
    userPrefsState: loadUserPrefsState(),
    userState: initalUserState,
    deviceState: initialDevicesState,
    devState: initalDevState,
    treeState: initialTreeState,
    milestonesState: initialMilestonesState,
    flashBarState: initialFlashBarState,
  },
  devTools: true,
  // TODO: Turn this off in production eventually
});

sagaMiddleware.run(rootSaga);

store.subscribe(() => {
  saveUserPrefsState(store.getState());
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
