/* eslint-disable no-console */
import React, { ReactElement, useState } from 'react';
import Modal, { ModalProps } from '@amzn/awsui-components-react/polaris/modal';
import Box from '@amzn/awsui-components-react/polaris/box';
import Container from '@amzn/awsui-components-react/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import CsvUploadForm from '@features/isc/CsvUploadForm';
import { UINode } from '@features/milestones/types/ui-types';
import {
  selectMilestonesCsvUpload,
  toggleShowMilestonesCsvUpload,
} from '@stores/slices/userSlice';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import {
  ChangeRenameStatusFn,
  CsvNode,
  RenameStatus,
  getCsvNodeFromImportCsvRow,
} from 'src/utils/csv';
import CsvNodeList from './CsvNodeList';

enum ModalSize {
  Max = 'max',
  Medium = 'medium',
  Small = 'small',
}

export type UploadCsvModalProps = {
  onRenameStatusChange: ChangeRenameStatusFn;
  renameCallback: (node: UINode, newName: string) => Promise<void>;
  renameStatusMap: Record<string, RenameStatus>;
};

export const UploadCsvModal = ({
  onRenameStatusChange,
  renameCallback,
  renameStatusMap,
}: UploadCsvModalProps): ReactElement => {
  const showMilestonesCsvUpload = useAppSelector(selectMilestonesCsvUpload);
  const [csvNodes, setCsvNodes] = useState<CsvNode[]>([]);
  const [renameClicked, setRenameClicked] = useState<boolean>(false);
  const [modalSize, setModalSize] = useState<ModalProps.Size>(ModalSize.Medium);
  const dispatch = useAppDispatch();

  function closeUploadCsvModal(): void {
    dispatch(toggleShowMilestonesCsvUpload());
    setCsvNodes([]);
    setRenameClicked(false);
    setModalSize(ModalSize.Medium);
  }

  function handleCsvUploadEnd() {
    setModalSize(ModalSize.Max);
  }

  function parseCsv(csvData: string[][]): void {
    // [location, regionName, type, name, newName]
    csvData.forEach(row => {
      const node = getCsvNodeFromImportCsvRow(row);
      setCsvNodes(csvNodes => [...csvNodes, node]);
      onRenameStatusChange(node.id, RenameStatus.Init);
    });
  }

  function handleRenamings(): void {
    csvNodes.forEach(node => renameCallback(node as UINode, node.newName));
    setRenameClicked(true);
  }

  return (
    <Modal
      onDismiss={closeUploadCsvModal}
      visible={showMilestonesCsvUpload}
      size={modalSize}
      footer={
        <>
          {renameClicked && (
            <Box float="right">
              <Button variant="primary" onClick={closeUploadCsvModal}>
                Dismiss
              </Button>
            </Box>
          )}
          {!renameClicked && (
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={closeUploadCsvModal}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleRenamings}>
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          )}
        </>
      }
      header="Upload CSV file">
      <CsvNodeList
        nodeList={csvNodes}
        renameStatusMap={renameStatusMap}></CsvNodeList>
      {csvNodes.length === 0 && (
        <Container>
          <p>Choose a CSV file to upload.</p>
          <CsvUploadForm
            onCsvUploadEnd={handleCsvUploadEnd}
            parseCsv={parseCsv}></CsvUploadForm>
        </Container>
      )}
    </Modal>
  );
};

export default UploadCsvModal;
