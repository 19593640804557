import React, { ReactElement } from 'react';
import {
  Icon,
  Popover,
  SpaceBetween,
  Spinner,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import { NodeTypes } from '@features/milestones/types/api-types';
import { UINode } from '@features/milestones/types/ui-types';
import { StyledCheckbox } from './Checkbox';
import InfoPopover from './tooltip/InfoPopover';
import InlineMenu from './menus/InlineMenu';

const ErrorRenamingPopover = ({
  renamingError,
}: {
  renamingError: string;
}): ReactElement => {
  return (
    <Popover
      dismissButton={false}
      size="medium"
      triggerType="custom"
      renderWithPortal
      content={<StatusIndicator type="error">{renamingError}</StatusIndicator>}>
      <Icon name="status-negative" variant="error" />
    </Popover>
  );
};

const TreeNodeText = ({ node }: { node: UINode }): ReactElement => {
  const getNameColor = (): string => {
    return node.isInvalidName ? 'red' : node.isHighlight ? 'deeppink' : '';
  };

  return (
    <SpaceBetween size="xs" direction={'horizontal'}>
      <span style={{ color: getNameColor() }}>{node.name}</span>
      {node.isLoading && <Spinner />}
      {node.renamingError && (
        <ErrorRenamingPopover renamingError={node.renamingError} />
      )}
    </SpaceBetween>
  );
};

const TreeNode = ({ node }: { node: UINode }): ReactElement | null => {
  return node.isHidden ? null : (
    <SpaceBetween size="xs" direction={'horizontal'}>
      <StyledCheckbox node={node} />
      <InfoPopover node={node} />
      <TreeNodeText node={node} />
      {node.type !== NodeTypes.Site && <InlineMenu node={node} />}
    </SpaceBetween>
  );
};

export default TreeNode;
