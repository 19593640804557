import * as React from 'react';
import { useAppSelector } from 'src/stores/slices/hooks';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/stores/slices/store';
import {
  Box,
  Button,
  Modal,
  Popover,
  SpaceBetween,
  StatusIndicator,
  Toggle,
} from '@amzn/awsui-components-react';
import {
  toggleDevMode,
  toggleShowDevPanel,
} from 'src/stores/slices/isc/devSlice';
import { envVariables } from 'src/resources/envVariables';

export default function DevPanel(): React.ReactElement {
  const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
  const dispatch = useAppDispatch();

  const devMode = useAppSelector(state => state.devState.devMode);
  const showDevPanel = useAppSelector(state => state.devState.showDevPanel);
  const devices = useAppSelector(state => state.deviceState.parentDevices);

  const stage = envVariables.stage;

  if (showDevPanel && stage != 'prod') {
    return (
      <Modal
        visible={showDevPanel}
        header="Dev Panel"
        onDismiss={(): unknown => dispatch(toggleShowDevPanel())}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="primary"
                onClick={(): unknown => dispatch(toggleShowDevPanel())}>
                Dismiss
              </Button>
            </SpaceBetween>
          </Box>
        }>
        <Toggle
          checked={devMode}
          onChange={(): unknown => dispatch(toggleDevMode())}>
          Enable dev mode
        </Toggle>

        <span className="custom-wrapping">
          <Popover
            size="small"
            position="top"
            triggerType="custom"
            dismissButton={false}
            content={
              <StatusIndicator type="success">Devices Copied</StatusIndicator>
            }>
            <Button
              variant="inline-icon"
              iconName="copy"
              onClick={(): void => {
                navigator.clipboard.writeText(JSON.stringify(devices));
              }}
            />
          </Popover>
          Copy Devices
        </span>
      </Modal>
    );
  } else {
    return <></>;
  }
}
