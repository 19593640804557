import React, { Suspense } from 'react';

import TreeLayout from '@features/milestones/tree/TreeLayout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import MilestonesContent from './layout/MilestonesContent';
import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import { useMilestonesNavigate } from 'src/features/milestones/hooks/useMilestonesNavigate';

/**
 * If searchParams are present in the URL, we will construct the tree based on search Params
 * Example: /tree?site=site1 or /tree?recorder=recorder1
 * For multiple sites or recorders, we will use selectedTreeSites and selectedTreeRecorders to construct the tree
 * To ensure, there is no navigation bugs, we will use /tree/sites and /tree/recorders for multiple sites/recorders
 */
const TreePage = () => {
  const { navigateToSitesView, navigateToRecodersView } =
    useMilestonesNavigate();
  const content = (
    <Container
      header={
        <Header variant="h2">
          <BreadcrumbGroup
            items={[
              { text: 'Sites View', href: '#' },
              { text: 'Recorders View', href: '#' },
              { text: 'Tree View', href: '#' },
            ]}
            ariaLabel="Breadcrumbs"
            onClick={event => {
              switch (event.detail.text) {
                case 'Sites View':
                  navigateToSitesView();
                  break;
                case 'Recorders View':
                  navigateToRecodersView();
                  break;
                default:
                  break;
              }
            }}
          />
        </Header>
      }>
      <Suspense fallback={<Spinner size="large" />}>
        <TreeLayout />
      </Suspense>
    </Container>
  );
  return <MilestonesContent contentType="default" content={content} />;
};

export default TreePage;
