import { UINode as Node } from 'src/features/milestones/types/ui-types';

export const expandCollapseNodeAction = (
  nodes: Node[],
  id: string,
  expanded: boolean
) => {
  nodes.forEach(node => {
    if (node.id === id) {
      node.isExpanded = expanded;
      if (node.children) {
        expandCollapseAllNodesAction(node.children, false);
      }
    }
    if (node.children) {
      expandCollapseNodeAction(node.children, id, expanded);
    }
  });
};

export const expandCollapseAllNodesAction = (
  nodes: Node[],
  isExpanded: boolean
): void => {
  nodes.forEach(node => {
    node.isExpanded = isExpanded;

    if (node.children) {
      expandCollapseAllNodesAction(node.children, isExpanded);
    }
  });
};
/**
 * Expand the tree path with all checked nodes
 * If a child of a node is checked, we will expand the parent node
 * Even if the parent node is not checked
 * */
export const expandCheckNodesAction = (nodes: Node[]): void => {
  nodes.forEach(node => {
    let isExpanded = node.isExpanded;
    if (node.isChecked) isExpanded = true;
    if (node.children?.length) {
      expandCheckNodesAction(node.children);
      const children = node.children;
      isExpanded = node.isExpanded || children.some(child => child.isExpanded);
    }
    node.isExpanded = isExpanded;
  });
};

/**
 * Collapse all checked nodes
 */
export function collapseCheckNodesAction(nodes: Node[]): void {
  nodes.forEach(node => {
    let isExpanded = node.isExpanded;
    if (node.isChecked) isExpanded = false;
    if (node.children) {
      collapseCheckNodesAction(node.children);
    }
    node.isExpanded = isExpanded;
  });
}
