import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';

export const TestIds = {
  NOTIFICATIONS: 'app-layout-notifications',
};

export const LOADING_TEMPLATE: FlashbarProps.MessageDefinition = {
  type: 'success',
  loading: true,
  id: 'loading-notification',
};

export const ERROR_TEMPLATE: FlashbarProps.MessageDefinition = {
  type: 'error',
  dismissible: true,
  dismissLabel: 'Dismiss message',
  id: 'error-notification',
};

export const SUCCESS_TEMPLATE: FlashbarProps.MessageDefinition = {
  type: 'success',
  dismissible: true,
  dismissLabel: 'Dismiss message',
  id: 'success-notification',
};

export enum FlashBarMessageStatus {
  Error = 'ERROR',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
  Success = 'SUCCESS',
}

export interface FlashBarMessage {
  bulletPoints?: string[];
  isDismissible: boolean;
  message: string;
  status: FlashBarMessageStatus;
}

export function createErrorsMessage(message: string): FlashBarMessage {
  return {
    message,
    status: FlashBarMessageStatus.Error,
    isDismissible: true,
  };
}

export function createTaskSucceededMessage(message: string): FlashBarMessage {
  return {
    message,
    status: FlashBarMessageStatus.Success,
    isDismissible: true,
  };
}

export function createInProgressMessage(message: string) {
  return {
    message,
    status: FlashBarMessageStatus.Started,
    isDismissible: true,
  };
}

export function createNotStartedTaskMessage(
  message: string,
  id: string
): FlashBarMessage {
  return {
    message,
    status: FlashBarMessageStatus.NotStarted,
    isDismissible: true,
  };
}
