import React from 'react';
import { Icon, IconProps, SpaceBetween } from '@amzn/awsui-components-react';
import EncoderIcon from './EncoderIcon';

/*
 * https://heroicons.com/
 * All icons must be wrapped inside AWS Cloudscape component for proper styling
 * We may utilize variant to mark a node as under construction or errors
 */
const SingleMetadataIcon = ({ variant }: IconProps) => {
  return (
    <Icon
      variant={variant || 'link'}
      svg={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14.25 9.75 16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"
          />
        </svg>
      }
    />
  );
};

const EncoderMetadataIcon = ({ variant }: IconProps) => {
  return (
    <SpaceBetween size="xxxs" direction="horizontal">
      {/* <Badge color="blue">Meta</Badge> */}
      <EncoderIcon variant={variant} />
      <SingleMetadataIcon variant={variant} />
    </SpaceBetween>
  );
};

export default EncoderMetadataIcon;
