import React from 'react';
import {
  Box,
  Button,
  Container,
  Flashbar,
  Grid,
  Link,
  Modal,
  Multiselect,
  Popover,
  SelectProps,
  SpaceBetween,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import {
  selectShowSelectSite,
  toggleSelectSite,
} from 'src/stores/slices/userSlice';
import { useTranslation } from 'react-i18next';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { CONSTANTS } from 'src/resources/constants';
import {
  selectSelectedSiteCodes,
  setSelectedSites,
} from 'src/stores/slices/userPrefsSlice';
import { updateNodesWithRemovedSiteCodes } from 'src/stores/slices/milestones/tree/treeSlice';
import { updateSelectedWithRemovedSiteCodes } from 'src/stores/slices/milestones/milestonesSlice';

const SelectSite = (): React.ReactElement => {
  const { t } = useTranslation();
  const [flashbarContent, setFlashbarContent] = React.useState(
    [] as FlashbarProps.MessageDefinition[]
  );

  const allowedSitesFromState = useAppSelector(
    state => state.deviceState.allowedSites
  );
  const selectedSitesFromState = useAppSelector(selectSelectedSiteCodes);
  const showSelectSite = useAppSelector(selectShowSelectSite);

  const dispatch = useAppDispatch();

  function continueClick(): void {
    dispatch(toggleSelectSite());
  }

  function exitWarn(): void {
    if (selectedSitesFromState.length <= 0) {
      setFlashbarContent([
        {
          type: 'info',
          dismissible: true,
          dismissLabel: t('Dismiss message'),
          header: 'Exit selecting sites',
          onDismiss: (): void => setFlashbarContent([]),
          content: <>{t('Please select site(s)')}</>,
        },
      ]);
    } else {
      dispatch(toggleSelectSite());
    }
  }

  return (
    <>
      <Modal
        id={'selectSiteModal'}
        visible={showSelectSite}
        size="large"
        header={t('Select Site(s)')}
        onDismiss={(): void => exitWarn()}
        footer={
          <Grid
            gridDefinition={[
              { colspan: 4 },
              { colspan: 1 },
              { colspan: 3 },
              { colspan: 1 },
              { colspan: 3 },
            ]}>
            <div></div>
            <div />
            <div>
              <Link href={CONSTANTS.HELP_LINK} external target={'_blank'}>
                Need Assistance?
              </Link>
            </div>
            <div />
            <div>
              <Box float={'right'}>
                <Popover
                  content={
                    <StatusIndicator type="warning">
                      {t('Please select a site to continue')}
                    </StatusIndicator>
                  }
                  header=" " // remove console warning
                  position="top"
                  size="large"
                  triggerType="custom">
                  <Button
                    id={'selectSiteContinueButton'}
                    onClick={continueClick}
                    variant="primary"
                    disabled={selectedSitesFromState.length <= 0}>
                    Continue
                  </Button>
                </Popover>
              </Box>
            </div>
          </Grid>
        }>
        <Container>
          <SpaceBetween size="xxl" direction="vertical">
            <Flashbar items={flashbarContent} />

            <Multiselect
              selectedOptions={
                selectedSitesFromState.map(s => {
                  return {
                    value: s,
                    label: s,
                  } as SelectProps.Option;
                }) as SelectProps.Options
              }
              onChange={({ detail }): void => {
                const siteCodes = detail.selectedOptions.map(siteOption => {
                  return siteOption.label ?? '';
                });
                const removedSiteCodes = selectedSitesFromState.filter(
                  siteCode => !siteCodes.includes(siteCode)
                );

                dispatch(setSelectedSites(siteCodes));

                if (removedSiteCodes.length > 0) {
                  dispatch(updateNodesWithRemovedSiteCodes(removedSiteCodes));
                  dispatch(
                    updateSelectedWithRemovedSiteCodes(removedSiteCodes)
                  );
                }
              }}
              filteringType="auto"
              placeholder={t('Select Site(s)')}
              empty={t('No Sites Assigned Please Contact Support')} //TODO: Show help link
              options={
                allowedSitesFromState.map(allowedSiteCode => {
                  const siteCode = allowedSiteCode.SiteCode;
                  return {
                    value: siteCode,
                    label: siteCode,
                  } as SelectProps.Option;
                }) as SelectProps.Options
              }
              /*expandToViewport={true}
                        keepOpen={false}*/
              virtualScroll={true}
              loadingText={'Loading your sites, please wait'}
              id={'selectSites'}
              selectedAriaLabel="Selected"
              //onLoadItems={(e) => log('OnLoad ' + JSON.stringify(e))}
            />
          </SpaceBetween>
        </Container>
      </Modal>
    </>
  );
};

export default SelectSite;
