export const SPLIT_PANEL_I18NSTRINGS = {
  preferencesTitle: 'Split panel preferences',
  preferencesPositionLabel: 'Split panel position',
  preferencesPositionDescription:
    'Choose the default split panel position for the service.',
  preferencesPositionSide: 'Side',
  preferencesPositionBottom: 'Bottom',
  preferencesConfirm: 'Confirm',
  preferencesCancel: 'Cancel',
  closeButtonAriaLabel: 'Close panel',
  openButtonAriaLabel: 'Open panel',
  resizeHandleAriaLabel: 'Resize split panel',
};
export const CONSTANTS = {
  WIKI_LINK:
    'https://w.amazon.com/bin/view/AmazonCorporateSecurity/TechnicalSecurity/Infrastructure/Services/ACSDeviceRenameV2',
  HELP_LINK:
    'https://w.amazon.com/bin/view/AmazonCorporateSecurity/TechnicalSecurity/Infrastructure/Services/ACSDeviceRenameV2',
  REPORT_BUG:
    'https://t.corp.amazon.com/create/templates/04f18bd7-100f-48c7-8a9f-04ff49bb5d08',
  FEATURE_REQUEST:
    'https://t.corp.amazon.com/create/templates/99318820-107c-4b6d-9d4a-13e4a2fb5a28',
};
