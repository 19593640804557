import React from 'react';
import { Box, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { TreeDevice } from 'src/types';
import { checkDeviceNameValid, log } from 'src/utils/helpers';
import { BsCameraVideo, BsQuestionLg } from 'react-icons/bs';
import { VscCircuitBoard, VscTools } from 'react-icons/vsc';
import { FaNetworkWired } from 'react-icons/fa';
import { GiTRexSkull } from 'react-icons/gi';
import { MdOutlineSensors } from 'react-icons/md';
import { RiAlarmWarningLine } from 'react-icons/ri';
import { TiCalculator } from 'react-icons/ti';
import { IconContext } from 'react-icons/lib';
import { deviceNameChanged, setChecked } from 'src/features/isc/actions/thunks';
import ExpandableDevice from 'src/features/isc/ExpandableDevice/ExpandableDevice';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import {
  setChildExpanded,
  setParentExpanded,
  setSubchildExpanded,
} from 'src/stores/slices/isc/devicesSlice';
import { selectGlobalIsLoading } from 'src/stores/slices/userSlice';
import { selectDarkMode } from 'src/stores/slices/userPrefsSlice';

const iconSize = '1.4em';
const textSize = '1.5em';

function TreeDeviceIcon(props: { device: TreeDevice }): React.ReactElement {
  const darkMode = useAppSelector(selectDarkMode);
  let deviceImage: React.ReactNode;
  const device = props.device;

  const alt = device.device_type_name_special
    ? device.device_type_name_special.replace('_', ' ')
    : 'invalid device';

  const normalColor = darkMode ? 'white' : 'black';
  const warnColor = darkMode ? 'red' : 'red';
  const iconStyle = {
    color: normalColor,
    className: 'inline',
    size: iconSize,
  };

  if (device.invalidName) {
    iconStyle.color = warnColor;
  }

  switch (device.device_type_name_special) {
    case 'parent_device':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'parent'}>
          <FaNetworkWired />
        </IconContext.Provider>
      );
      break;
    case 'camera':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'cam'}>
          <BsCameraVideo />
        </IconContext.Provider>
      );
      break;
    case 'card_reader':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'cr'}>
          <TiCalculator />
        </IconContext.Provider>
      );
      break;
    case 'card_reader_rex':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'crr'}>
          <GiTRexSkull />
        </IconContext.Provider>
      );
      break;
    case 'account_input':
    case 'card_reader_aux_input_1':
    case 'card_reader_aux_input_2':
    case 'alarm_panel_input':
    case 'intrusion_panel_input':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'ipi'}>
          <MdOutlineSensors />
        </IconContext.Provider>
      );
      break;

    case 'card_reader_door_contact':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'crdc'}>
          <VscTools />
        </IconContext.Provider>
      );
      break;
    case 'card_reader_aux_output_1':
    case 'card_reader_aux_output_2':
    case 'intrusion_panel_output_onboard':
    case 'alarm_panel_output':
    case 'intrusion_panel_output':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'ipo'}>
          <RiAlarmWarningLine />
        </IconContext.Provider>
      );
      break;
    case 'alarm_panel':
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'ap'}>
          <VscCircuitBoard />
        </IconContext.Provider>
      );
      break;

    default:
      log(`No icon found for device type: ${alt}: ${device.device_name}`);
      deviceImage = (
        <IconContext.Provider value={iconStyle} key={'q'}>
          <BsQuestionLg />
        </IconContext.Provider>
      );
      break;
  }

  //ImCross - x

  return <span className="stDeviceImageParent">{deviceImage}</span>;
}

function getDeviceName(device: TreeDevice): string {
  let deviceName = device.device_name;
  if (!deviceName) {
    if (device.subchild_device_id == 0 && device.child_device_id == 0) {
      deviceName = device.parent_device_name ?? 'No Name for Device';
    } else if (device.subchild_device_id == 0) {
      deviceName = device.child_device_name ?? 'No Name for Device';
    } else {
      deviceName = device.subchild_device_name ?? 'No Name for Device';
    }
  }
  return deviceName;
}

export function SubChild(props: { subchild: TreeDevice }): React.ReactElement {
  const dispatch = useAppDispatch();

  let errorText = '';
  let devicePatterns;
  if (props.subchild.errorText && props.subchild.errorText != '') {
    errorText = props.subchild.errorText;
  } else {
    devicePatterns = checkDeviceNameValid(props.subchild);
    if (devicePatterns.length) {
      errorText = 'Invalid Device Name';
    }
  }

  return (
    <ExpandableDevice
      key={`sc${props.subchild.deviceKey}`}
      deviceIcon={<TreeDeviceIcon device={props.subchild} />}
      deviceName={getDeviceName(props.subchild)}
      textSize={textSize}
      iconSize={iconSize}
      loading={props.subchild.loading}
      className={'statusTreeItem statusTreeChild2'}
      expanded={props.subchild.expanded}
      checked={props.subchild.checked}
      errorText={errorText}
      devicePatterns={devicePatterns}
      onChangeExpanded={(change): void => {
        log('Change detail', false, { change: change });
        dispatch(
          setSubchildExpanded({
            device: props.subchild,
            expanded: change.expanded,
          })
        );
      }}
      onCheckChanged={(change): void => {
        dispatch(
          setChecked({ device: props.subchild, checked: change.checked })
        );
      }}
      onDeviceNameChanged={(change): void => {
        dispatch(
          deviceNameChanged({ device: props.subchild, newName: change.newName })
        );
      }}
    />
  );
}

export function Child(props: { child: TreeDevice }): React.ReactElement {
  const dispatch = useAppDispatch();
  const subchildDevices = useAppSelector(
    state => state.deviceState.subchildDevices
  );
  const subChildren = subchildDevices.filter(subchild => {
    return (
      subchild.deviceKey != props.child.deviceKey &&
      props.child.parent_device_id == subchild.parent_device_id &&
      subchild.child_device_id == props.child.child_device_id &&
      subchild.subchild_device_id != 0
    );
  });
  const renderedSubchildren = subChildren.map(subChild => {
    return (
      <SubChild
        key={`${subChild.deviceKey}_${subChild.device_name}_subchild`}
        subchild={subChild}
      />
    );
  });
  let errorText = '';
  let devicePatterns;
  if (props.child.errorText && props.child.errorText != '') {
    errorText = props.child.errorText;
  } else {
    devicePatterns = checkDeviceNameValid(props.child);
    if (devicePatterns.length) {
      errorText = 'Invalid Device Name';
    }
  }

  return (
    <ExpandableDevice
      key={`child${props.child.deviceKey}`}
      /* eslint-disable */
      children={renderedSubchildren}
      /* eslint-disable */
      deviceIcon={<TreeDeviceIcon device={props.child} />}
      deviceName={getDeviceName(props.child)}
      textSize={textSize}
      iconSize={iconSize}
      loading={props.child.loading}
      className={'statusTreeItem statusTreeChild1'}
      expanded={props.child.expanded}
      checked={props.child.checked}
      errorText={errorText}
      devicePatterns={devicePatterns}
      onChangeExpanded={(change): void => {
        log('Change detail', false, { change: change });
        dispatch(
          setChildExpanded({ device: props.child, expanded: change.expanded })
        );
      }}
      onCheckChanged={(change): void => {
        dispatch(setChecked({ device: props.child, checked: change.checked }));
      }}
      onDeviceNameChanged={(change): void => {
        dispatch(
          deviceNameChanged({ device: props.child, newName: change.newName })
        );
      }}
    />
  );
}

export function Isc(props: { device: TreeDevice }): React.ReactElement {
  const dispatch = useAppDispatch();
  const childDevices = useAppSelector(state => state.deviceState.childDevices);
  const children = childDevices.filter(device => {
    return device.parent_device_id == props.device.parent_device_id;
  });
  const renderedChildren = children.map(child => {
    return <Child key={`${child.deviceKey}_child`} child={child} />;
  });

  let errorText = '';
  let devicePatterns;
  if (props.device.errorText && props.device.errorText != '') {
    errorText = props.device.errorText;
  } else {
    devicePatterns = checkDeviceNameValid(props.device);
    if (devicePatterns.length) {
      errorText = 'Invalid Device Name';
    }
  }

  return (
    <ExpandableDevice
      key={`isc${props.device.deviceKey}`}
      children={renderedChildren}
      deviceIcon={<TreeDeviceIcon device={props.device} />}
      deviceName={getDeviceName(props.device)}
      textSize={textSize}
      iconSize={iconSize}
      loading={props.device.loading}
      className={'statusTreeItem statusTreeChild0'}
      expanded={props.device.expanded}
      checked={props.device.checked}
      errorText={errorText}
      devicePatterns={devicePatterns}
      badgeText={props.device.DeviceSource.toLocaleUpperCase()}
      badgeColor={
        props.device.DeviceSource.toLowerCase() == 'onguard' ? 'blue' : 'green'
      }
      onChangeExpanded={(change): void => {
        //dispatch(setChecked({ device: props.device, checked: change.detail.expanded }));
        dispatch(
          setParentExpanded({ device: props.device, expanded: change.expanded })
        );
      }}
      onCheckChanged={(change): void => {
        dispatch(setChecked({ device: props.device, checked: change.checked }));
      }}
      onDeviceNameChanged={(change): void => {
        dispatch(
          deviceNameChanged({ device: props.device, newName: change.newName })
        );
      }}
    />
  );
}

export function DeviceTree(): React.ReactElement {
  const parentDevices = useAppSelector(
    state => state.deviceState.parentDevices
  );
  const globalIsLoading = useAppSelector(selectGlobalIsLoading);

  const renderedIscs = parentDevices.map(isc => {
    return <Isc key={`${isc.deviceKey}_isc`} device={isc}></Isc>;
  });

  if (!globalIsLoading) {
    return <Box>{renderedIscs}</Box>;
  } else if (!globalIsLoading && !parentDevices.length) {
    return <h4>No Devices Returned</h4>;
  }
  return (
    <Box textAlign="center" color="inherit" key={'deviceTree'}>
      <SpaceBetween size={'l'}>
        <Spinner size={'large'} />
        <b>Loading Devices...</b>
      </SpaceBetween>
    </Box>
  );
}
