import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CacheKeys, getArrayCacheKey } from './cache-keys';
import {
  GetRecorderServersQuery,
  GetRecorderServersQueryVariables,
  RecorderServersResponse,
} from 'src/API';
import { API, graphqlOperation } from 'aws-amplify';
import { getRecorderServers } from 'src/graphql/queries';
import { log } from 'src/utils/helpers';
import { GraphQLResult } from '@aws-amplify/api-graphql';

/**
 * Pure API call.
 */
export async function apiGetRecorderServers(
  queryProps: GetRecorderServersQueryVariables
): Promise<RecorderServersResponse> {
  const response = (await API.graphql(
    graphqlOperation(getRecorderServers, queryProps)
  )) as GraphQLResult<GetRecorderServersQuery>;

  if (response.data?.getRecorderServers?.status !== 200) {
    throw new Error(
      `Failed getRecordersServer with status: ${response.data?.getRecorderServers?.status}`
    );
  }

  if (!response.data?.getRecorderServers?.recorders) {
    throw new Error(
      `Failed getRecordersServer with unparsable data ${response.data?.getRecorderServers?.recorders}`
    );
  }

  return response.data.getRecorderServers;
}

export const getQueryRecorderServers = (
  siteCodes: string[],
  siteCodeCacheKey: string
) => {
  return {
    queryKey: [CacheKeys.RecorderServers, siteCodeCacheKey],
    queryFn: async (): Promise<RecorderServersResponse> => {
      if (!siteCodes || siteCodes.length < 1 || !siteCodes[0]) {
        log(
          ' >>> useRecorderServers: empty siteCodes -- returning emptyQueryFunction()'
        );
        return await emptyQueryFunction();
      }

      try {
        log(
          ' >>> useRecorderServers: non-empty siteCodes -- returning apiGetRecorderServers()'
        );
        return await apiGetRecorderServers({ siteCodes });
      } catch (err) {
        throw new Error(
          `Unabled to retrieve hardwares for recorder server: ${siteCodes}`
        );
      }
    },
  };
};

export interface UseRecorderServersResponse {
  error: Error | null;
  isError: boolean;
  isLoading: boolean;
  recordersResponse: RecorderServersResponse | undefined;
  refetchRecorderServers: () => void;
  removeRecorderServersCache: () => void;
}

export const useRecorderServers = (
  siteCodes: string[]
): UseRecorderServersResponse => {
  log('*** useRecorderServers(): siteCodes=', false, siteCodes);
  const queryClient = useQueryClient();
  const siteCodesCacheKey = getArrayCacheKey(siteCodes);
  const {
    isLoading: isFirstLoading,
    isFetching,
    isRefetching,
    data: recordersResponse,
    isError,
    error,
  } = useQuery(getQueryRecorderServers(siteCodes, siteCodesCacheKey));

  function refetchRecorderServers() {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.RecorderServers, siteCodesCacheKey],
    });
  }

  function removeRecorderServersCache() {
    queryClient.removeQueries({
      queryKey: [CacheKeys.RecorderServers, siteCodesCacheKey],
    });
  }

  return {
    isLoading: isFirstLoading || isFetching || isRefetching,
    recordersResponse,
    isError,
    error,
    refetchRecorderServers,
    removeRecorderServersCache,
  };
};

const emptyQueryFunction = async (): Promise<RecorderServersResponse> => {
  return new Promise(function (resolve) {
    resolve({
      __typename: 'RecorderServersResponse',
      status: 0,
      recorders: [],
    });
  });
};
