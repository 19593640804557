import {
  Box,
  Button,
  Form,
  FormField,
  Input,
  Modal,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { UNKNOWN } from '@features/milestones/tree/constants';
import { NodeTypes } from '@features/milestones/types/api-types';
import { useAppDispatch, useAppSelector } from '@stores/slices/hooks';
import {
  clearEditingNode,
  selectAllNodeNames,
  selectEditingNode,
} from '@stores/slices/milestones/tree/treeSlice';
import { getNewNameErrorMessages } from '@utils/helpers';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { PopoverContent } from 'src/features/milestones/tree/components/tooltip/InfoPopover';
import { UINode } from 'src/features/milestones/types/ui-types';

export type EditHardwareModalProps = {
  renameCallback: (node: UINode, newName: string) => Promise<void>;
};

const EditHardwareModal = ({
  renameCallback,
}: EditHardwareModalProps): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const node: UINode = useAppSelector(selectEditingNode)!;
  const allNodeNames = useAppSelector(selectAllNodeNames);
  const dispatch = useAppDispatch();
  const [inputName, setInputName] = useState(node?.name || UNKNOWN);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    setValidationErrors(getNewNameErrorMessages(allNodeNames, node, inputName));
  }, [allNodeNames, inputName, node]);

  const handleDismiss = useCallback(() => {
    dispatch(clearEditingNode());
  }, [dispatch]);

  function handleRenameHardware(): void {
    switch (node?.type) {
      case NodeTypes.HardwareCamera:
      case NodeTypes.Recorder:
      case NodeTypes.Encoder:
      case NodeTypes.EncoderCamera:
      case NodeTypes.EncoderMetadata:
        renameCallback(node, inputName);
        handleDismiss();
        break;
      default:
        alert('Not yet implemented renaming for type ' + node?.type);
        break;
    }
  }

  return (
    <Modal
      size="medium"
      data-testid="editingNode"
      header={
        <Box
          color="text-body-secondary"
          display="inline-block"
          fontWeight="normal"
          variant="h2">{`Edit ${node?.type}`}</Box>
      }
      visible={!!node}
      onDismiss={handleDismiss}>
      <SpaceBetween size="l">
        {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <PopoverContent node={node!} edit={true} columns={1} />
        }
        <form onSubmit={e => e.preventDefault()}>
          <Form
            variant="embedded"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  formAction="none"
                  variant="link"
                  onClick={handleDismiss}>
                  Cancel
                </Button>
                <Button
                  onClick={handleRenameHardware}
                  variant="primary"
                  disabled={
                    validationErrors.length > 0 || inputName === UNKNOWN
                  }>
                  Confirm
                </Button>
              </SpaceBetween>
            }>
            <FormField
              label="Device Name"
              errorText={
                validationErrors.length > 0 ? (
                  <ul style={{ listStyleType: 'none' }}>
                    {validationErrors.map((errorMessage, i) => (
                      <li key={i}>{errorMessage}</li>
                    ))}
                  </ul>
                ) : (
                  ''
                )
              }>
              <Input
                type="text"
                name="Hardware Name"
                value={inputName}
                placeholder="New Hardware Name"
                invalid={validationErrors.length > 0}
                onChange={({ detail }): void => {
                  setInputName(detail.value);
                }}
                autoFocus
              />
            </FormField>
          </Form>
        </form>
      </SpaceBetween>
    </Modal>
  );
};

export default EditHardwareModal;
