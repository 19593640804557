import React, { useEffect } from 'react';

import AppLayout, {
  AppLayoutProps,
} from '@amzn/awsui-components-react/polaris/app-layout';

import Notifications from '@features/milestones/notifications/components/Notifications';
import { TestIds } from './constants';
import { elementIds } from './constants';
import { useAppDispatch } from '@stores/slices/hooks';
import { clearAllMessages } from '@stores/slices/milestones/flashBar/flashBarSlice';

interface MilestonesContentProps {
  content: JSX.Element;
  contentType: AppLayoutProps.ContentType;
}

const MilestonesContent = ({
  content,
  contentType,
}: MilestonesContentProps): React.ReactElement => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearAllMessages());
  }, [dispatch]);

  return (
    <AppLayout
      data-testid={TestIds.MILESTONES_SHELL_TEST_ID}
      navigationHide
      toolsHide
      contentType={contentType}
      content={content}
      notifications={<Notifications />}
      headerSelector={`#${elementIds.MILESTONES_TOP_NAVIGATION_ID}`}
    />
  );
};

export default MilestonesContent;
