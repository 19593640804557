import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import {
  MilestoneRenameResponse,
  RenameMetadataMutation,
  RenameMetadataMutationVariables,
} from 'src/API';
import { renameMetadata } from 'src/graphql/mutations';

const apiRenameEncoderMetadata = async (
  variables: RenameMetadataMutationVariables
): Promise<MilestoneRenameResponse> => {
  const response = (await API.graphql(
    graphqlOperation(renameMetadata, variables)
  )) as GraphQLResult<RenameMetadataMutation>;

  const renameEncoderMetadataResponse = response.data?.renameMetadata;
  if (renameEncoderMetadataResponse?.status !== 200) {
    throw new Error(
      `Failed renameMetadata with status: ${renameEncoderMetadataResponse?.status}`
    );
  }

  if (!renameEncoderMetadataResponse.device) {
    throw new Error(
      `Failed renameMetadata with empty data ${renameEncoderMetadataResponse?.device}`
    );
  }
  return renameEncoderMetadataResponse;
};

export const useEncoderMetadataRenaming = () => {
  const {
    mutate: renameEncoderMetadataMutation,
    mutateAsync: renameEncoderMetadataAsync,
    data: response,
    isSuccess,
    isError,
    isPending,
  } = useMutation({
    mutationFn: apiRenameEncoderMetadata,
  });

  return {
    renameEncoderMetadataMutation,
    renameEncoderMetadataAsync,
    response,
    isSuccess,
    isError,
    isPending,
  };
};
