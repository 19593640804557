/* eslint-disable no-console */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores/slices/store';
import { FlashBarMessage } from '@features/milestones/notifications/utils';

type FlashBarState = {
  items: FlashBarMessage[];
};

export const initialFlashBarState: FlashBarState = {
  //items: allMockedNotifications, // allMockedNotifications
  items: [],
};

const flashBarSlice = createSlice({
  name: 'flashBarState',
  initialState: initialFlashBarState,
  reducers: {
    clearAllMessages: state => {
      state.items = [];
    },
    removeFlashBarMessage(state, action: PayloadAction<FlashBarMessage>): void {
      const items = state.items.filter(
        item => item.message !== action.payload.message
      );
      state.items = [...items];
    },
    addFlashBarMessage(state, action: PayloadAction<FlashBarMessage>): void {
      //remove duplication
      const items = state.items.filter(
        item => item.message !== action.payload.message
      );
      state.items = [...items, action.payload];
    },
    setSingleFlashBarMessage(
      state,
      action: PayloadAction<FlashBarMessage>
    ): void {
      state.items = [action.payload];
    },
  },
});

export const flashBarReducer = flashBarSlice.reducer;

export const {
  removeFlashBarMessage,
  addFlashBarMessage,
  clearAllMessages,
  setSingleFlashBarMessage,
} = flashBarSlice.actions;

export { flashBarSlice };

export const selectFlashBarItems = (state: RootState): FlashBarMessage[] => {
  return state.flashBarState.items;
};
