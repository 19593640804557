import { useMutation } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  MilestoneRenameResponse,
  RenameRecorderServerMutation,
  RenameRecorderServerMutationVariables,
} from 'src/API';
import { renameRecorderServer } from 'src/graphql/mutations';

const apiRenameRecorderServers = async (
  variables: RenameRecorderServerMutationVariables
): Promise<MilestoneRenameResponse> => {
  const response = (await API.graphql(
    graphqlOperation(renameRecorderServer, variables)
  )) as GraphQLResult<RenameRecorderServerMutation>;

  const renameRecorderServerResponse = response.data?.renameRecorderServer;
  if (renameRecorderServerResponse?.status !== 200) {
    throw new Error(
      `Failed renameRecorderServer with status: ${renameRecorderServerResponse?.status}`
    );
  }

  if (!renameRecorderServerResponse.device) {
    throw new Error(
      `Failed getRecorderServer with empty data ${renameRecorderServerResponse?.device}`
    );
  }
  return renameRecorderServerResponse;
};

export const useRecorderServerRenaming = () => {
  const {
    mutate: renameRecorderServerMutation,
    mutateAsync: renameRecorderServerAsync,
    data: response,
    isSuccess,
    isError,
    isPending,
  } = useMutation({
    mutationFn: apiRenameRecorderServers,
  });

  return {
    renameRecorderServerMutation,
    renameRecorderServerAsync,
    response,
    isSuccess,
    isError,
    isPending,
  };
};
