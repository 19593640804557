import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { Recorder } from '@features/milestones/types/api-types';
import { renameItemsWith, tableAriaLabels } from '@components/Table';
import {
  createErrorsMessage,
  createInProgressMessage,
} from '@features/milestones/notifications/utils';

export const ERROR_LOADING_RECORDERS = createErrorsMessage(
  'Failed to load recorders!'
);

export const FETCHING_RECORDER_SERVERS = createInProgressMessage(
  'We are loading recorders servers... Please wait!'
);

export const FETCHING_RECORDER_DEVICES = createInProgressMessage(
  'We are loading devices from selected recorders... Please wait!'
);

export const ariaLabels = {
  ...tableAriaLabels,
  REFRESH_ITEMS: renameItemsWith(tableAriaLabels.REFRESH_ITEMS, 'recorders'),
  FILTERING_LABEL: renameItemsWith(
    tableAriaLabels.FILTERING_LABEL,
    'recorders'
  ),
  getItemSelectionLabel: (
    selectedItems: readonly Recorder[],
    itemName: string
  ): string => {
    return tableAriaLabels.getItemSelectionLabel(
      selectedItems,
      itemName,
      'name'
    );
  },
};

export const labels = {
  buttons: {
    VIEW_SELECTED_TREE: 'View selected tree',
  },
  descriptions: {
    EMPTY_DESCRIPTION: '',
    NO_MATCH_DESCRIPTION: 'We can`t find a match.',
    LOADING: 'Loading recorders',
  },
  placeholders: {
    FILTER: 'Find recorders',
  },
  tablePreferences: {
    titles: {
      HEADER: 'Preferences',
      PAGE_SIZE_HEADER: 'Select page size',
      WRAP_LINES_HEADER: 'Wrap lines',
      VISIBLE_COLUMNS_HEADER: 'Select visible columns',
      VISIBLE_COLUMNS_SUBTITLE: 'Recorders columns',
    },
    descriptions: {
      WRAP_LINES_DESCRIPTION: 'Check to see all the text and wrap the lines',
    },
    buttons: {
      CANCEL_BUTTON: 'Cancel',
      CONFIRM_BUTTON: 'Confirm',
    },
    pageSizeLabel: (number: number): string => `${number} recorders`,
  },
  titles: {
    CLEAR_FILTER: 'Clear filter',
    TABLE_TITLE: 'Recorders',
    EMPTY_TITLE: 'No recorders',
    FAILURE_TITLE: 'Failed to get recorders',
    NO_ITEMS_TITLE: 'There are no recorders',
    NO_MATCH_TITLE: 'No matches',
    LOADING_TEXT_TITLE: 'Loading recorders...',
    SHOW_TREE_ITEMS: 'Show Tree Items',
    HEADERER_TITLE: 'Recorders View',
  },
};

export const testIds = {
  TABLE_FILTER: 'recorders-table-filter',
  TABLE: 'recorders-table-main-component',
  PREFERENCES: 'recorders-table-preferences',
  HEADER: 'recorders-table-header',
};
export const DEFAULT_PAGE_SIZE = 50;

export const PAGE_SIZE_OPTIONS: CollectionPreferencesProps.PageSizeOption[] = [
  6, 10, 20, 30, 50,
].map(number => {
  return {
    value: number,
    label: labels.tablePreferences.pageSizeLabel(number),
  };
});
