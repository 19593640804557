/* eslint-disable react/react-in-jsx-scope */
import { SpaceBetween } from '@amzn/awsui-components-react';
import React, { ReactElement } from 'react';
import ExpandCollapseFilter from './filters/ExpandCollapseFilter';
import HardwareModelFilter from './filters/HardwareModelFilter';
import SearchByNameFilter from './filters/SearchByNameFilter';

const TreeTopHeader = (): ReactElement => {
  return (
    <SpaceBetween direction="horizontal" size="s">
      <SearchByNameFilter />
      <ExpandCollapseFilter />
      <HardwareModelFilter />
    </SpaceBetween>
  );
};

export default TreeTopHeader;
