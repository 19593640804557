import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Outlet } from 'react-router';
import MilestonesTopNav from 'src/pages/milestones/layout/MilestonesTopNav';

const MilestonesLayout = (): React.ReactElement => {
  return (
    <ErrorBoundary fallback={<p>⚠️Something went wrong</p>}>
      <MilestonesTopNav />
      <Outlet />
    </ErrorBoundary>
  );
};

export default MilestonesLayout;
