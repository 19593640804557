/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SelectProps } from '@amzn/awsui-components-react';
import { UINode as Node } from '@features/milestones/types/ui-types';
import {
  expandCheckNodesAction,
  expandCollapseAllNodesAction,
  expandCollapseNodeAction,
} from '@stores/slices/milestones/tree/expand.actions';
import { getNameErrorMessages } from '@utils/helpers';
import { checkNodesAction, syncNodesAction } from './check.actions';
import { searchNodesAction } from './search.actions';
import { RootState } from '@stores/slices/store';
import { Entity } from '@features/milestones/types/api-types';
import { filteringNodesAction } from './filter.action';
import {
  optimisticUpdateNameAction,
  updateNodeNameAndIdAction,
} from './rename.actions';

export interface SelectOption {
  label: string;
  value: string;
}

/**
 * When there are more than this number of nodes, we will not allow expanding all tree nodes
 * All other operations are still operating as usual
 * */
export const EXPAND_ALL_LIMIT = 1000;

export const FILTER_ALL = 'All';

export const INITIAL_CAMERA_MODEL_FILTER: SelectOption = {
  label: 'All Camera Models',
  value: FILTER_ALL,
};

export const INITIAL_ENCODER_MODEL_FILTER: SelectOption = {
  label: 'All Encoder Models',
  value: FILTER_ALL,
};

export const INITIAL_HARDWARE_MODEL_FILTER: SelectProps.Option = {
  label: 'All Hardware Models',
  value: FILTER_ALL,
};

export interface TreeSliceInterface {
  allNodeNames: string[];
  checkedNodes: Node[];
  editingNode: Node | undefined;
  errorMessages: string[];
  //node are under editing or renaming
  filterByCameraModel: SelectOption;
  filterByEncoderModel: SelectOption;
  filterByHardwareModel: SelectProps.Option;
  isLoading: boolean;
  isSiteTree: boolean;
  nodes: Node[];
  successMessages: string[];
  totalDisabledCameras: number;
  //no logic to calculate yet
  totalDisabledHardwares: number;
  //no logic to calculate yet
  totalNodes: number;
  totalVisibleNodes: number;
}

export const initialTreeState: TreeSliceInterface = {
  nodes: [], //a filter action, could relocate node from nodes[] to/from hiddenNodes
  checkedNodes: [],
  totalNodes: 0,
  totalVisibleNodes: 0,
  totalDisabledCameras: 0, //no logic to calculate yet
  totalDisabledHardwares: 0, //no logic to calculate yet
  isLoading: false,
  isSiteTree: false,
  filterByCameraModel: INITIAL_CAMERA_MODEL_FILTER,
  filterByEncoderModel: INITIAL_ENCODER_MODEL_FILTER,
  filterByHardwareModel: INITIAL_HARDWARE_MODEL_FILTER,
  editingNode: undefined,
  errorMessages: [],
  successMessages: [],
  allNodeNames: [],
};

export type Counter = {
  count: number;
  countVisible?: number;
};

//When API call is settled (success/error), update both newId and newName
export type SettledRenamePayload = {
  id: string;
  // existing id
  newId: string;
  // when response is success, we will have a new id
  newName: string;
  renamingError?: string;
};

//When we start mutating, optimistically update the node name
export type OptimisticRenamePayload = {
  id: string; // existing id
  newName: string;
};

/**
 * Setting all UI states such as isChecked, isExpanded, isHidden, isHighlight to default values
 */
function attachUIStates(
  nodes: Entity[],
  counter: Counter,
  allNames: string[],
  stateNodes?: Node[] | undefined
): Node[] {
  return nodes.map(node => {
    const nodeInStore: Node | undefined = stateNodes?.find(
      n => n.id === node.id
    );
    counter.count += 1;
    return {
      ...node,
      isChecked: nodeInStore ? nodeInStore.isChecked : false,
      isExpanded: nodeInStore ? nodeInStore.isExpanded : false,
      isHidden: nodeInStore ? nodeInStore.isHidden : false,
      isHighlight: nodeInStore ? nodeInStore.isHighlight : false,
      isLoading: nodeInStore ? nodeInStore.isLoading : false,
      renamingError: nodeInStore ? nodeInStore.renamingError : undefined,
      isInvalidName: getNameErrorMessages(allNames, node).length > 0,
      children: node.children
        ? attachUIStates(
            node.children,
            counter,
            allNames,
            nodeInStore?.children
          )
        : node.children,
    };
  });
}

function getCount(nodes: Node[], counter: Counter): void {
  return nodes.forEach(node => {
    counter.count += 1;
    if (!node.isHidden && counter.countVisible) {
      counter.countVisible += 1;
    }
    if (node.children && node.children.length > 0) {
      return getCount(node.children, counter);
    }
  });
}

export const treeSlice = createSlice({
  name: 'treeState',
  initialState: initialTreeState,
  reducers: {
    reset: _ => {
      return initialTreeState;
    },
    initData: (
      state,
      action: PayloadAction<{ allNames: string[]; rootNode: Entity[] }>
    ) => {
      const counter = { count: 0 };
      const nodes = attachUIStates(
        action.payload.rootNode,
        counter,
        action.payload.allNames,
        state.nodes
      );
      const isSiteTree = nodes[0]?.type === 'Site';
      const newState = {
        ...initialTreeState,
        successMessages: state.successMessages,
        errorMessages: state.errorMessages,
        nodes,
        totalNodes: counter.count,
        totalVisibleNodes: counter.count,
        isSiteTree,
      };
      syncNodesAction(newState);
      return newState;
    },
    setFilterByCameraModel: (state, action: PayloadAction<SelectOption>) => {
      state.filterByCameraModel = action.payload;
    },
    setFilterByEncoderModel: (state, action: PayloadAction<SelectOption>) => {
      state.filterByEncoderModel = action.payload;
    },
    setFilterByHardwareModel: (state, action: PayloadAction<SelectOption>) => {
      state.filterByHardwareModel = action.payload;
    },
    setTreeLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    filteringNodes: state => {
      const counter = { count: 0 };
      state.isLoading = true;
      filteringNodesAction(state, counter);
      syncNodesAction(state);
      state.totalVisibleNodes = counter.count;
      state.isLoading = false;
    },
    toggleNode: (
      state,
      action: PayloadAction<{ id: string; isExpanded: boolean }>
    ) => {
      expandCollapseNodeAction(
        state.nodes,
        action.payload.id,
        action.payload.isExpanded
      );
    },
    checkNodes: (
      state,
      action: PayloadAction<{ id: string; isChecked: boolean }>
    ) => {
      checkNodesAction(
        state.nodes,
        action.payload.isChecked,
        action.payload.id
      );
      syncNodesAction(state);
    },
    expandCollapseAll: (state, action: PayloadAction<boolean>) => {
      const isExpanding = action.payload;
      expandCollapseAllNodesAction(state.nodes, isExpanding);
    },
    expandCheckedNodes: state => {
      expandCheckNodesAction(state.nodes);
    },
    searchNodes: (state, action: PayloadAction<{ query: string }>) => {
      state.isLoading = true;
      expandCollapseAllNodesAction(state.nodes, false);
      searchNodesAction(state.nodes, action.payload.query);
      state.isLoading = false;
    },
    setEditingNode(state, action: PayloadAction<Node>) {
      state.editingNode = action.payload;
    },
    clearEditingNode(state) {
      state.editingNode = initialTreeState.editingNode;
    },
    optimisticUpdateName(
      state,
      action: PayloadAction<OptimisticRenamePayload>
    ) {
      const found = { isRenamed: false };
      optimisticUpdateNameAction(state.nodes, action.payload, found);
      if (found.isRenamed) syncNodesAction(state);
    },
    updateNodeNameAndId(state, action: PayloadAction<SettledRenamePayload>) {
      const found = { isRenamed: false };
      updateNodeNameAndIdAction(state.nodes, action.payload, found);
      if (found.isRenamed) syncNodesAction(state);
    },
    addErrorMessage(state, action: PayloadAction<string>) {
      //only add this message, if it is not already in the list
      const errors = state.errorMessages.filter(
        message => message !== action.payload
      );
      state.errorMessages = [...errors, action.payload];
    },
    removeErrorMessage(state, action: PayloadAction<string>) {
      const errors = state.errorMessages.filter(
        message => message !== action.payload
      );
      state.errorMessages = [...errors];
    },
    addSuccessMessage(state, action: PayloadAction<string>) {
      //only add this message, if it is not already in the list
      const successes = state.successMessages.filter(
        message => message !== action.payload
      );
      state.successMessages = [...successes, action.payload];
    },
    removeSuccessMessage(state, action: PayloadAction<string>) {
      const successes = state.successMessages.filter(
        message => message !== action.payload
      );
      state.successMessages = [...successes];
    },
    clearAllTreeMessages(state) {
      state.errorMessages = [];
      state.successMessages = [];
    },
    scrollToNext: (_, __: PayloadAction<{ currentId: string }>) => {
      //@TODO, scroll to next searched node
    },
    updateNodesWithRemovedSiteCodes: (
      state,
      action: PayloadAction<string[]>
    ) => {
      const updatedNodes = state.nodes.filter(
        node => !action.payload.includes(node.siteCode!)
      );
      const counter: Counter = {
        count: 0,
        countVisible: 0,
      };

      getCount(updatedNodes, counter);

      return {
        ...state,
        nodes: updatedNodes,
        totalNodes: counter.count,
        totalVisibleNodes: counter.countVisible!,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  reset,
  initData,
  toggleNode,
  checkNodes,
  expandCollapseAll,
  expandCheckedNodes,
  searchNodes,
  scrollToNext,
  filteringNodes,
  setFilterByCameraModel,
  setFilterByEncoderModel,
  setFilterByHardwareModel,
  setTreeLoading,
  setEditingNode,
  clearEditingNode,
  updateNodeNameAndId,
  optimisticUpdateName,
  addErrorMessage,
  removeErrorMessage,
  updateNodesWithRemovedSiteCodes,
  addSuccessMessage,
  removeSuccessMessage,
  clearAllTreeMessages,
} = treeSlice.actions;

export const selectNodes = (state: RootState): Node[] => state.treeState.nodes;
export const selectTotalNodes = (state: RootState): number =>
  state.treeState.totalNodes;
export const selectTotalVisibleNodes = (state: RootState): number =>
  state.treeState.totalVisibleNodes;
export const selectCheckNodes = (state: RootState): Node[] =>
  state.treeState.checkedNodes;
export const selectFilterByCameraModel = (state: RootState): SelectOption =>
  state.treeState.filterByCameraModel;
export const selectFilterByEncoderModel = (state: RootState): SelectOption =>
  state.treeState.filterByEncoderModel;
export const selectFilterByHardwareModel = (
  state: RootState
): SelectProps.Option => state.treeState.filterByHardwareModel;
export const selectIsLoading = (state: RootState): boolean =>
  state.treeState.isLoading;
export const selectIsSiteTree = (state: RootState): boolean =>
  state.treeState.isSiteTree;
export const selectEditingNode = (state: RootState): Node | undefined =>
  state.treeState.editingNode;
export const selectErrorMessages = (state: RootState): string[] =>
  state.treeState.errorMessages;
export const selectSuccessMessages = (state: RootState): string[] =>
  state.treeState.successMessages;
export const selectAllNodeNames = (state: RootState): string[] =>
  state.treeState.allNodeNames;
export default treeSlice.reducer;
