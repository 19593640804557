import React from 'react';
import { Box, Button, SpaceBetween } from '@amzn/awsui-components-react';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import {
  addTextToSelected,
  removeTextFromSelected,
} from 'src/features/isc/actions/thunks';
import { ExportCsvButton } from 'src/features/isc/ExportCsvButton';
import { toggleShowFileUpload } from 'src/stores/slices/userSlice';
import { TreeDevice } from 'src/types';

const ucRegx = /uc_.*/i;
const dcmsnRegx = /dcmsn_.*/i;

function getSubChildren(
  devices: TreeDevice[],
  parentId: number,
  childId: number
): TreeDevice[] {
  return devices.filter(device => {
    return (
      device.parent_device_id == parentId && device.child_device_id == childId
    );
  });
}

function getChildren(devices: TreeDevice[], parentId: number): TreeDevice[] {
  return devices.filter(device => {
    return (
      device.parent_device_id == parentId && device.subchild_device_id == 0
    );
  });
}

export function DeviceMenu(): React.ReactElement {
  const dispatch = useAppDispatch();
  const parentDevices = useAppSelector(
    state => state.deviceState.parentDevices
  );
  const childDevices = useAppSelector(state => state.deviceState.childDevices);
  const subchildDevices = useAppSelector(
    state => state.deviceState.subchildDevices
  );

  function addUcDcmsnDisabled(): boolean {
    return ![childDevices, parentDevices, subchildDevices]
      .flat()
      .some(device => device.checked);
  }

  function removeUcDcmsnDisabled(regxMatch: RegExp): boolean {
    return ![childDevices, parentDevices, subchildDevices]
      .flat()
      .some(device => device.checked && device.device_name.match(regxMatch));
  }

  return (
    <Box textAlign={'center'}>
      <SpaceBetween size={'s'} direction={'vertical'}>
        <Button
          className={'ninetyWidth'}
          disabled={addUcDcmsnDisabled()}
          onClick={(): void => {
            dispatch(addTextToSelected({ toAdd: 'uc_' }));
          }}
          iconName={'add-plus'}>
          Add uc_
        </Button>
        <Button
          className={'ninetyWidth'}
          disabled={removeUcDcmsnDisabled(ucRegx)}
          onClick={(): void => {
            dispatch(removeTextFromSelected({ toRemove: 'uc_' }));
          }}
          iconName={'close'}>
          Remove uc_
        </Button>
        <Button
          className={'ninetyWidth'}
          disabled={addUcDcmsnDisabled()}
          onClick={(): void => {
            dispatch(addTextToSelected({ toAdd: 'dcmsn_' }));
          }}
          iconName={'add-plus'}>
          Add dcmsn_
        </Button>
        <Button
          className={'ninetyWidth'}
          disabled={removeUcDcmsnDisabled(dcmsnRegx)}
          onClick={(): void => {
            dispatch(removeTextFromSelected({ toRemove: 'dcmsn_' }));
          }}
          iconName={'close'}>
          Remove dcmsn_
        </Button>

        <Button
          className={'ninetyWidth'}
          onClick={(): void => {
            dispatch(toggleShowFileUpload());
          }}
          iconName={'upload'}>
          Upload CSV
        </Button>
        <ExportCsvButton />
      </SpaceBetween>
    </Box>
  );
}
