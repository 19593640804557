import { Entity } from '@features/milestones/types/api-types';
import { duplicateName } from '@utils/helpers';
import {
  getDuplicateNameErrorMessage,
  getInvalidNameErrorMessage,
  getNameLengthErrorMessage,
} from 'src/utils/validations/constants/ErrorMessageConstant';

const encoderMetadataDeviceType = 'Encoder metadata';

export const getEncoderMetadataNameFieldErrors = (
  allNodeNames: string[],
  node: Entity,
  newName: string
): string[] => {
  const errorMessages: string[] = [];
  const metadataChannelNumber = node.name.substring(
    node.name.indexOf(' - Metadata '),
    node.name.length
  );
  if (node.name !== newName && duplicateName(allNodeNames, newName)) {
    errorMessages.push(getDuplicateNameErrorMessage(encoderMetadataDeviceType));
  }
  if (newName.length > 96) {
    errorMessages.push(getNameLengthErrorMessage(encoderMetadataDeviceType));
  }
  const siteCode: string | undefined = node?.siteCode;
  const regex = new RegExp(
    `^(?:UC_|DCMSN_|uc_|dcmsn_)?${siteCode}-(INT|EXT)[1-6](.*)${metadataChannelNumber}$`
  );
  if (!regex.test(newName)) {
    errorMessages.push(
      getInvalidNameErrorMessage(
        encoderMetadataDeviceType,
        `[uc_|dcmsn_]{SITECODE}-(INT or EXT)[1-6](.*)${metadataChannelNumber}`
      )
    );
  }
  return errorMessages;
};
