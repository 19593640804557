import * as React from 'react';

interface IRawHTMLRendererProps {
  html: string;
}
export default class RawHTMLRenderer extends React.Component<
  IRawHTMLRendererProps,
  unknown
> {
  public container: HTMLElement | null = null;
  public shadowRoot: ShadowRoot | null = null;

  public componentDidMount(): void {
    this.updateContainerContent();
  }

  public componentDidUpdate(): void {
    this.updateContainerContent();
  }

  public render(): JSX.Element {
    return (
      <div
        className="rawHtmlRenderer"
        ref={(node): void => {
          if (node) this.container = node;
        }}
      />
    );
  }

  private updateContainerContent(): void {
    if (!this.container) {
      return;
    }

    if (!this.props.html) {
      return;
    }

    if (this.shadowRoot === null) {
      this.shadowRoot = this.container.attachShadow({ mode: 'open' });
    }

    this.shadowRoot.innerHTML = this.props.html;
  }
}
