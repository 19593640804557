import { UINode as Node } from 'src/features/milestones/types/ui-types';

export const searchNodesAction = (nodes: Node[], query: string): void => {
  nodes.forEach(node => {
    //if a node is hidden, we will not search it and its children
    if (node.isHidden) {
      node.isHighlight = false;
      return;
    }

    const shouldHighlight = query.length
      ? node.name.toLowerCase().includes(query.toLowerCase())
      : false;

    node.isHighlight = shouldHighlight;

    if (node.children) {
      searchNodesAction(node.children, query);
      if (
        node.children.some(
          (child: Node) => child.isExpanded || child.isHighlight
        )
      ) {
        node.isExpanded = true;
      }
    }
  });
};

export const searchNodesHighlightPath = (
  nodes: Node[],
  query: string
): Node[] => {
  nodes.forEach((node: Node) => {
    const shouldHighlight = query.length
      ? node.name.toLowerCase().includes(query.toLowerCase())
      : false;

    node.isHighlight = shouldHighlight;

    if (node.children) {
      searchNodesHighlightPath(node.children, query);
      if (node.children.some((child: Node) => child.isHighlight)) {
        node.isHighlight = true;
      }
    }
  });

  return nodes;
};
