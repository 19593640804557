import React, { ReactElement, useEffect, useState } from 'react';
import {
  searchNodes,
  selectIsLoading,
} from 'src/stores/slices/milestones/tree/treeSlice';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { Input } from '@amzn/awsui-components-react';

const SearchByNameFilter = (): ReactElement => {
  const isTreeLoading = useAppSelector(selectIsLoading);
  const dispatch = useAppDispatch();

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const search = setTimeout(() => {
      if (searchQuery.length >= 2) {
        dispatch(searchNodes({ query: searchQuery }));
      }
    }, 1000);

    return (): void => clearTimeout(search);
  }, [searchQuery, dispatch]);

  return (
    <Input
      disabled={isTreeLoading}
      onChange={(e): void => setSearchQuery(e.detail.value)}
      value={searchQuery}
      placeholder="Search..."
      type="search"
    />
  );
};

export default SearchByNameFilter;
