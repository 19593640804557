import React from 'react';
import { useAppSelector } from 'src/stores/slices/hooks';
import { envVariables } from 'src/resources/envVariables';
import { selectUsername } from 'src/stores/slices/userSlice';

export function HowManyPixel(): React.ReactElement {
  const username = useAppSelector(selectUsername);
  const stage: string = envVariables.stage;
  let hmPixelUrl = `https://0s62bmu3aj.execute-api.us-east-1.amazonaws.com/PROD/pixel/tracker?PixelID=b33c7e65-fd52-fcf2-f7a0-50f82dccfc06&login=${username}`; //PROD
  switch (stage) {
    case 'test':
    case 'beta':
      hmPixelUrl = `https://0s62bmu3aj.execute-api.us-east-1.amazonaws.com/PROD/pixel/tracker?PixelID=088f4642-10ff-6051-188f-c983aaaa4763&login=${username}`; //BETA
      break;
    case 'prod': // Do nothing, prod is default
    default:
  }
  return (
    <img
      src={hmPixelUrl}
      style={{ display: 'none' }}
      alt="HowMany visitor tracker"
    />
  );
}
