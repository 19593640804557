/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from '@stores/slices/hooks';
import { selectSelectedSiteCodes } from '@stores/slices/userPrefsSlice';
import { CacheKeys, getArrayCacheKey } from 'src/services/cache-keys';
import { useRecorderServers } from 'src/services/useRecorderServers';
import { setGlobalIsLoading } from 'src/stores/slices/userSlice';
import { log } from 'src/utils/helpers';
import { RecorderServersResponse, Site } from 'src/API';
import { Recorder } from '@features/milestones/types/api-types';
import { setRecorders } from '@stores/slices/milestones/milestonesSlice';

export interface UseRecordersResponse {
  error: Error | null;
  isError: boolean;
  isLoading: boolean;
  recorders: Recorder[];
  refetchRecorders: () => void;
}

/**
 * Refetching data
 * Should we sync with Redux states, maybe complex or overkill
 */
export const useRecorders = (): UseRecordersResponse => {
  const queryClient = useQueryClient();
  const selectedSiteCodes = useAppSelector(selectSelectedSiteCodes);
  const allowedSites = useAppSelector(state => state.deviceState.allowedSites);
  const dispatch = useAppDispatch();

  log('*** useRecorders(): selectedSites=', false, selectedSiteCodes);

  //do not pass mutable selectedSiteCodes directly to userRecordersService
  const { isLoading, recordersResponse, isError, error } = useRecorderServers([
    ...selectedSiteCodes,
  ]);

  const transformRecordersResponse = useCallback(
    (recordersResponse: RecorderServersResponse | undefined): Recorder[] => {
      if (
        !recordersResponse ||
        !recordersResponse.recorders ||
        recordersResponse.recorders.length === 0
      ) {
        return [] as Recorder[];
      }

      return responseToRecorderList(recordersResponse, allowedSites);
    },
    [allowedSites]
  );

  //could use queryClient.invalidateQueries({ queryKey: [CacheKeys.RecorderServers] }), but not as safe as belows
  //invalidate cache results when both static key 'RecorderServers' and dynamic key 'AAA1-BBB1' are matched
  function refetchRecorders() {
    queryClient.invalidateQueries({
      predicate: query =>
        query.queryKey[0] === CacheKeys.RecorderServers &&
        query.queryKey[1] === getArrayCacheKey(selectedSiteCodes),
    });
  }

  //may need to use isLoading from useState, to synchronize
  useEffect(() => {
    dispatch(setGlobalIsLoading(isLoading));
  }, [dispatch, isLoading]);

  const recorders = useMemo(
    () => transformRecordersResponse(recordersResponse),
    [recordersResponse, transformRecordersResponse]
  );

  // Note: this is the only place that dispatches 'setRecorders',
  //       and it was being triggered a LOT!! Thus the useMemo()
  // TODO: But... each 'setSelectedTreeRecorders' makes it trigger
  //       repeatedly.
  useEffect(() => {
    if (recorders && recorders.length) {
      dispatch(setRecorders(recorders));
    }
  }, [dispatch, recorders]);

  return {
    recorders,
    isLoading,
    isError,
    error,
    refetchRecorders,
  };
};

function getRegionFromSiteCode(siteCode: string, allowedSites: Site[]): number {
  const region = allowedSites.find(site => site.SiteCode === siteCode)
    ?.region as number;
  return region;
}

function responseToRecorderList(
  recordersResponse: RecorderServersResponse,
  allowedSites: Site[]
): Recorder[] {
  return recordersResponse.recorders.map(recorderServer => {
    return {
      type: 'Recorder',
      id: recorderServer.id,
      name: recorderServer.name,
      region: String(
        getRegionFromSiteCode(recorderServer.siteCode, allowedSites)
      ),
      siteCode: recorderServer.siteCode,
      location: recorderServer.location,
      totalDevices: recorderServer.deviceCount!,
    };
  });
}
