/* eslint-disable @typescript-eslint/no-empty-function, @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Button,
  Header,
  Icon,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { SearchParameters } from '@features/milestones/hooks/useMilestonesNavigate';
import { RecordersForSiteCode } from '@features/milestones/tree/SingleSiteTree';
import { getSelectedTreeRecorders } from '@stores/slices/milestones/milestonesSlice';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RecorderServersResponse } from 'src/API';
import { useBatchRecorderDevices } from 'src/services/useBatchRecorderDevices';
import { useMultiRecorderDevices } from 'src/services/useMultiRecorderDevices';
import { useRecorderServers } from 'src/services/useRecorderServers';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { log } from 'src/utils/helpers';
import {
  clearAllTreeMessages,
  selectErrorMessages,
  selectIsLoading,
  selectIsSiteTree,
  selectSuccessMessages,
  selectTotalVisibleNodes,
} from '@stores/slices/milestones/tree/treeSlice';
import { AriaLabels, Labels, TestIds } from './constants';
import ActionMenu from './components/menus/ActionMenu';
import { getRecorderIdsBySiteCode } from 'src/utils/helpers';

const TreeLayoutHeader = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isTreeLoading = useAppSelector(selectIsLoading);
  const isSiteTree = useAppSelector(selectIsSiteTree);
  let totalNodes = useAppSelector(selectTotalVisibleNodes);
  const selectedTreeRecorders = useAppSelector(getSelectedTreeRecorders);
  const recorderServerIdsBySiteCode = getRecorderIdsBySiteCode(
    selectedTreeRecorders
  );
  const [searchParams] = useSearchParams();
  const siteCode = searchParams.get(SearchParameters.Site);
  const errorMessages = useAppSelector(selectErrorMessages);
  const successMessages = useAppSelector(selectSuccessMessages);

  log('*** TreeLayoutHeader(): siteCode=', false, siteCode);

  const { refetchMultiRecorderDevices } = useMultiRecorderDevices(
    recorderServerIdsBySiteCode
  );
  const { recordersResponse, refetchRecorderServers } = useRecorderServers([
    siteCode!,
  ]);
  const recordersForSiteCode: RecordersForSiteCode = useMemo(
    () => processRecorderServers(recordersResponse),
    [recordersResponse]
  );
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { refetchBatchRecorderDevices } = useBatchRecorderDevices(
    Object.keys(recordersForSiteCode)
  );

  useEffect(() => {
    if (!siteCode && selectedTreeRecorders.length === 0) {
      navigate('/milestones/recorders');
    }
  }, [siteCode, selectedTreeRecorders, navigate]);

  if (isSiteTree) {
    totalNodes--;
  }

  function refresh(): void {
    if (siteCode) {
      refetchRecorderServers();
      refetchBatchRecorderDevices();
    } else {
      refetchMultiRecorderDevices();
    }
  }

  function clearLogs(): void {
    dispatch(clearAllTreeMessages());
  }

  return (
    <Box data-testid={TestIds.DevicesHeader}>
      <SpaceBetween direction="horizontal" size="s">
        <Header counter={isTreeLoading ? '' : totalNodes.toString()}>
          {Labels.Titles.HeadingTitle}
        </Header>
        <Button onClick={refresh} ariaLabel={AriaLabels.RefreshDevices}>
          <Icon name="refresh" alt={AriaLabels.RefreshDevices} />
        </Button>
        <ActionMenu />
        <Button
          onClick={clearLogs}
          ariaLabel={AriaLabels.ClearLogs}
          disabled={errorMessages.length === 0 && successMessages.length === 0}>
          <Icon name="remove" alt={AriaLabels.ClearLogs} />
          Clear messages
        </Button>
      </SpaceBetween>
    </Box>
  );
};

const processRecorderServers = (
  recordersResponse: RecorderServersResponse | undefined
): RecordersForSiteCode => {
  return (
    recordersResponse?.recorders.reduce(
      (recordersForSiteCode: RecordersForSiteCode, recorder) => {
        recordersForSiteCode[recorder.id] = recorder;
        return recordersForSiteCode;
      },
      {}
    ) || {}
  );
};

export default TreeLayoutHeader;
