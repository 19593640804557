import { SelectProps } from '@amzn/awsui-components-react';
import { FILTER_ALL } from './treeSlice';
/* eslint-disable no-console */
import {
  UINode as Node,
  UIAbstractCamera,
  UIEncoder,
} from '@features/milestones/types/ui-types';
import { Counter, SelectOption, TreeSliceInterface } from './treeSlice';
import { NodeTypes } from 'src/features/milestones/types/api-types';

function isCamera(node: Node): boolean {
  return (
    node.type === NodeTypes.HardwareCamera ||
    node.type === NodeTypes.EncoderCamera ||
    node.type === NodeTypes.EncoderMetadata
  );
}

function isEncoder(node: Node): boolean {
  return node.type === NodeTypes.Encoder;
}

type FilterOptions = {
  filterByCameraModel: SelectOption;
  filterByEncoderModel: SelectOption;
  filterByHardwareModel: SelectProps.Option;
};

//A node is hidden, if it is not matching with cameraModel, encoderModel
//A hidden node will also make its children hidden
function setNodeIsHidden(
  node: Node,
  parentIsHidden: boolean,
  filterOptions: FilterOptions,
  counter: Counter
): void {
  if (parentIsHidden) {
    node.isHidden = true;
    node.children?.forEach(child =>
      setNodeIsHidden(child, node.isHidden, filterOptions, counter)
    );
    return;
  }

  //Site and Recorder are always shown
  if (
    node.type === NodeTypes.Site ||
    node.type === NodeTypes.Recorder ||
    parentIsHidden
  ) {
    node.isHidden = false;
    counter.count += 1;
    node.children?.forEach(child =>
      setNodeIsHidden(child, false, filterOptions, counter)
    );
    return;
  }

  //initially all nodes are shown
  node.isHidden = false;

  const { filterByCameraModel, filterByEncoderModel, filterByHardwareModel } =
    filterOptions;

  if (filterByCameraModel.value !== FILTER_ALL && isCamera(node)) {
    const abstractCameraNode = node as UIAbstractCamera;
    if (abstractCameraNode.cameraModel !== filterByCameraModel.value) {
      node.isHidden = true;
    } else {
      counter.count += 1;
    }
  }

  if (filterByEncoderModel.value !== FILTER_ALL && isEncoder(node)) {
    const encoderNode = node as UIEncoder;
    if (encoderNode.encoderModel !== filterByEncoderModel.value) {
      node.isHidden = true;
    } else {
      counter.count += 1;
    }
  }

  if (filterByHardwareModel.value !== FILTER_ALL) {
    let modelName = '';
    if (isCamera(node)) {
      modelName = (node as UIAbstractCamera).cameraModel;
    } else if (isEncoder(node)) {
      modelName = (node as UIEncoder).encoderModel;
    }

    if (modelName && modelName !== filterByHardwareModel.value) {
      node.isHidden = true;
    } else {
      counter.count += 1;
    }
  }

  node.children?.forEach(child =>
    setNodeIsHidden(child, node.isHidden, filterOptions, counter)
  );
  return;
}

/**
 * Marking nodes as hidden or not recursively based on the filters
 * Need also fixes on other reducer actions:
 * Check, Expand, Hightlight should ignore node when node.isHidden = true
 * If a parent node is hidden, its children should also be tagged with isHidden = true
 */
export function filteringNodesAction(
  state: TreeSliceInterface,
  counter: Counter
): void {
  const { filterByCameraModel, filterByEncoderModel, filterByHardwareModel } =
    state;
  state.nodes.forEach(node => {
    setNodeIsHidden(
      node,
      false,
      {
        filterByCameraModel,
        filterByEncoderModel,
        filterByHardwareModel,
      },
      counter
    );
  });
}
