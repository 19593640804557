/* eslint-disable no-console */
import React, { ReactElement, useMemo } from 'react';
import {
  NonCancelableCustomEvent,
  Select,
  SelectProps,
} from '@amzn/awsui-components-react';
import { NodeTypes } from '@features/milestones/types/api-types';
import { UINode as Node } from '@features/milestones/types/ui-types';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { selectNodes } from '@stores/slices/milestones/tree/treeSlice';
import {
  INITIAL_HARDWARE_MODEL_FILTER,
  selectFilterByHardwareModel,
  selectIsLoading,
  setFilterByHardwareModel,
} from 'src/stores/slices/milestones/tree/treeSlice';

const HardwareModelFilter = (): ReactElement => {
  const dispatch = useAppDispatch();
  const filterByHardwareModel = useAppSelector(selectFilterByHardwareModel);
  const isTreeLoading = useAppSelector(selectIsLoading);
  const nodes = useAppSelector(selectNodes);

  const options: SelectProps.Option[] = useMemo(() => {
    return generateOptions(nodes);
  }, [nodes]);

  return (
    <Select
      disabled={isTreeLoading}
      onChange={(
        event: NonCancelableCustomEvent<SelectProps.ChangeDetail>
      ): void => {
        const { label, value } = event.detail.selectedOption;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        dispatch(setFilterByHardwareModel({ label: label!, value: value! }));
      }}
      options={options}
      selectedAriaLabel="Filter by Hardware Model"
      selectedOption={filterByHardwareModel}
      filteringType="auto"
      expandToViewport
    />
  );
};

const generateOptions = (nodes: Node[]): SelectProps.Option[] => {
  const hardwareLookup: { [model: string]: boolean } = {};
  const options: SelectProps.Option[] = [INITIAL_HARDWARE_MODEL_FILTER];

  const extractOptions = (node: Node): void => {
    if (node.children && node.children.length > 0) {
      return node.children.forEach(child => extractOptions(child));
    }

    if (node.type !== NodeTypes.Recorder && node.type !== NodeTypes.Site) {
      const model = node.cameraModel || node.encoderModel;
      if (model) {
        hardwareLookup[model] = true;
      }
    }
  };

  nodes.forEach(node => extractOptions(node));

  Object.keys(hardwareLookup)
    .sort()
    .forEach(model =>
      options.push({ value: model, label: model } as SelectProps.Option)
    );

  return options;
};

export default HardwareModelFilter;
