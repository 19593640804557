import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SelectProps } from '@amzn/awsui-components-react';
import { RootState } from './store';

export interface IUserPreferences {
  darkMode: boolean;
  selectedSiteOptions: any;
  selectedSites: string[];

  tutorialCompleted: boolean;

  uiDensity: boolean;
  visualRefresh: boolean;
}

const initialState: IUserPreferences = {
  darkMode: false,
  uiDensity: true,
  visualRefresh: true,

  tutorialCompleted: false,

  selectedSites: [],
  selectedSiteOptions: [],
};

export const userPrefsState = createSlice({
  name: 'userPrefsState',
  initialState: initialState,
  reducers: {
    toggleDarkMode: state => {
      state.darkMode = !state.darkMode;
    },
    toggleVisualRefresh: state => {
      state.visualRefresh = !state.visualRefresh;
    },

    toggleUiDensity: state => {
      state.uiDensity = !state.uiDensity;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    setTutorialCompleted: (state, action: PayloadAction<boolean>) => {
      state.tutorialCompleted = action.payload;
    },
    setSelectedSites: (state, action: PayloadAction<string[]>) => {
      // ensure unique array here...
      state.selectedSites = [...new Set<string>(action.payload)];
      state.selectedSiteOptions = state.selectedSites.map(s => {
        return {
          value: s,
          label: s,
        } as SelectProps.Option;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleDarkMode,
  toggleUiDensity,
  toggleVisualRefresh,
  setDarkMode,
  setTutorialCompleted,
  setSelectedSites,
} = userPrefsState.actions;

export const selectDarkMode = (state: RootState): boolean =>
  state.userPrefsState?.darkMode;

export const selectSelectedSiteCodes = (state: RootState): string[] =>
  state.userPrefsState?.selectedSites;

export const selectTutorialCompleted = (state: RootState): boolean =>
  state.userPrefsState?.tutorialCompleted;

export const selectUiDensity = (state: RootState): boolean =>
  state.userPrefsState?.uiDensity;

export const selectVisualRefresh = (state: RootState): boolean =>
  state.userPrefsState?.visualRefresh;

export default userPrefsState.reducer;
