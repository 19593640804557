/* eslint-disable no-console */
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { router } from 'src/routes/Routes';

function App(): React.ReactElement {
  /**
   * https://tanstack.com/query/latest/docs/framework/react/quick-start
   * https://tanstack.com/query/latest/docs/framework/react/guides/window-focus-refetching
   */

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60000, // 60,000 milliseconds = 1 minute
        // staleTime: 0, //always refresh, we will eventually use cache and invalidate cache properly
        // Appsync will always return 200, retry will only work on network interuption
        // if custom retry is needed, try queryClient = useQueryClient, and specify retry: function(failureCount, error)
        retry: 1,
        retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000), //expornentially retrying
        refetchOnWindowFocus: true, //when use navigate away and comeback, we will refetch
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
