import React from 'react';
import { Icon, IconProps } from '@amzn/awsui-components-react';
/*
 * https://heroicons.com/
 * All icons must be wrapped inside AWS Cloudscape component for proper styling
 * We may utilize variant to mark a node as under construction or errors
 */
const CameraIcon = ({ variant }: IconProps) => {
  return (
    <Icon
      variant={variant || 'link'}
      svg={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
          />
        </svg>
      }
    />
  );
};

export default CameraIcon;
