import {
  renameItemsWith,
  tableAriaLabels,
  tableLabels,
} from '@components/Table';
import { Site } from '@features/milestones/types/api-types';
import {
  createErrorsMessage,
  createInProgressMessage,
} from '@features/milestones/notifications/utils';

export const ERROR_LOADING_SITES = createErrorsMessage('Failed to load sites!');

export const FETCHING_SITES = createInProgressMessage(
  'We are loading sites... Please wait!'
);

export const renameItemsWithSites = (label: string) =>
  renameItemsWith(label, 'sites');

export const ariaLabels = {
  ...tableAriaLabels,
  REFRESH_ITEMS: renameItemsWithSites(tableAriaLabels.REFRESH_ITEMS),
  FILTERING_LABEL: renameItemsWithSites(tableAriaLabels.FILTERING_LABEL),
  getItemSelectionLabel: (
    selectedItems: readonly Site[],
    itemName: string
  ): string => {
    return tableAriaLabels.getItemSelectionLabel(
      selectedItems,
      itemName,
      'name'
    );
  },
};

export const labels = {
  buttons: {
    VIEW_SELECTED_TREE: 'View selected tree',
  },
  titles: {
    EMPTY_TITLE: 'No sites',
    LOADING_TEXT: renameItemsWithSites(tableLabels.titles.LOADING_TEXT),
    HEADERER_TITLE: 'Sites View',
  },
  placeholders: {
    FILTER: renameItemsWithSites(tableLabels.placeholders.FILTER),
  },
  tablePreferences: {
    titles: {
      HEADER: 'Preferences',
      PAGE_SIZE_HEADER: 'Select page size',
      WRAP_LINES_HEADER: 'Wrap lines',
      VISIBLE_COLUMNS_HEADER: 'Select visible columns',
      VISIBLE_COLUMNS_SUBTITLE: 'Sites columns',
    },
    descriptions: {
      WRAP_LINES_DESCRIPTION: 'Check to see all the text and wrap the lines',
    },
    buttons: {
      CANCEL_BUTTON: 'Cancel',
      CONFIRM_BUTTON: 'Confirm',
    },
    pageSizeLabel: (number: number): string => `${number} sites`,
  },
};

export const testIds = {
  TABLE_FILTER: 'sites-table-filter',
  TABLE: 'sites-table-main-component',
  PREFERENCES: 'sites-table-preferences',
  HEADER: 'sites-table-header',
};

export const DEFAULT_PAGE_SIZE = 50;
