import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FlashbarMessage } from 'src/types';
import { log } from 'src/utils/helpers';

export interface IUserSlice {
  authToken: string;
  authenticatedTimestamp: number;
  globalIsLoading: boolean;
  showDeviceLinkFileUpload: boolean;
  showFileUpload: boolean;
  showMilestonesCsvUpload: boolean;

  showSelectSite: boolean;
  showUserPreferences: boolean;
  tzOffset: number;
  userAuthenticated: boolean;
  userNotifications: FlashbarMessage[];
  username: string;
  visualRefreshTheme: boolean;
}

export const initalUserState: IUserSlice = {
  userAuthenticated: false,
  username: '',
  authenticatedTimestamp: 0,
  tzOffset: new Date().getTimezoneOffset(),
  visualRefreshTheme: false,
  showUserPreferences: false,
  showSelectSite: true,
  showFileUpload: false,
  showMilestonesCsvUpload: false,
  showDeviceLinkFileUpload: false,
  authToken: '',
  userNotifications: [],
  globalIsLoading: false,
};

export const userSlice = createSlice({
  name: 'userState',
  initialState: initalUserState,
  reducers: {
    userDidAuthenticate: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userAuthenticated = true;
    },
    setShowFileUpload: (state, action: PayloadAction<boolean>) => {
      state.showFileUpload = action.payload;
    },
    toggleShowFileUpload: state => {
      state.showFileUpload = !state.showFileUpload;
    },
    toggleShowMilestonesCsvUpload: state => {
      state.showMilestonesCsvUpload = !state.showMilestonesCsvUpload;
    },
    setShowDeviceLinkFileUpload: (state, action: PayloadAction<boolean>) => {
      state.showDeviceLinkFileUpload = action.payload;
    },
    toggleShowDeviceLinkFileUpload: state => {
      state.showDeviceLinkFileUpload = !state.showDeviceLinkFileUpload;
    },
    userDidLogoff: state => {
      state.userAuthenticated = false;
      state.username = '';
    },
    toggleUserPreferences: state => {
      state.showUserPreferences = !state.showUserPreferences;
    },
    toggleSelectSite: state => {
      state.showSelectSite = !state.showSelectSite;
    },
    setUsername: (state, action) => {
      log(`setUsername: ${action.payload}`);
      state.username = action.payload.toString();
      state.userAuthenticated = true;
    },
    setAuthToken: (state, action) => {
      // log(`set federate token: `, false, action.payload);
      state.authToken = action.payload.toString();
    },
    addUserNotification: (state, action: PayloadAction<FlashbarMessage[]>) => {
      action.payload.forEach(message => {
        state.userNotifications.push(message);
      });
    },
    setUserNotifications: (state, action: PayloadAction<FlashbarMessage[]>) => {
      log('Setting new notifications as ');
      log(action.payload);
      state.userNotifications = action.payload;
    },
    userNotificationCountdown: state => {
      if (state.showSelectSite || state.showUserPreferences) {
        return;
      } // Don't count down if there is a window open
      state.userNotifications = state.userNotifications
        .filter(n => n.displayTimeout > 1)
        .map(n => {
          return {
            displayTimeout: n.displayTimeout - 1,
            type: n.type,
            content: n.content,
          };
        });
    },
    removeUserNotification: (state, action: PayloadAction<number>) => {
      //Payload is index to delete
      log(`Removing notification ${action.payload}`);
      state.userNotifications = state.userNotifications.splice(
        action.payload - 1,
        1
      );
      const notifications: FlashbarMessage[] = [];

      state.userNotifications.forEach((n, index) => {
        if (index != action.payload) {
          notifications.push(n);
        }
      });
      state.userNotifications = notifications;
    },
    setGlobalIsLoading: (state, action: PayloadAction<boolean>) => {
      log(`setGlobalIsLoading: ${action.payload}`);
      state.globalIsLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  userDidAuthenticate,
  userDidLogoff,
  setUsername,
  toggleUserPreferences,
  toggleSelectSite,
  setAuthToken,
  addUserNotification,
  removeUserNotification,
  setUserNotifications,
  userNotificationCountdown,
  toggleShowFileUpload,
  toggleShowMilestonesCsvUpload,
  toggleShowDeviceLinkFileUpload,
  setShowDeviceLinkFileUpload,
  setShowFileUpload,
  setGlobalIsLoading,
} = userSlice.actions;

// cannot import RootState from './store' because it
// is derived after importing code from this file...
type RootState = {
  userState: IUserSlice;
};

export const selectAuthToken = (state: RootState): string =>
  state.userState.authToken;

export const selectGlobalIsLoading = (state: RootState): boolean =>
  state.userState.globalIsLoading;

export const selectShowDeviceLinkFileUpload = (state: RootState): boolean =>
  state.userState.showDeviceLinkFileUpload;

export const selectShowFileUpload = (state: RootState): boolean =>
  state.userState.showFileUpload;

export const selectMilestonesCsvUpload = (state: RootState): boolean =>
  state.userState.showMilestonesCsvUpload;

export const selectShowSelectSite = (state: RootState): boolean =>
  state.userState.showSelectSite;

export const selectShowUserPreferences = (state: RootState): boolean =>
  state.userState.showUserPreferences;

export const selectUserNotifications = (state: RootState): FlashbarMessage[] =>
  state.userState.userNotifications;

export const selectUsername = (state: RootState): string =>
  state.userState.username;

export default userSlice.reducer;
