import React, { ReactElement } from 'react';
import {
  ButtonDropdown,
  ButtonDropdownProps,
} from '@amzn/awsui-components-react';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import {
  EXPAND_ALL_LIMIT,
  expandCheckedNodes,
  expandCollapseAll,
  selectIsLoading,
  selectTotalNodes,
} from 'src/stores/slices/milestones/tree/treeSlice';

enum FilterTypes {
  COLLAPSE_ALL = 'collapse-all',
  EXPAND_ALL = 'expand-all',
  EXPAND_SELECTED = 'expand-selected',
}

const ExpandCollapseFilter = (): ReactElement => {
  const isTreeLoading = useAppSelector(selectIsLoading);
  const dispatch = useAppDispatch();
  const totalNodes = useAppSelector(selectTotalNodes);

  const handleExpandCollapseFilterChange = (
    e: CustomEvent<ButtonDropdownProps.ItemClickDetails>
  ): void => {
    const filter = e.detail.id;
    switch (filter) {
      case FilterTypes.EXPAND_SELECTED:
        dispatch(expandCheckedNodes());
        break;
      case FilterTypes.EXPAND_ALL:
        dispatch(expandCollapseAll(true));
        break;
      case FilterTypes.COLLAPSE_ALL:
        dispatch(expandCollapseAll(false));
        break;
      default:
        throw new Error('Invalid filter type!');
    }
  };

  return (
    <ButtonDropdown
      disabled={isTreeLoading}
      items={[
        { text: 'Show Selected', id: FilterTypes.EXPAND_SELECTED },
        {
          text: 'Expand All',
          id: FilterTypes.EXPAND_ALL,
          disabled: totalNodes > EXPAND_ALL_LIMIT,
        },
        { text: 'Hide All', id: FilterTypes.COLLAPSE_ALL },
      ]}
      onItemClick={handleExpandCollapseFilterChange}>
      Expand/Collapse
    </ButtonDropdown>
  );
};

export default ExpandCollapseFilter;
