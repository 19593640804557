import * as React from 'react';
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation/1.0-beta';
import {
  selectGlobalIsLoading,
  selectUsername,
  toggleSelectSite,
  toggleShowDeviceLinkFileUpload,
  toggleUserPreferences,
} from 'src/stores/slices/userSlice';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { CONSTANTS } from 'src/resources/constants';
import { toggleDevMode } from 'src/stores/slices/isc/devSlice';
import whiteLogo from 'src/assets/logo_white.png';
import { TopNavigationProps } from '@amzn/awsui-components-react';
import { AppPaths } from 'src/routes/Routes';
import { useNavigate } from 'react-router';

const IscTopNav = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);
  const navigate = useNavigate();
  const globalIsLoading = useAppSelector(selectGlobalIsLoading);

  const i18nStrings = {
    searchIconAriaLabel: 'Search',
    searchDismissIconAriaLabel: 'Close search',
    overflowMenuTriggerText: 'More',
  };

  const title = 'ISC Device Rename';

  const utilities = globalIsLoading
    ? ([] as TopNavigationProps.Utility[])
    : ([
        {
          type: 'button',
          text: 'Select Site(s)',
          iconName: 'edit',
          ariaLabel: 'Select Sites',
          href: '#/SelectSite',
          badge: false,
          disableUtilityCollapse: true,
          onClick: (): void => {
            dispatch(toggleSelectSite());
          },
        },
        {
          type: 'button',
          text: 'Link Device(s)',
          iconName: 'upload',
          ariaLabel: 'Link Devices',
          badge: false,
          disableUtilityCollapse: true,
          onClick: (): void => {
            dispatch(toggleShowDeviceLinkFileUpload());
          },
        },
        {
          type: 'button',
          text: 'User Preferences',
          iconName: 'settings',
          ariaLabel: 'User Preferences',
          href: '#/SelectSite',
          badge: false,
          disableUtilityCollapse: true,
          onClick: (): void => {
            dispatch(toggleUserPreferences());
          },
        },
        {
          type: 'menu-dropdown',
          text: 'Help',
          items: [
            {
              id: 'bug',
              text: 'Report a Bug',
              href: CONSTANTS.REPORT_BUG,
              external: true,
              externalIconAriaLabel: ' (opens in new tab)',
            },
            {
              id: 'feature',
              text: 'Feature Requests',
              href: CONSTANTS.FEATURE_REQUEST,
              external: true,
              externalIconAriaLabel: ' (opens in new tab)',
            },
            {
              id: 'guide',
              text: 'End User Guide',
              href: CONSTANTS.WIKI_LINK,
              external: true,
              externalIconAriaLabel: ' (opens in new tab)',
            },
          ],
        },
        {
          type: 'button',
          text: username,
          iconName: 'user-profile',
          onClick: (): void => {
            dispatch(toggleDevMode());
          },
        },
        {
          type: 'button',
          disableTextCollapse: true,
          text: 'Switch to Milestones',
          iconName: 'upload',
          ariaLabel: 'Switch to Milestones',
          onClick: () => navigate(AppPaths.Milestones),
        },
      ] as TopNavigationProps.Utility[]);

  return (
    <div
      className="awsui"
      id="header"
      style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
      <TopNavigation
        id={'headerTopNav'}
        i18nStrings={i18nStrings}
        identity={{
          href: '#',
          title: title,
          logo: {
            src: whiteLogo,
            alt: 'Amazon Corporate Security',
          },
        }}
        utilities={utilities}
      />
    </div>
  );
};

export default IscTopNav;
