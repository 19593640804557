import React, { useEffect, useState } from 'react';
import { Flashbar } from '@amzn/awsui-components-react/polaris';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import {
  removeUserNotification,
  selectShowSelectSite,
  selectShowUserPreferences,
  selectUserNotifications,
  userNotificationCountdown,
} from 'src/stores/slices/userSlice';
import IsItDown from 'src/components/IsItDown';

export function TopNotification(): React.ReactElement {
  const dispatch = useAppDispatch();
  const [timerRunning, setTimerRunning] = useState(false);
  const userNotifications = useAppSelector(selectUserNotifications);
  const showSelectSite = useAppSelector(selectShowSelectSite);
  const showUserPreferences = useAppSelector(selectShowUserPreferences);

  useEffect(() => {
    //log(`userNotifications changed ${userNotifications.length} ${timerRunning}`);

    function flashbarCountdown(): void {
      dispatch(userNotificationCountdown());
    }

    if (userNotifications.length) {
      setTimerRunning(true);
      setTimeout(flashbarCountdown, 1000);
    }
    if (userNotifications.length <= 0) {
      setTimerRunning(false);
    }
    return (): void => {
      userNotifications;
    };
  }, [userNotifications, showSelectSite, showUserPreferences, dispatch]);

  return (
    <>
      <IsItDown />
      <Flashbar
        items={userNotifications.map((notification, index) => {
          return {
            type: notification.type,
            content: `${notification.content} ...(${notification.displayTimeout}s)`,
            dismissible: true,
            header: 'Broadcast Message',
            dismissLabel: 'Dismiss',
            onDismiss: () => dispatch(removeUserNotification(index)),
          } as FlashbarProps.MessageDefinition;
        })}
      />
    </>
  );
}

export default TopNotification;
