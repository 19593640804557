import React, { ReactElement, useEffect } from 'react';
import { Box } from '@amzn/awsui-components-react';
import {
  RenameProps,
  useRenameMilestonesNode,
} from '@features/milestones/hooks/useRenameMilestonesNode';
import { useAppDispatch, useAppSelector } from '@stores/slices/hooks';
import {
  filteringNodes,
  selectCheckNodes,
  selectEditingNode,
  selectFilterByCameraModel,
  selectFilterByEncoderModel,
  selectFilterByHardwareModel,
  selectNodes,
} from '@stores/slices/milestones/tree/treeSlice';
import EditHardwareModal from './edit/EditHardwareModal';
import UploadCsvModal from './upload/UploadCsvModal';
import TreeTable from './TreeTable';
import TreeTopHeader from './TreeTopHeader';

/**
 * @TODO Handle rendering tree based on proritized URL param or Redux states for selectedTreeRecorders and selectedTreeSites
 * View tree from a single Site (/tree?site=AAA1)
 * View tree from multiple Recorders (not based on URL, but based on selectedTreeRecorders states)
 * Note: We will not use Redux selectedTreeSites or selectedTreeRecorders when users directly
 * click on 'View Tree' for single site, or single Recorder, because it will leave the checkboxes
 * checked unintutively, which is not user friendly.
 */
const SearchTree = (renameProps: RenameProps): ReactElement => {
  const nodes = useAppSelector(selectNodes) || [];
  const filterByCameraModel = useAppSelector(selectFilterByCameraModel);
  const filterByEncoderModel = useAppSelector(selectFilterByEncoderModel);
  const filterByHardwareModel = useAppSelector(selectFilterByHardwareModel);
  const checkedNodes = useAppSelector(selectCheckNodes) || [];
  const editingNode = useAppSelector(selectEditingNode);
  const { renameCallback, renameStatusMap, setRenameStatusForNode } =
    useRenameMilestonesNode(renameProps);
  const dispatch = useAppDispatch();

  useEffect(() => {
    //add this artificial delay to trigger show Spinner, which would force re-rendering the full tree
    //where React engine stucking on recursively updating tree, when all nodes are expanded
    //only need this, when the number of nodes are higher than few thousands
    //@TODO: alternatively useDebounce()
    setTimeout(() => {
      dispatch(filteringNodes());
    }, 500);
  }, [
    filterByCameraModel.value,
    filterByEncoderModel.value,
    filterByHardwareModel.value,
    dispatch,
  ]);

  return (
    <Box>
      <TreeTopHeader />
      <Box margin={{ top: 'm', bottom: 'm' }}>
        <TreeTable data={nodes} />
        {editingNode && <EditHardwareModal renameCallback={renameCallback} />}
        <UploadCsvModal
          renameCallback={renameCallback}
          renameStatusMap={renameStatusMap}
          onRenameStatusChange={setRenameStatusForNode}
        />
      </Box>
      <p>Selected items: ({checkedNodes.map(node => node.name).join(', ')}).</p>
    </Box>
  );
};

export default SearchTree;
