/* eslint-disable no-console */
import * as React from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Table from '@amzn/awsui-components-react/polaris/table';

import {
  DEFAULT_PAGE_SIZE,
  ERROR_LOADING_RECORDERS,
  FETCHING_RECORDER_SERVERS,
  ariaLabels,
  labels,
  testIds,
} from './constants';
import {
  DEFAULT_VISIBLE_COLUMNS,
  RecordersFilteringProperties,
  getRecordersColumnDefinitions,
} from './tableConfig';
import { RecordersHeader } from './components/RecordersHeader';
import { RecordersFooter } from './components/RecordersFooter';
import { RecordersPreferences } from './components/RecordersPreferences';
import { usePreferences } from 'src/hooks/usePreferences';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import {
  getSelectedTreeRecorders,
  setSelectedTreeRecorders,
} from '@stores/slices/milestones/milestonesSlice';
import { RecordersFilter } from './components/RecordersFilter';
import {
  EmptyPropertyFilterQuery,
  EmptyState,
  NoMatchClearFilter,
} from '@components/Table';
import { useEffect } from 'react';
import {
  addFlashBarMessage,
  removeFlashBarMessage,
  setSingleFlashBarMessage,
} from '@stores/slices/milestones/flashBar/flashBarSlice';
import { useRecorders } from './hooks/useRecorders';
import { getBatchQueryRecorderDevices } from 'src/services/useBatchRecorderDevices';
import { getRecorderIdsBySiteCode } from 'src/utils/helpers';
import { useQueryClient } from '@tanstack/react-query';
import { Recorder } from '@features/milestones/types/api-types';

const RecordersTable = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const selectedTreeRecorders = useAppSelector(getSelectedTreeRecorders);

  const { recorders, isLoading, isError, error } = useRecorders();

  const { visibleColumns, pageSize, updatePreferencesHandler } = usePreferences(
    {
      defaultPageSize: DEFAULT_PAGE_SIZE,
      defaultVisibleColumns: DEFAULT_VISIBLE_COLUMNS,
    }
  );

  const emptyStateComponent = <EmptyState title={labels.titles.EMPTY_TITLE} />;

  const {
    items,
    actions,
    propertyFilterProps,
    paginationProps,
    collectionProps,
    filteredItemsCount,
  } = useCollection(recorders, {
    propertyFiltering: {
      filteringProperties: RecordersFilteringProperties,
      empty: emptyStateComponent,
      noMatch: (
        <NoMatchClearFilter
          cleanFilterHandler={() =>
            actions.setPropertyFiltering(EmptyPropertyFilterQuery)
          }
        />
      ),
    },
    pagination: { pageSize: pageSize },
    sorting: {
      defaultState: {
        sortingColumn: getRecordersColumnDefinitions()[0],
      },
    },
    selection: {},
  });

  //Prefetching devices for the current page of recorders, so tree rendering will be faster
  useEffect(() => {
    const recorderIdsBySiteCode = getRecorderIdsBySiteCode(items as Recorder[]);
    Object.values(recorderIdsBySiteCode).forEach(ids => {
      queryClient.prefetchQuery(getBatchQueryRecorderDevices(ids));
    });
  }, [items, queryClient, recorders]);

  useEffect(() => {
    setTimeout(async () => {
      if (isLoading) {
        dispatch(setSingleFlashBarMessage(FETCHING_RECORDER_SERVERS));
      } else {
        dispatch(removeFlashBarMessage(FETCHING_RECORDER_SERVERS));
      }
    }, 200);
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (isError) {
      dispatch(addFlashBarMessage(ERROR_LOADING_RECORDERS));
    }
  }, [isError, dispatch, error]);

  return (
    <SpaceBetween direction="vertical" size="m">
      <Table
        data-testid={testIds.TABLE}
        {...collectionProps}
        onSelectionChange={({ detail }) => {
          dispatch(setSelectedTreeRecorders(detail.selectedItems));
        }}
        selectedItems={selectedTreeRecorders}
        ariaLabels={{
          selectionGroupLabel: ariaLabels.SELECTION_GROUP_LABEL,
          allItemsSelectionLabel: ({ selectedItems }) =>
            ariaLabels.getAllItemsSelectionLabel(selectedItems.length),
          itemSelectionLabel: ({ selectedItems }, item) =>
            ariaLabels.getItemSelectionLabel(selectedItems, item.name),
        }}
        columnDefinitions={getRecordersColumnDefinitions()}
        items={items}
        loading={isLoading}
        loadingText={labels.titles.LOADING_TEXT_TITLE}
        selectionType="multi"
        trackBy="name"
        filter={
          <RecordersFilter
            propertyFilterProps={propertyFilterProps}
            filteredItemsCount={filteredItemsCount}
          />
        }
        header={
          <RecordersHeader
            items={items}
            selectedItems={selectedTreeRecorders}
          />
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: ariaLabels.NEXT_PAGE_LABEL,
              previousPageLabel: ariaLabels.PREVIOUS_PAGE_LABEL,
              pageLabel: pageNumber => ariaLabels.getPageLabel(pageNumber),
            }}
          />
        }
        preferences={
          <RecordersPreferences
            pageSize={pageSize}
            visibleColumns={visibleColumns}
            updatePreferencesHandler={updatePreferencesHandler}
          />
        }
        columnDisplay={visibleColumns.map(c => {
          return { id: c, visible: true };
        })}
      />
      <RecordersFooter />
    </SpaceBetween>
  );
};

export default RecordersTable;
