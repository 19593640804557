/* eslint-disable no-console */
import React from 'react';
import { UINode } from 'src/features/milestones/types/ui-types';
import Checkbox, {
  CheckboxProps,
} from '@amzn/awsui-components-react/polaris/checkbox';
import { useAppDispatch } from 'src/stores/slices/hooks';
import { checkNodes } from 'src/stores/slices/milestones/tree/treeSlice';

interface StyledCheckboxProps {
  node: UINode;
}

const StyledCheckbox = ({ node }: StyledCheckboxProps) => {
  const dispatch = useAppDispatch();

  const handleOnChange = (detail: CheckboxProps.ChangeDetail) => {
    dispatch(checkNodes({ id: node.id, isChecked: detail.checked }));
  };

  return (
    <Checkbox
      checked={node?.isChecked || false}
      onChange={({ detail }) => handleOnChange(detail)}
    />
  );
};

export { StyledCheckbox };
