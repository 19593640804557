import { useRecorders } from '@features/milestones/recorders/hooks/useRecorders';
import * as React from 'react';

import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

import { useMilestonesNavigate } from 'src/features/milestones/hooks/useMilestonesNavigate';
import { ariaLabels, labels } from '@features/milestones/sites/constants';
import { getHeaderCounterText } from 'src/utils/table';
import { Site } from '@features/milestones//types/api-types';
import Icon from '@amzn/awsui-components-react/polaris/icon';

interface SitesHeaderProps {
  items: readonly Site[];
}

const SitesHeader = ({ items }: SitesHeaderProps) => {
  const { navigateToRecodersView } = useMilestonesNavigate();
  const { refetchRecorders } = useRecorders();

  const refreshSites = () => {
    refetchRecorders();
  };

  return (
    <Header
      actions={
        <Button
          ariaLabel="Go To Recorders View"
          iconAlign="right"
          iconName="external"
          onClick={navigateToRecodersView}>
          Switch To Recorders View
        </Button>
      }>
      <SpaceBetween direction="horizontal" size="s">
        <Header counter={getHeaderCounterText(items)}>
          {labels.titles.HEADERER_TITLE}
        </Header>
        <Button onClick={refreshSites} ariaLabel={ariaLabels.REFRESH_ITEMS}>
          <Icon name="refresh" alt={ariaLabels.REFRESH_ITEMS} />
        </Button>
      </SpaceBetween>
    </Header>
  );
};

export { SitesHeader };
