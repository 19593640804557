import React from 'react';
import { IconProps, SpaceBetween } from '@amzn/awsui-components-react';
import CameraIcon from './CameraIcon';
import SingleEncoderIcon from './SingleEncoderIcon';

/*
 * https://heroicons.com/
 * All icons must be wrapped inside AWS Cloudscape component for proper styling
 * We may utilize variant to mark a node as under construction or errors
 */
const EncoderCameraIcon = ({ variant }: IconProps) => {
  return (
    <SpaceBetween size="xxxs" direction="horizontal">
      {/* <Badge color="green">Enc</Badge> */}
      <SingleEncoderIcon variant={variant} />
      <CameraIcon variant={variant} />
    </SpaceBetween>
  );
};

export default EncoderCameraIcon;
