import { UINode as Node } from 'src/features/milestones/types/ui-types';
import { TreeSliceInterface } from './treeSlice';

export const checkAndExpandNodeAction = (
  node: Node,
  targetId: string,
  isChecked: boolean,
  stop: {
    found: boolean;
  }
): void => {
  if (node.id === targetId) {
    stop.found = true;
    node.isExpanded = node.isExpanded || isChecked;
    node.isChecked = isChecked;
    if (node.children) checkAllChildrenAction(node, node.isChecked);
  } else {
    //if we already found the matching node, we will not explore this branch of tree any more
    if (stop.found || !node.children || node.children.length === 0) return;

    //keep searching for targetId within node.children
    node.children.forEach(child => {
      checkAndExpandNodeAction(child, targetId, isChecked, stop);
    });
  }
};

export const checkNodesAction = (
  nodes: Node[],
  isChecked: boolean,
  targetId: string
) => {
  const stop = {
    found: false,
  };

  nodes.forEach(node => {
    checkAndExpandNodeAction(node, targetId, isChecked, stop);
  });
};
export const checkAllChildrenAction = (
  parent: Node,
  isChecked: boolean
): void => {
  parent.children?.forEach(child => {
    child.isChecked = isChecked;
    child.isExpanded = isChecked || child.isExpanded;
    if (child.children) checkAllChildrenAction(child, isChecked);
  });
};

export function syncNodesAction(state: TreeSliceInterface) {
  const calculatedCheckedNodes = [] as Node[];
  syncNodesHelper(state.nodes, calculatedCheckedNodes);
  state.checkedNodes = calculatedCheckedNodes;
}

/**
 * Should have attached checked nodes through Redux state
 */
function syncNodesHelper(nodes: Node[], checkedNodes: Node[]) {
  nodes.forEach(node => {
    if (node.isHidden) {
      node.isChecked = false;
      node.isHighlight = false;
      node.isExpanded = false;
    }
    if (node.isChecked) {
      checkedNodes.push(node);
    }
    if (node.children) {
      syncNodesHelper(node.children, checkedNodes);
    }
  });
}
