import TreeSuccessMessages from '@features/milestones/tree/components/notifications/TreeSuccessMessages';
import React, { ReactElement } from 'react';
import { Box, Container } from '@amzn/awsui-components-react';
import { getSelectedTreeRecorders } from '@stores/slices/milestones/milestonesSlice';
import { useAppSelector } from '@stores/slices/hooks';
import { useSearchParams } from 'react-router-dom';
import { SearchParameters } from '@features/milestones/hooks/useMilestonesNavigate';
import MultiRecordersTree from './MultiRecordersTree';
import TreeLayoutHeader from './TreeLayoutHeader';
import SingleSiteTree from './SingleSiteTree';
import TreeErrorMessages from './components/notifications/TreeErrorMessages';

const TreeLayout = (): ReactElement => {
  const selectedTreeRecorders = useAppSelector(getSelectedTreeRecorders);
  const [searchParams] = useSearchParams();
  const singleSiteId = searchParams.get(SearchParameters.Site);

  return (
    <Box>
      <TreeErrorMessages />
      <TreeSuccessMessages />
      <Container header={<TreeLayoutHeader />}>
        {!singleSiteId && selectedTreeRecorders.length > 0 && (
          <MultiRecordersTree />
        )}
        {singleSiteId && <SingleSiteTree />}
      </Container>
    </Box>
  );
};

export default TreeLayout;
