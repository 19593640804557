import React, { useEffect } from 'react';
import { HowManyPixel } from 'src/components/HowManyPixel';
import TopNotification from '@features/isc/TopNotification';
import { Box, Container, Grid, Header } from '@amzn/awsui-components-react';
import UserPreferences from '@features/isc/UserPreferences';
import DevPanel from 'src/pages/isc/layout/DevPanel';
import { DeviceTree } from '@features/isc/DeviceTree';
import { DeviceMenu } from '@features/isc/DeviceMenu';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { getDevicesForSites } from '@features/isc/actions';
import { selectSelectedSiteCodes } from 'src/stores/slices/userPrefsSlice';

export default function MainContent(): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedSitesFromState = useAppSelector(selectSelectedSiteCodes);

  useEffect(() => {
    if (selectedSitesFromState?.length) {
      dispatch(getDevicesForSites(selectedSitesFromState));
    }
    return;
  }, [dispatch, selectedSitesFromState]);

  return (
    <>
      <Box>
        <TopNotification />
        <Box>
          <Grid
            gridDefinition={[
              { colspan: 1 },
              { colspan: 2 },
              { colspan: 8 },
              { colspan: 1 },
            ]}>
            <div></div>
            <Container header={<Header variant="h2">{t('Actions')}</Header>}>
              <DeviceMenu />
            </Container>
            <Container header={<Header variant="h2">{t('Devices')}</Header>}>
              <DeviceTree key={'tree'} />
            </Container>
            <div></div>
          </Grid>
        </Box>
      </Box>
      <HowManyPixel />
      <UserPreferences />
      <DevPanel />
      {/* <ResizeHandler /> */}
    </>
  );
}
