import React, { useState } from 'react';
import './expandableDevice.scss';
import {
  Badge,
  Popover,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import { IconContext } from 'react-icons';
import { AiFillCaretDown, AiFillCaretRight } from 'react-icons/ai';
/* eslint-disable */

export declare namespace ExpandableDeviceProps {
  interface ChangeDetail {
    expanded: boolean;
  }
}
/* eslint-enable */

export type BadgeColorOptions = 'red' | 'blue' | 'green' | 'grey' | undefined;

export interface ExpandableDeviceProps {
  badgeColor?: BadgeColorOptions;
  badgeText?: string;
  checked?: boolean;
  children?: React.ReactNode;
  className?: string;
  deviceIcon?: React.ReactNode;
  deviceName: string;
  devicePatterns?: Array<string>;
  errorText?: string;
  expanded?: boolean;
  iconSize?: string;
  loading?: boolean;
  onChangeExpanded?: (props: { expanded: boolean }) => void;
  onCheckChanged?: (props: { checked: boolean }) => void;
  onDeviceNameChanged?: (props: { newName: string }) => void;
  textSize?: string; // Indicates server action pending
}

function ErrorText(props: {
  devicePatterns?: Array<string>;
  errorText?: string;
}): React.ReactElement {
  if (props.errorText) {
    return (
      <Popover
        dismissButton={false}
        size="large"
        triggerType="custom"
        content={
          <React.Fragment>
            <p>
              Device Name must match:
              <br />
            </p>
            {props.devicePatterns ? (
              props.devicePatterns.map((pattern, index) => {
                return (
                  <p key={index}>
                    {pattern}
                    <br />
                  </p>
                );
              })
            ) : (
              <></>
            )}
          </React.Fragment>
        }>
        <Badge color={'red'}>{props.errorText} *</Badge>
      </Popover>
    );
  }
  return <></>;
}

function LoadingSpinner(props: { loading?: boolean }): React.ReactElement {
  if (props.loading) {
    return <Spinner variant={'normal'} size={'normal'} />;
  }
  return <></>;
}

function BadgeMessage(props: {
  color: BadgeColorOptions;
  text: string | undefined;
}): React.ReactElement {
  if (props.text != '') return <Badge color={props.color}>{props.text}</Badge>;
  else return <></>;
}

export default function ExpandableDevice(
  props: ExpandableDeviceProps
): React.ReactElement {
  const [editing, setEditing] = useState(false);
  const [newDeviceName, setNewDeviceName] = useState(props.deviceName);
  let iconContainer: React.ReactNode;

  function caretClickHandler(
    clickEvent: React.MouseEvent<HTMLSpanElement>
  ): void {
    clickEvent.stopPropagation();
    const newExpanded = !props.expanded;
    if (props.onChangeExpanded) {
      props.onChangeExpanded({ expanded: newExpanded });
    } else {
      props.expanded = newExpanded;
    }
  }

  let icon: React.ReactNode;
  if (props.expanded) {
    // icon = <Icon name={'caret-down-filled'} />;
    icon = <AiFillCaretDown />;
  } else {
    // icon = <Icon name={'caret-right-filled'} />;
    icon = <AiFillCaretRight />;
  }
  if (props.children)
    iconContainer = (
      <span
        key={'iconContainer'}
        onClick={(clickEvent): void => {
          caretClickHandler(clickEvent);
        }}
        onDoubleClick={(clickEvent): void => {
          clickEvent.stopPropagation();
        }}>
        <IconContext.Provider key={'icp'} value={{ size: props.iconSize }}>
          {icon}
        </IconContext.Provider>
      </span>
    );

  const editTextBox = (
    <input
      key={'editTextBox'}
      type={'text'}
      className={'expandableDeviceEditInput'}
      value={newDeviceName}
      inputMode={'text'}
      onChange={(event): void => {
        setNewDeviceName(event.target.value);
      }}
      onKeyUp={(keyEvent): void => {
        if (keyEvent.nativeEvent.code == 'Enter') {
          setEditing(false);
          if (props.onDeviceNameChanged) {
            props.onDeviceNameChanged({ newName: newDeviceName });
          }
        } else if (editing && keyEvent.nativeEvent.code == 'Escape') {
          // Cancel and reset the input
          setEditing(false);
          setNewDeviceName(props.deviceName);
        }
      }}
    />
  );

  const textStyle = {
    fontSize: props.textSize ?? '1.5em',
    display: 'inline-block',
  };

  return (
    <div className={props.className ?? ''}>
      <div
        className={`expandableDeviceContainer ${
          props.expanded ? 'expanded' : ''
        }`}
        onDoubleClick={(): void => {
          if (!props.loading) setEditing(true);
          setNewDeviceName(props.deviceName);
        }}
        key={'expandableHeader'}>
        <SpaceBetween key={'spaceBetween'} size={'xs'} direction={'horizontal'}>
          {iconContainer}
          <input
            type={'checkbox'}
            className={'expandableDeviceCheckbox'}
            key={'checkBox'}
            checked={props.checked ?? false}
            onChange={(change): void => {
              if (props.onCheckChanged) {
                props.onCheckChanged({ checked: change.target.checked });
              }
              change.stopPropagation();
            }}
          />
          <span key={'deviceIcon'}>{props.deviceIcon}</span>
          {editing ? (
            <span style={textStyle} key={'deviceNameEdit'}>
              {editTextBox}
            </span>
          ) : (
            <>
              <span style={textStyle} key={'deviceName'}>
                {props.loading ? newDeviceName : props.deviceName}
              </span>
            </>
          )}
          <BadgeMessage
            key={'badgeMessage'}
            text={props.badgeText}
            color={props.badgeColor}
          />
          <ErrorText
            key={'errorText'}
            errorText={props.errorText}
            devicePatterns={props.devicePatterns}
          />
          <LoadingSpinner key={'loadingSpinner'} loading={props.loading} />
        </SpaceBetween>
      </div>

      <div
        key={'expandableDeviceContent'}
        className={'expandableDeviceContent'}
        style={{
          display: `${props.expanded ? 'block' : 'none'}`,
        }}>
        {props.children}
      </div>
    </div>
  );
}
