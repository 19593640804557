import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';

export const testIds = {
  EMPTY_STATE: 'table-empty-state',
  EMPTY_STATE_TITLE: 'table-empty-state-title',
  EMPTY_STATE_SUBTITLE: 'table-empty-state-subtitle',
  EMPTY_STATE_ACTION: 'table-empty-state-action',
};

export const tableLabels = {
  titles: {
    NO_MATCHES: 'No matches',
    CANT_FIND_MATCH: 'We can`t find a match.',
    CLEAR_FILTER: 'Clear filter',
    LOADING_TEXT: 'Loading items...',
  },
  placeholders: {
    FILTER: 'Find items',
  },
};

export const tableAriaLabels = {
  SELECTION_GROUP_LABEL: 'Items selection',
  NEXT_PAGE_LABEL: 'Next page',
  PREVIOUS_PAGE_LABEL: 'Previous page',
  REFRESH_ITEMS: 'Refresh items',
  FILTERING_LABEL: 'Filter items',
  getAllItemsSelectionLabel: (selectedItemsLength: number): string =>
    `${selectedItemsLength} ${
      selectedItemsLength === 1 ? 'item' : 'items'
    } selected`,
  getItemSelectionLabel: <T extends Record<string, any>>(
    selectedItems: readonly T[],
    itemName: string,
    namePropTitleInT: string
  ): string => {
    const isItemSelected = selectedItems.filter(i =>
      namePropTitleInT in i ? i[namePropTitleInT] === itemName : false
    ).length;
    return `${itemName} is ${isItemSelected ? '' : 'not'} selected`;
  },
  getPageLabel: (pageNumber: number): string =>
    `Page ${pageNumber} of all pages`,
};

export const EmptyPropertyFilterQuery: PropertyFilterProps.Query = {
  tokens: [],
  operation: 'and',
};
