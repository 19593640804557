import * as React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { testIds } from '@components/Table/constants';

interface EmptyStateProps {
  action?: JSX.Element;
  subtitle?: string;
  title: string;
}

export function EmptyState({
  title,
  subtitle,
  action,
}: EmptyStateProps): JSX.Element {
  return (
    <Box textAlign="center" color="inherit" data-testid={testIds.EMPTY_STATE}>
      <Box
        variant="strong"
        textAlign="center"
        color="inherit"
        data-testid={testIds.EMPTY_STATE_TITLE}>
        {title}
      </Box>
      {subtitle && (
        <Box
          variant="p"
          color="inherit"
          data-testid={testIds.EMPTY_STATE_SUBTITLE}>
          {subtitle}
        </Box>
      )}
      {action && (
        <Box margin={{ top: 's' }} data-testid={testIds.EMPTY_STATE_ACTION}>
          {action}
        </Box>
      )}
    </Box>
  );
}
