import { useRecorders } from '@features/milestones/recorders/hooks/useRecorders';
import * as React from 'react';

import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

import { useMilestonesNavigate } from 'src/features/milestones/hooks/useMilestonesNavigate';
import { ariaLabels, labels } from '@features/milestones/recorders/constants';
import { getHeaderCounterText } from 'src/utils/table';
import { Recorder } from '@features/milestones//types/api-types';
import Icon from '@amzn/awsui-components-react/polaris/icon';

interface RecordersHeaderProps {
  items: readonly Recorder[];
  selectedItems: Recorder[];
}

const RecordersHeader = ({ items, selectedItems }: RecordersHeaderProps) => {
  const { refetchRecorders } = useRecorders();
  const { navigateToSitesView, navigateToTreeWithMultipleRecorders } =
    useMilestonesNavigate();

  const onViewSelectedTreeHandler = () => {
    navigateToTreeWithMultipleRecorders();
  };

  const refreshRecorders = () => {
    refetchRecorders();
  };

  return (
    <Header
      actions={
        <Button
          ariaLabel="Switch To Sites View"
          iconAlign="right"
          iconName="external"
          onClick={navigateToSitesView}>
          Switch To Sites View
        </Button>
      }>
      <SpaceBetween direction="horizontal" size="s">
        <Header counter={getHeaderCounterText(items, selectedItems)}>
          {labels.titles.HEADERER_TITLE}
        </Header>
        <Button onClick={refreshRecorders} ariaLabel={ariaLabels.REFRESH_ITEMS}>
          <Icon name="refresh" alt={ariaLabels.REFRESH_ITEMS} />
        </Button>
        <Button
          variant="primary"
          disabled={selectedItems.length === 0}
          onClick={() => onViewSelectedTreeHandler()}>
          {labels.buttons.VIEW_SELECTED_TREE}
        </Button>
      </SpaceBetween>
    </Header>
  );
};

export { RecordersHeader };
