import { useMutation } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  MilestoneRenameResponse,
  RenameEncoderMutation,
  RenameEncoderMutationVariables,
} from 'src/API';
import { renameEncoder } from 'src/graphql/mutations';

const apiRenameEncoderServer = async (
  variables: RenameEncoderMutationVariables
): Promise<MilestoneRenameResponse> => {
  const response = (await API.graphql(
    graphqlOperation(renameEncoder, variables)
  )) as GraphQLResult<RenameEncoderMutation>;

  const renameEncoderResponse = response.data?.renameEncoder;
  if (renameEncoderResponse?.status !== 200) {
    throw new Error(
      `Failed renameEncoder with status: ${renameEncoderResponse?.status}`
    );
  }

  if (!renameEncoderResponse.device) {
    throw new Error(
      `Failed renameEncoder with empty data ${renameEncoderResponse?.device}`
    );
  }
  return renameEncoderResponse;
};

export const useEncoderRenaming = () => {
  const {
    mutate: renameEncoderMutation,
    mutateAsync: renameEncoderAsync,
    data: response,
    isSuccess,
    isError,
    isPending,
  } = useMutation({
    mutationFn: apiRenameEncoderServer,
  });

  return {
    renameEncoderMutation,
    renameEncoderAsync,
    response,
    isSuccess,
    isError,
    isPending,
  };
};
