import React from 'react';
import { Button } from '@amzn/awsui-components-react';
import { useAppSelector } from 'src/stores/slices/hooks';
import { createIscCsvData, downloadCsvFile } from '@utils/csv';

export function ExportCsvButton(): React.ReactElement {
  const parentDevicesChecked = useAppSelector(
    state => state.deviceState.parentDevices
  ).filter(device => device.checked);
  const childDevicesChecked = useAppSelector(
    state => state.deviceState.childDevices
  ).filter(device => device.checked);
  const subchildDevicesChecked = useAppSelector(
    state => state.deviceState.subchildDevices
  ).filter(device => device.checked);

  const isChecked = Boolean(
    parentDevicesChecked?.length ||
      childDevicesChecked?.length ||
      subchildDevicesChecked?.length
  );

  function exportCvsFile(): void {
    const csvData = createIscCsvData(
      parentDevicesChecked,
      childDevicesChecked,
      subchildDevicesChecked
    );
    downloadCsvFile('ISC_Devices', csvData);
  }

  return (
    <Button
      className={'ninetyWidth'}
      disabled={!isChecked}
      onClick={(): void => {
        exportCvsFile();
      }}
      iconName={'download'}>
      Export CSV
    </Button>
  );
}
