/* eslint-disable no-console */
/**
 * https://tanstack.com/query/latest/docs/framework/react/guides/query-keys#query-keys-are-hashed-deterministically
 * When query key is an object, order of properties does not matter
 * When queryKey is a array, orders matter
 */
export const CacheKeys = {
  RecorderServers: 'RecorderServers',
  RecorderDevices: 'RecorderDevices',
  BatchDevices: 'BatchDevices',
  Placeholder: 'Placeholder',
};

/**
 * By default, when cacheKey is an array, orders of array elements matter
 * However, we want a determinitic cache key from array, which disregards array order
 * For instance: getRecorderServers for  ["AAA1", "BBB2"] should use
 * the same cache as getRecorderServers for ["BBB2", "AAA1"]
 */
export function getArrayCacheKey(array: string[]): string {
  const mutableArray = [...array];

  //Redux arrays are mutable, which directly change UI states, sorting function is also mutation array.
  //Therefore we should only sort the copy version of original array.
  const sortedArray = mutableArray.sort();
  return sortedArray.join('-');
}
