import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import {
  Alert,
  Badge,
  Box,
  Button,
  Container,
  Header,
  Modal,
  SpaceBetween,
  StatusIndicator,
  Table,
} from '@amzn/awsui-components-react';
import { getDeviceByName, log } from 'src/utils/helpers';
import { useTranslation } from 'react-i18next';
import { TreeDevice } from 'src/types';
import {
  selectShowDeviceLinkFileUpload,
  setShowDeviceLinkFileUpload,
} from 'src/stores/slices/userSlice';
import { devicesLinked, resetState } from 'src/features/isc/actions/thunks';
import { API } from 'aws-amplify';
import { graphqlOperation } from '@aws-amplify/api';
import { getDevicesForSites } from 'src/graphql/queries';
import { CsvUploadForm } from 'src/features/isc/CsvUploadForm';
import { setLinkErrorText } from 'src/stores/slices/isc/devicesSlice';

export interface CsvLinkDevice {
  cameraName: string;
  deviceName: string;
  done: boolean;
  errorText: string | null;
  loading: boolean;
  region: number | null;
}

interface RawCsvLinkDevice {
  cameraName: string;
  deviceName: string;
}

type DeviceRegionMap = Record<string, number | null>;
enum States {
  idle,
  parsing,
  regions,
}

const templateFile =
  'data:text/plain;charset=utf-8,' +
  encodeURIComponent('DeviceName,CameraName\n');

function DeviceList(props: { devices: CsvLinkDevice[] }): React.ReactElement {
  const { t } = useTranslation();
  const linkDevices = useAppSelector(state => state.deviceState.linkDevices);

  if (!props.devices.length) {
    return <></>;
  }

  const devices = props.devices.map(device => {
    const {
      loading = device.loading,
      done = device.done,
      errorText,
    } = linkDevices[device.deviceName] || {};
    return {
      ...device,
      errorText,
      loading,
      done,
    };
  });

  if (devices.length > 0) {
    return (
      <>
        <Table
          columnDefinitions={[
            {
              id: 'deviceName',
              header: t('Device Name'),
              cell: (item): string => item.deviceName || '-',
              maxWidth: window.innerWidth / 2 - 100,
              sortingField: 'deviceName',
            },
            {
              id: 'cameraName',
              header: t('Camera Name'),
              cell: (item): string => item.cameraName || '-',
              maxWidth: window.innerWidth / 2 - 100,
              sortingField: 'cameraName',
            },
            {
              id: 'status',
              header: t('Status'),
              cell: (item): React.ReactElement => {
                if (item.errorText) {
                  return <Badge color={'red'}>{t(item.errorText)}</Badge>;
                }
                if (item.done) {
                  return (
                    <StatusIndicator type={'success'}>
                      {t('Success')}
                    </StatusIndicator>
                  );
                }
                if (item.loading) {
                  return (
                    <StatusIndicator type={'loading'}>
                      {t('Working')}
                    </StatusIndicator>
                  );
                }
                return <StatusIndicator type={'stopped'}></StatusIndicator>;
              },
              sortingField: 'status',
            },
          ]}
          visibleColumns={['deviceName', 'cameraName', 'status']}
          items={devices}
          sortingDisabled
          sortingColumn={{ sortingField: 'status' }}
          empty={<></>}
          header={<Header> Devices for linking </Header>}
        />
      </>
    );
  } else {
    return <></>;
  }
}

function LinkingState(props: { state: States }): React.ReactElement {
  const { t } = useTranslation();

  switch (props.state) {
    case States.parsing:
      return (
        <StatusIndicator type={'loading'}>
          {t('State: Parsing')}
        </StatusIndicator>
      );
    case States.regions:
      return (
        <StatusIndicator type={'loading'}>
          {t('State: Downloading device region data')}
        </StatusIndicator>
      );
    default:
      return <></>;
  }
}

export default function DeviceLinkFileUpload(): React.ReactElement {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [importError, setImportError] = useState<string>('');
  const [csvDevices, setCsvDevices] = useState<CsvLinkDevice[]>([]);
  const [parsingState, setParsingState] = useState<States>(States.idle);
  const [linkingState, setLinkingState] = useState<boolean>(false);

  const [deviceRegionMap, setDeviceRegionMap] = useState<DeviceRegionMap>({});
  const allDevices = [
    ...useAppSelector(state => state.deviceState.parentDevices),
    ...useAppSelector(state => state.deviceState.childDevices),
    ...useAppSelector(state => state.deviceState.subchildDevices),
  ];

  if (!Object.keys(deviceRegionMap).length && allDevices.length) {
    setDeviceRegionMap(devicesToMap(allDevices));
  }

  const showDeviceLinkFileUpload = useAppSelector(
    selectShowDeviceLinkFileUpload
  );

  function closeFileUpload(): void {
    setImportError('');
    dispatch(setShowDeviceLinkFileUpload(false));
    csvDevices.forEach(item => {
      dispatch(resetState({ device: item }));
    });
    setLinkingState(false);
    setCsvDevices([]);
  }

  function devicesToMap(devices: TreeDevice[]): DeviceRegionMap {
    return devices.reduce((acc, device) => {
      acc[device.device_name] = device.region_id;
      return acc;
    }, {} as DeviceRegionMap);
  }

  function getSiteCodeFromDeviceName(name: string): string {
    const [firstPart] = name.split('-');
    const last = firstPart.split(' ').pop() as string;
    return last.split('_').pop() as string;
  }

  async function findMissingDeviceRegions(
    unknownSiteCodes: string[],
    regionlessDevices: string[]
  ): Promise<DeviceRegionMap> {
    log('Unknown site codes we need to try and get:', false, unknownSiteCodes);
    const queryResult: any = await API.graphql(
      graphqlOperation(getDevicesForSites, {
        sites: unknownSiteCodes.join(','),
      })
    );
    const missingDevices = queryResult.data
      ? queryResult.data.getDevicesForSites?.devices
      : [];

    return regionlessDevices.reduce((acc, name) => {
      const device = getDeviceByName(missingDevices, name);
      acc[name] = device ? device.region_id : null;
      return acc;
    }, deviceRegionMap);
  }

  async function getDeviceRegionMap(
    raw: RawCsvLinkDevice[]
  ): Promise<DeviceRegionMap> {
    const { unknownSiteCodes, unknownDeviceNames } = raw.reduce(
      (acc, { deviceName }) => {
        if (deviceRegionMap[deviceName]) {
          return acc;
        }
        const siteCode = getSiteCodeFromDeviceName(deviceName);
        if (deviceName && siteCode) {
          acc.unknownSiteCodes.add(siteCode);
          acc.unknownDeviceNames.push(deviceName);
        }
        return acc;
      },
      { unknownSiteCodes: new Set(), unknownDeviceNames: [] } as {
        unknownDeviceNames: string[];
        unknownSiteCodes: Set<string>;
      }
    );

    if (unknownSiteCodes.size) {
      setParsingState(States.regions);
      const newMap = await findMissingDeviceRegions(
        Array.from(unknownSiteCodes.values()),
        unknownDeviceNames
      );

      setDeviceRegionMap(newMap);

      return newMap;
    }

    return deviceRegionMap;
  }

  async function enrichDevices(
    raw: RawCsvLinkDevice[]
  ): Promise<CsvLinkDevice[]> {
    const nameRegionMap = await getDeviceRegionMap(raw);

    return raw.map(r => {
      const region = nameRegionMap[r.deviceName];

      return {
        deviceName: r.deviceName,
        cameraName: r.cameraName,
        done: false,
        loading: false,
        errorText: region ? null : 'Device not found',
        region: region && null,
      };
    });
  }

  async function parseCsv(data: string[][]): Promise<void> {
    const outDevices: RawCsvLinkDevice[] = data.map(line => {
      return {
        deviceName: line[0],
        cameraName: line[1],
      };
    });

    const devices = await enrichDevices(outDevices);
    log('Devices from loadCsv', false, { devices });
    if (devices.length) {
      setCsvDevices(devices);
    } else {
      log('No devices loaded');
      setImportError(
        'No Devices Found, please check the format of the file and try again'
      );
    }
  }

  function handleCsvUploadStart(): void {
    setLinkingState(false);
    setImportError('');
    setParsingState(States.parsing);
  }

  function handleCsvUploadEnd(): void {
    setParsingState(States.idle);
  }

  function acceptHandler(): void {
    // Push to appsync, devices will be updated async
    const newDevices = csvDevices.map(device => {
      const region = deviceRegionMap[device.deviceName];
      log(`${device.deviceName} - ${region ? 'Found' : 'Not Found'}`);

      if (!region) {
        dispatch(
          setLinkErrorText({
            deviceName: device.deviceName,
            errorText: 'Failed to find the device and region',
          })
        );
      }

      return {
        ...device,
        loading: region !== null,
      };
    });

    const devicesToLink = newDevices
      .map(device => {
        const region = deviceRegionMap[device.deviceName];

        if (!region) {
          return null;
        }

        return {
          deviceName: device.deviceName,
          cameraName: device.cameraName,
          region,
        };
      })
      .filter(Boolean) as any;

    setLinkingState(true);
    dispatch(devicesLinked(devicesToLink));

    setCsvDevices(newDevices);
  }

  return (
    <Modal
      visible={showDeviceLinkFileUpload}
      onDismiss={closeFileUpload}
      closeAriaLabel={'close csv file upload window'}
      header={'Select Device linking file to upload'}
      size={'max'}
      footer={
        <>
          <Box float={'right'}>
            <SpaceBetween size={'m'} direction={'horizontal'}>
              {!linkingState && (
                <Button
                  onClick={acceptHandler}
                  disabled={csvDevices.length <= 0}>
                  Accept/Continue
                </Button>
              )}

              <Button onClick={closeFileUpload}>
                {linkingState ? 'Close' : 'Cancel'}
              </Button>
            </SpaceBetween>
          </Box>
        </>
      }>
      <Alert visible={importError != ''}>{importError}</Alert>
      <Container>
        <p>{t('Choose a Device linking file to upload.')}</p>
        <p>
          To download CSV template please&nbsp;
          <a
            download={'device-link-template.csv'}
            href={templateFile}
            target={'_blank'}
            rel="noreferrer">
            click here
          </a>
        </p>
        <CsvUploadForm
          onCsvUploadStart={handleCsvUploadStart}
          onCsvUploadEnd={handleCsvUploadEnd}
          parseCsv={parseCsv}></CsvUploadForm>
      </Container>
      <LinkingState state={parsingState}></LinkingState>
      <DeviceList devices={csvDevices} />
    </Modal>
  );
}
