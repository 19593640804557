import { all, call, select, spawn, takeLatest } from 'redux-saga/effects';
import {
  GET_DEVICES_FOR_SITES,
  GET_USER_SITES,
} from 'src/features/isc/actions';
import { log } from 'src/utils/helpers';
import {
  appSyncGetDevicesForSites,
  appSyncGetUserSites,
} from 'src/features/isc/sagas/appsync';
import { RootState } from 'src/stores/slices/store';

export default function* rootSaga(): any {
  const sagas = [appSyncEventDispatcher];
  // This fancy thing will restart any crashed saga automatically
  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            log(e);
          }
        }
      })
    )
  );
}

function* appSyncEventDispatcher(): any {
  yield takeLatest(GET_USER_SITES, appSyncGetUserSites);
  yield takeLatest(GET_DEVICES_FOR_SITES, appSyncGetDevicesForSites);
  // yield throttle(500, GET_USER_NOTIFICATIONS, appsyncGetUserNotifications);
  // yield takeLatest(GET_USER_PREFERENCES, appSyncGetUserPreferences);
}

// Proper typing for yield/selector
export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

export function selectState<T>(selector: (s: RootState) => T): any {
  return select(selector);
}
