import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CacheKeys, getArrayCacheKey } from './cache-keys';
import {
  GetBatchRecorderDevicesQuery,
  GetBatchRecorderDevicesQueryVariables,
  RecorderDeviceTuple,
} from 'src/API';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { getBatchRecorderDevices } from 'src/graphql/queries';
import { log } from 'src/utils/helpers';

/**
 * This hook will call getBatchRecorderDevices by giving an array of recorderServerIds
 */

export async function apiBatchGetRecorderDevices(
  queryProps: GetBatchRecorderDevicesQueryVariables
): Promise<RecorderDeviceTuple[]> {
  const response = (await API.graphql(
    graphqlOperation(getBatchRecorderDevices, queryProps)
  )) as GraphQLResult<GetBatchRecorderDevicesQuery>;

  const batchRecorderDevicesResponse = response.data?.getBatchRecorderDevices;
  if (batchRecorderDevicesResponse?.status !== 200) {
    throw new Error(
      `Failed getBatchRecorderDevices with status: ${batchRecorderDevicesResponse?.status}`
    );
  }

  if (!batchRecorderDevicesResponse.data) {
    throw new Error(
      `Failed getBatchRecorderDevices with empty data ${batchRecorderDevicesResponse?.data}`
    );
  }

  return batchRecorderDevicesResponse.data as RecorderDeviceTuple[];
}

export type GetBatchQueryResponse = {
  queryFn: () => Promise<RecorderDeviceTuple[]>;
  queryKey: string[];
};

export const getBatchQueryRecorderDevices = (
  recorderServerIds: string[]
): GetBatchQueryResponse => {
  const recorderServersCacheKey = getArrayCacheKey(recorderServerIds);
  log('*** in getBatchQueryRecorderDevices');
  return {
    queryKey: [CacheKeys.BatchDevices, recorderServersCacheKey],
    queryFn: async () => {
      try {
        if (!recorderServerIds || recorderServerIds.length === 0) {
          return Promise.resolve([]);
        }
        const devices = await apiBatchGetRecorderDevices({ recorderServerIds });

        // Note: some of the tuples in 'devices' may have a 'recorderId' whose value is the string 'undefined'
        return devices;
      } catch (err) {
        throw new Error(
          `Unabled to retrieve hardwares for recorder servers: ${recorderServerIds.join(
            ', '
          )}`
        );
      }
    },
  };
};

interface UseBatchRecorderDevicesResponse {
  error: Error | null;
  isError: boolean;
  isLoading: boolean;
  recorderDevices: RecorderDeviceTuple[];
  refetchBatchRecorderDevices: () => void;
  removeBatchRecorderDeviceQueries: () => void;
}

export const useBatchRecorderDevices = (
  recorderServerIds: string[]
): UseBatchRecorderDevicesResponse => {
  const queryClient = useQueryClient();
  const {
    isLoading: isLoadingApi,
    isFetching,
    isRefetching,
    data,
    isError,
    error,
  } = useQuery(getBatchQueryRecorderDevices(recorderServerIds));

  const isLoading = isLoadingApi || isFetching || isRefetching;

  const recorderDevices = data?.length ? data : [];

  function refetchBatchRecorderDevices() {
    const recorderServersCacheKey = getArrayCacheKey(recorderServerIds);
    return queryClient.invalidateQueries({
      queryKey: [CacheKeys.BatchDevices, recorderServersCacheKey],
    });
  }

  function removeBatchRecorderDeviceQueries() {
    const recorderServersCacheKey = getArrayCacheKey(recorderServerIds);
    return queryClient.removeQueries({
      queryKey: [CacheKeys.BatchDevices, recorderServersCacheKey],
    });
  }

  return {
    isLoading,
    recorderDevices,
    isError,
    error,
    refetchBatchRecorderDevices,
    removeBatchRecorderDeviceQueries,
  };
};
