import { UINode } from '@features/milestones/types/ui-types';
import { TreeDevice } from 'src/types';
import { decodeTreeId, encodeTreeId, getRegionId } from '@utils/nodeInfo';
import { log } from '@utils/helpers';

export enum SortContext {
  ISC = 'ISC',
  Milestones = 'Milestones',
}

export enum RenameStatus {
  Error = 'error',
  Init = 'init',
  Loading = 'loading',
  Success = 'success',
}

export type ChangeRenameStatusFn = (
  nodeId: string,
  statusVal: RenameStatus
) => void;

export interface CsvNode extends UINode {
  newName: string;
}

const csvSortIndices = {
  [SortContext.ISC]: [0, 1, 2], // ISC - sort by: parentId / childId / subchildId
  [SortContext.Milestones]: [0, 1, 2, 3], // Milestones - sort by: location / region / type / name
};

type CsvRow = Array<number | string>;

/* begin helpers for CSV download */

function csvSortCompareFn(ctx: SortContext, a: CsvRow, b: CsvRow): number {
  for (const index in csvSortIndices[ctx]) {
    if (a[index] < b[index]) return -1;
    if (a[index] > b[index]) return 1;
  }

  // equality - should not happen...
  return 0;
}

// row: parentId, childId, subchildId, "deviceName", "newName" (blank)
function getIscRow(device: TreeDevice): CsvRow {
  return [
    device.parent_device_id,
    device.child_device_id,
    device.subchild_device_id,
    `"${device.device_name}"`,
    '""',
  ];
}

// row: location, region, type, name, newName (blank)
// note: do not need to traverse node.children - checked children will be in main list
function getMilestonesRow(node: UINode): Array<string> {
  const nodeInfo = decodeTreeId(node.id);

  return [
    `"${nodeInfo.location}"`,
    `"${nodeInfo.regionName}"`,
    `"${nodeInfo.type}"`,
    `"${nodeInfo.name}"`,
    '""',
  ];
}

export function createMilestonesCsvData(nodes: UINode[]): string {
  const header = 'location,region,type,name,newName';
  const rows: Array<string> = nodes
    .filter((node: UINode) => node.type !== 'Site')
    .map((node: UINode) => getMilestonesRow(node))
    .sort((a, b) => csvSortCompareFn(SortContext.Milestones, a, b))
    .map((row: CsvRow) => row.join(','));

  // once toSpliced() is supported, can be one continuous chain...
  rows.splice(0, 0, header);
  return rows.join('\r\n'); // TODO: use OS-relevant line sep?
}

export function createIscCsvData(
  parents: TreeDevice[],
  children: TreeDevice[],
  subchildren: TreeDevice[]
): string {
  const header = 'parentId,childId,subchildId,deviceName,newName';
  const rows = parents
    .concat(children, subchildren)
    .map((device: TreeDevice) => getIscRow(device))
    .sort((a, b) => csvSortCompareFn(SortContext.ISC, a, b))
    .map((row: CsvRow) => row.join(','));

  // once toSpliced() is supported, can be one continuous chain...
  rows.splice(0, 0, header);
  return rows.join('\r\n'); // TODO: use OS-relevant line sep?
}

function getFilename(prefix: string): string {
  const date = new Date();
  const timestamp = [
    String(date.getFullYear()),
    String(date.getMonth() + 1).padStart(2, '0'),
    String(date.getDate()).padStart(2, '0'),
    '-',
    String(date.getHours()).padStart(2, '0'),
    String(date.getMinutes()).padStart(2, '0'),
  ].join('');
  return `${prefix}-export-${timestamp}.csv`;
}

export function downloadCsvFile(filePrefix: string, csvData: string): void {
  const csvFileName = getFilename(filePrefix);
  const csvUrl = URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }));
  const csvLink = document.createElement('a');

  csvLink.setAttribute('data-testid', 'csv-export-link');
  csvLink.href = csvUrl;
  csvLink.download = csvFileName;
  document.body.appendChild(csvLink);
  csvLink.click();
  document.body.removeChild(csvLink);
}

/* end helpers for CSV download */

/* begin helpers for CSV upload */

export function getCsvNodeFromImportCsvRow(row: string[]): CsvNode {
  log('** getCsvNodeFromImportCsvRow()');
  log('  -- incoming row: ', false, row);
  const [location, regionName, type, name, newName] = row;
  const region = getRegionId(regionName);
  const node = {
    id: '',
    isChecked: false,
    isExpanded: false,
    isHidden: false,
    isHighlight: false,
    isInvalidName: false,
    isLoading: false,
    name,
    newName,
    location,
    region,
    type,
  } as CsvNode;

  node.id = encodeTreeId(node);
  log('  -- outgoing node: ', false, node);
  return node;
}

export function parseCsvFile(fileText: string): Array<Array<string>> {
  return fileText
    .split('\n') // split string to lines
    .slice(1) // Remove header
    .map(row => row.trim()) // remove white spaces for each line
    .filter(row => row !== '') // remove blank lines
    .map(
      row =>
        row
          .split(',') // split each row into array of fields
          .map(field => field.replace(/['"]/g, '').trim()) // remove quotes and white space from each field
    );
}

/* end helpers for CSV upload */
