import React from 'react';
import MainContent from 'src/pages/isc/layout/MainContent';
import { AppLayout } from '@amzn/awsui-components-react';
import IscTopNav from 'src/pages/isc/layout/IscTopNav';
import FileUpload from 'src/features/isc/FileUpload';
import DeviceLinkFileUpload from 'src/features/isc/DeviceLinkFileUpload';

function DeviceRenamePage(): React.ReactElement {
  return (
    <>
      <IscTopNav />
      <AppLayout
        headerSelector={'#header'}
        navigationHide
        content={<MainContent />} // Main content on the page, defined below
        toolsHide
      />
      <FileUpload />
      <DeviceLinkFileUpload />
    </>
  );
}

export default DeviceRenamePage;
