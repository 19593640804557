import { useCallback, useState } from 'react';
import { useRecorders } from '@features/milestones/recorders/hooks/useRecorders';
import {
  TreeErrorCodes,
  TreeSuccessCodes,
} from '@features/milestones/tree/constants';
import { DeviceType, NodeTypes } from '@features/milestones/types/api-types';
import { UINode } from '@features/milestones/types/ui-types';
import {
  optimisticUpdateRecorder,
  updateRecorderNameAndId,
} from '@stores/slices/milestones/milestonesSlice';
import {
  addErrorMessage,
  addSuccessMessage,
  optimisticUpdateName,
  removeErrorMessage,
  removeSuccessMessage,
  updateNodeNameAndId,
} from '@stores/slices/milestones/tree/treeSlice';
import { MilestoneRenameResponse } from 'src/API';
import { ChangeRenameStatusFn, RenameStatus } from '@utils/csv';

import { useCameraRenaming } from 'src/services/mutations/useCameraRenaming';
import { useEncoderCameraRenaming } from 'src/services/mutations/useEncoderCameraRenaming';
import { useEncoderMetadataRenaming } from 'src/services/mutations/useEncoderMetadataRenaming';
import { useEncoderRenaming } from 'src/services/mutations/useEncoderRenaming';
import { useRecorderServerRenaming } from 'src/services/mutations/useRecorderServersRenaming';
import { useAppDispatch } from 'src/stores/slices/hooks';

export type RenameProps = {
  onRename: (oldId: string) => void; // upon successful rename, clear cache and refetch
};

/**
 *
 */
const useRenameMilestonesNode = ({
  onRename,
}: RenameProps): {
  renameCallback: (node: UINode, newName: string) => Promise<void>;
  renameStatusMap: Record<string, RenameStatus>;
  setRenameStatusForNode: ChangeRenameStatusFn;
} => {
  const dispatch = useAppDispatch();
  const { renameCameraAsync } = useCameraRenaming();
  const { renameRecorderServerAsync } = useRecorderServerRenaming();
  const { renameEncoderCameraAsync } = useEncoderCameraRenaming();
  const { renameEncoderMetadataAsync } = useEncoderMetadataRenaming();
  const { renameEncoderAsync } = useEncoderRenaming();
  const { refetchRecorders } = useRecorders();
  const [renameStatusMap, setRenameStatusMap] = useState<
    Record<string, RenameStatus>
  >({});
  const setRenameStatusForNode: ChangeRenameStatusFn = useCallback(
    (nodeId: string, statusVal: RenameStatus): void => {
      setRenameStatusMap(statusMap => ({ ...statusMap, [nodeId]: statusVal }));
    },
    [setRenameStatusMap]
  );

  function handleCallbackError(
    errorCode: TreeErrorCodes,
    existingName: string,
    newName: string,
    existingId: string
  ) {
    displayErrorMessage(errorCode, existingName, newName);
    //rollback to previous name and id
    dispatch(
      updateNodeNameAndId({
        id: existingId,
        newId: existingId,
        newName: existingName,
        renamingError: errorCode,
      })
    );
    dispatch(
      updateRecorderNameAndId({
        id: existingId,
        newId: existingId,
        newName: existingName,
        renamingError: errorCode,
      })
    );
  }

  function displayErrorMessage(
    errorCode: TreeErrorCodes,
    existingName: string,
    newName: string
  ) {
    const errorMessage = `${errorCode}: ${existingName} into ${newName}`;
    dispatch(addErrorMessage(errorMessage));
    setTimeout(() => {
      dispatch(removeErrorMessage(errorMessage));
    }, 30000);
  }

  function displaySuccessMessage(
    successPrefix: TreeSuccessCodes,
    existingName: string,
    newName: string
  ) {
    const successMessage = `${successPrefix}: ${existingName} into ${newName}`;
    dispatch(addSuccessMessage(successMessage));
    setTimeout(() => {
      dispatch(removeSuccessMessage(successMessage));
    }, 5000);
  }

  function mapNodeTypeToTreeErrorCode(nodeType: DeviceType): TreeErrorCodes {
    switch (nodeType) {
      case NodeTypes.Encoder:
        return TreeErrorCodes.EncoderRenameError;
      case NodeTypes.EncoderCamera:
        return TreeErrorCodes.EncoderCameraRenameError;
      case NodeTypes.EncoderMetadata:
        return TreeErrorCodes.EncoderMetadataRenameError;
      case NodeTypes.Recorder:
        return TreeErrorCodes.RecorderServerRenameError;
      case NodeTypes.HardwareCamera:
        return TreeErrorCodes.CameraRenameError;
      default:
        return TreeErrorCodes.UNKNOWN;
    }
  }

  function mapNodeTypeToTreeSuccessCode(
    nodeType: DeviceType
  ): TreeSuccessCodes {
    switch (nodeType) {
      case NodeTypes.Encoder:
        return TreeSuccessCodes.EncoderRenameSuccess;
      case NodeTypes.EncoderCamera:
        return TreeSuccessCodes.EncoderCameraRenameSuccess;
      case NodeTypes.EncoderMetadata:
        return TreeSuccessCodes.EncoderMetadataRenameSuccess;
      case NodeTypes.Recorder:
        return TreeSuccessCodes.RecorderServerRenameSuccess;
      case NodeTypes.HardwareCamera:
        return TreeSuccessCodes.CameraRenameSuccess;
      default:
        return TreeSuccessCodes.UNKNOWN;
    }
  }

  async function getRenameResultAsync(
    nodeType: DeviceType,
    newName: string,
    existingId: string
  ): Promise<MilestoneRenameResponse> {
    switch (nodeType) {
      case NodeTypes.Encoder:
        return renameEncoderAsync({ name: newName, id: existingId });
      case NodeTypes.EncoderCamera:
        return renameEncoderCameraAsync({ name: newName, id: existingId });
      case NodeTypes.EncoderMetadata:
        return renameEncoderMetadataAsync({ name: newName, id: existingId });
      case NodeTypes.Recorder:
        return renameRecorderServerAsync({ name: newName, id: existingId });
      case NodeTypes.HardwareCamera:
        return renameCameraAsync({ name: newName, id: existingId });
      default:
        throw new Error(`Unknown node type: ${nodeType}`);
    }
  }

  function optimisticallyRename(
    nodeType: DeviceType,
    existingId: string,
    newName: string
  ) {
    if (nodeType === NodeTypes.Recorder) {
      dispatch(
        optimisticUpdateRecorder({
          id: existingId,
          newName,
        })
      );
    }
    dispatch(
      optimisticUpdateName({
        id: existingId,
        newName,
      })
    );
  }

  function finalizeRename(
    nodeType: DeviceType,
    existingId: string,
    result: MilestoneRenameResponse
  ) {
    if (nodeType === NodeTypes.Recorder) {
      dispatch(
        updateRecorderNameAndId({
          id: existingId,
          newId: result.device.id,
          newName: result.device.name,
          renamingError: undefined,
        })
      );
    }
    dispatch(
      updateNodeNameAndId({
        id: existingId,
        newId: result.device.id,
        newName: result.device.name,
        renamingError: undefined, //on success, remove error icon on the node, if it is there
      })
    );
    displaySuccessMessage(
      mapNodeTypeToTreeSuccessCode(nodeType),
      result.device.lastName,
      result.device.name
    );
    if (nodeType === NodeTypes.Recorder) {
      refetchRecorders();
    }
    onRename(existingId);
  }

  const renameCallback = async (
    node: UINode,
    newName: string
  ): Promise<void> => {
    const existingName = node.name;
    const existingId = node.id;
    try {
      optimisticallyRename(node.type, existingId, newName);
      setRenameStatusForNode(existingId, RenameStatus.Loading);
      const result = await getRenameResultAsync(
        node?.type,
        newName,
        existingId
      );
      setRenameStatusForNode(existingId, RenameStatus.Success);
      finalizeRename(node.type, existingId, result);
    } catch (error) {
      setRenameStatusForNode(existingId, RenameStatus.Error);
      handleCallbackError(
        mapNodeTypeToTreeErrorCode(node.type),
        existingName,
        newName,
        existingId
      );
    }
  };

  return { renameCallback, renameStatusMap, setRenameStatusForNode };
};

export { useRenameMilestonesNode };
