//toggleLogEventsToConsole
import { createSlice } from '@reduxjs/toolkit';

interface IDevState {
  devMode: boolean;
  logEventsToConsole: boolean;
  showDevPanel: boolean;
}

export const initalDevState: IDevState = {
  showDevPanel: false,
  //logEventsToConsole: Boolean(localStorage.getItem('DEV_logEventsToConsole') ?? false),
  logEventsToConsole: Boolean(false),
  devMode: false,
};

export const devSlice = createSlice({
  name: 'devState',
  initialState: initalDevState,
  reducers: {
    toggleShowDevPanel: state => {
      state.showDevPanel = !state.showDevPanel;
    },
    toggleLogEventsToConsole: state => {
      state.logEventsToConsole = !state.logEventsToConsole;
    },
    toggleDevMode: state => {
      state.devMode = !state.devMode;
    },
    // actionWithPayload: (state, action) => {},
  },
});

// Action creators are generated for each case reducer function
export const { toggleLogEventsToConsole, toggleShowDevPanel, toggleDevMode } =
  devSlice.actions;

export default devSlice.reducer;
