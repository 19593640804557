import React from 'react';
import { Button, FormField } from '@amzn/awsui-components-react';
import { parseCsvFile } from '@utils/csv';
import { log } from '@utils/helpers';

export const CsvUploadForm = (props: {
  onCsvUploadEnd?: () => void;
  onCsvUploadStart?: () => void;
  parseCsv: (input: string[][]) => Promise<void> | void;
}): React.ReactElement => {
  const hiddenFileInput = React.createRef<HTMLInputElement>();

  async function fileChangeHandler(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    props.onCsvUploadStart && props.onCsvUploadStart();
    if (event.target.files && event.target.files[0]) {
      loadCsv(event.target.files[0])
        .then(props.parseCsv)
        .then(() => {
          props.onCsvUploadEnd && props.onCsvUploadEnd();
        })
        .catch(e => {
          props.onCsvUploadEnd && props.onCsvUploadEnd();
          log('Error loading csv', true, e);
        });
    }
    if (hiddenFileInput.current?.value) hiddenFileInput.current.value = '';
  }

  async function loadCsv(file: File): Promise<string[][]> {
    try {
      const fileText = await readFile(file);
      return parseCsvFile(fileText);
    } catch (err) {
      log('Error importing CSV', true, { err: err });
    }
    return [];
  }

  function readFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = (): void => {
        if (fr.result) resolve(String(fr.result));
        else reject([]);
      };
      fr.onerror = reject;
      fr.readAsText(file);
    });
  }

  return (
    <FormField
      id="csvUploadForm"
      // label={t('CSV Upload')}
    >
      <input
        ref={hiddenFileInput}
        id="chooseCsvInput"
        type="file"
        hidden
        multiple={false}
        accept="text/csv"
        onChange={fileChangeHandler}
      />
      <Button
        iconName={'upload'}
        formAction="none"
        onClick={(): void => {
          hiddenFileInput.current?.click();
        }}>
        Choose CSV File
      </Button>
    </FormField>
  );
};

export default CsvUploadForm;
