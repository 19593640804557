import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'src/stores/slices/store';
import { useEffect, useRef, useState } from 'react';

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useComponentVisible(initialIsVisible: any): any {
  const ref = useRef<HTMLDivElement>(null);
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  // log('setIsComponentVisible: ', false, setIsComponentVisible);

  const handleHideDropdown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    return (): void => {
      document.removeEventListener('keydown', handleHideDropdown, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}
