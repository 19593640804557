/* eslint-disable @typescript-eslint/no-empty-interface */
export interface OptionalParams {
  appServer?: string;
  cameraEnabled?: boolean;
  cameraModel?: string;
  channelNumber?: number;
  encoderId?: string;
  encoderModel?: string;
  hardwareEnabled?: boolean;
  //(CORP/Unfabric). In edge cases, duplicate server names with different locations
  location?: string;
  myRecorderServerId?: string;
  //if node is Camera, Encoder, Metadata, EncoderCamera, we want to keep track of its recorderServer
  recorderCount?: number;
  region?: string;
  siteCode?: string;
  totalDevices?: number;
}

export const NodeTypes = {
  HardwareCamera: 'HardwareCamera',
  Encoder: 'Encoder',
  EncoderCamera: 'EncoderCamera',
  EncoderMetadata: 'EncoderMetadata',
  Recorder: 'Recorder',
  Site: 'Site',
};

export type DeviceType =
  | 'HardwareCamera'
  | 'Encoder'
  | 'EncoderCamera'
  | 'EncoderMetadata'
  | 'Recorder'
  | 'Site';

export interface Entity extends OptionalParams {
  children?: Entity[];
  id: string;
  name: string;
  type: DeviceType;
}

export interface Recorder extends Entity {
  location: string;
  region: string;
  siteCode: string;
  totalDevices: number;
  type: 'Recorder';
}

export interface Site extends Entity {
  recorderCount: number;
  region: string;
  totalDevices: number;
  type: 'Site';
}

export interface AbstractHardware extends Entity {
  hardwareEnabled: boolean;
  myRecorderServerId: string;
}

export interface AbstractCamera extends AbstractHardware {
  cameraEnabled: boolean;
  cameraModel: string;
}

//Encoder will have mandatory fields: channel, encoderModel, hardwareEnabled, id, name, type
export interface Encoder extends Entity {
  channel: number;
  encoderModel: string;
  myRecorderServerId: string;
  type: 'Encoder';
}

//Camera will have mandatory fields: cameraEnabled, cameraModel, hardwareEnabled, id, name, type
export interface Camera extends AbstractCamera {
  type: 'HardwareCamera';
}

//EncoderCamera will have mandatory fields: encorderId, cameraEnabled, cameraModel, hardwareEnabled, id, name, type
export interface EncoderCamera extends AbstractCamera {
  encoderId: string;
  type: 'EncoderCamera';
}

//Not sure about this one
//EncoderMetadata will have mandatory fields: cameraEnabled, cameraModel, hardwareEnabled, id, name, type
export interface EncoderMetadata extends AbstractCamera {
  type: 'EncoderMetadata';
}
