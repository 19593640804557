import * as React from 'react';

import CollectionPreferences, {
  CollectionPreferencesProps,
} from '@amzn/awsui-components-react/polaris/collection-preferences';

import { labels, testIds } from '@features/milestones/recorders/constants';
import {
  PageSizePreference,
  VISIBLE_CONTENT_OPTIONS,
} from '@features/milestones/recorders/tableConfig';

interface RecordersPreferencesProps {
  pageSize: number;
  updatePreferencesHandler: (
    detail: CollectionPreferencesProps.Preferences<any>
  ) => void;
  visibleColumns: readonly string[];
}

const RecordersPreferences = (
  props: RecordersPreferencesProps
): JSX.Element => {
  const { visibleColumns, pageSize, updatePreferencesHandler } = props;
  return (
    <CollectionPreferences
      data-testid={testIds.PREFERENCES}
      onConfirm={({ detail }) => updatePreferencesHandler(detail)}
      title={labels.tablePreferences.titles.HEADER}
      confirmLabel={labels.tablePreferences.buttons.CONFIRM_BUTTON}
      cancelLabel={labels.tablePreferences.buttons.CANCEL_BUTTON}
      preferences={{ pageSize: pageSize, visibleContent: visibleColumns }}
      pageSizePreference={PageSizePreference}
      visibleContentPreference={{
        title: labels.tablePreferences.titles.VISIBLE_COLUMNS_HEADER,
        options: VISIBLE_CONTENT_OPTIONS,
      }}
    />
  );
};

export { RecordersPreferences };
