/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  ColumnLayout,
  Popover,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { ReactElement } from 'react';
import { NodeTypes } from '@features/milestones/types/api-types';
import CameraIcon from '@features/milestones/tree/components/icons/CameraIcon';
import EncoderIcon from '@features/milestones/tree/components/icons/EncoderIcon';
import EncoderCameraIcon from '@features/milestones/tree/components/icons/EncoderCameraIcon';
import EncoderMetadataIcon from '@features/milestones/tree/components/icons/EncoderMetadataIcon';
import RecorderBadge from '@features/milestones/tree/components/icons/RecorderBadge';
import { UINode } from 'src/features/milestones/types/ui-types';

export interface PopoverContentProps {
  columns?: number;
  edit?: boolean;
  node: UINode;
}

export const PopoverContent = ({
  node,
  edit = false,
  columns = 2,
}: PopoverContentProps): ReactElement => {
  const model = node.cameraModel || node.encoderModel;
  const pattern = /(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})/g;
  const matches = pattern.exec(node.name);
  const ipAddress = matches ? matches[0] : undefined;

  return (
    <ColumnLayout columns={columns} variant="text-grid">
      <SpaceBetween size="l">
        {!edit && (
          <div>
            <Box color="text-body-secondary" fontWeight="bold">
              Name
            </Box>
            <Box color="text-status-info">{node.name}</Box>
          </div>
        )}
        {node.siteCode && (
          <div>
            <Box color="text-body-secondary" fontWeight="bold">
              Site Code - Region
            </Box>
            <Box color="inherit">
              {node.siteCode} - {node.region}
            </Box>
          </div>
        )}
        {node.location && (
          <div>
            <Box color="text-body-secondary" fontWeight="bold">
              Device Location
            </Box>
            <Box color="inherit">{node.location}</Box>
          </div>
        )}
        {node.totalDevices && (
          <div>
            <Box color="text-body-secondary" fontWeight="bold">
              Total Devices
            </Box>
            <Box color="inherit">{node.totalDevices}</Box>
          </div>
        )}
        {model && (
          <div>
            <Box color="text-body-secondary" fontWeight="bold">
              Model
            </Box>
            <Box color="inherit">{model}</Box>
          </div>
        )}
      </SpaceBetween>
      <SpaceBetween size="l">
        {ipAddress && ipAddress.length > 0 && (
          <div>
            <Box color="text-body-secondary" fontWeight="bold">
              Private IP address
            </Box>
            <Box color="inherit">{ipAddress}</Box>
          </div>
        )}
        {node.appServer && (
          <div>
            <Box color="text-body-secondary" fontWeight="bold">
              Management Server
            </Box>
            <Box color="inherit">{node.appServer}</Box>
          </div>
        )}
        {node.channelNumber && (
          <div>
            <Box color="text-body-secondary" fontWeight="bold">
              Channel Number
            </Box>
            <Box color="inherit">{node.channelNumber}</Box>
          </div>
        )}
      </SpaceBetween>
    </ColumnLayout>
  );
};
/**
s * @TODO: This need to parse actual info and pass it to components
 */
const InfoPopover = ({ node }: PopoverContentProps): ReactElement => {
  return (
    <Popover
      fixedWidth
      header={node.type}
      renderWithPortal
      triggerType="custom"
      content={<PopoverContent node={node} />}>
      {node.type === NodeTypes.HardwareCamera && <CameraIcon variant="link" />}
      {node.type === NodeTypes.EncoderCamera && (
        <EncoderCameraIcon variant="link" />
      )}
      {node.type === NodeTypes.EncoderMetadata && (
        <EncoderMetadataIcon variant="success" />
      )}
      {node.type === NodeTypes.Recorder && <RecorderBadge color="green" />}
      {node.type === NodeTypes.Encoder && <EncoderIcon variant="link" />}
    </Popover>
  );
};

export default InfoPopover;
