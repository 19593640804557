import React, { ReactElement } from 'react';
import { Header, StatusIndicator, Table } from '@amzn/awsui-components-react';
import { CsvNode, RenameStatus } from 'src/utils/csv';

type CsvNodeListProps = {
  nodeList: Array<CsvNode>;
  renameStatusMap: Record<string, RenameStatus>;
};

export const CsvNodeList = ({
  nodeList,
  renameStatusMap,
}: CsvNodeListProps): ReactElement => {
  if (nodeList.length === 0) {
    return <></>;
  }
  return (
    <div style={{ overflow: 'auto', width: '100%', height: '70vh' }}>
      <Table
        columnDefinitions={[
          {
            id: 'status',
            header: 'Status',
            cell: (item: CsvNode): ReactElement =>
              getStatusIndicator(renameStatusMap[item.id]),
          },
          {
            id: 'type',
            header: 'Type',
            cell: (item: CsvNode): string => item.type || '-',
          },
          {
            id: 'currentName',
            header: 'Current Name',
            cell: (item: CsvNode): string => item.name || '-',
          },
          {
            id: 'newName',
            header: 'New Name',
            cell: (item: CsvNode): string => item.newName || '-',
          },
          {
            id: 'location',
            header: 'Location',
            cell: (item: CsvNode): string => item.location || '-',
          },
          {
            id: 'region',
            header: 'Region',
            cell: (item: CsvNode): string => item.region || '-',
          },
        ]}
        items={nodeList}
        empty={<></>}
        header={<Header>Items imported from CSV file for renaming</Header>}
      />
    </div>
  );
};

const getStatusIndicator = (renameStatus: RenameStatus): ReactElement => {
  switch (renameStatus) {
    case RenameStatus.Init:
      return <StatusIndicator type={'pending'}>Init</StatusIndicator>;
    case RenameStatus.Loading:
      return <StatusIndicator type={'loading'}>Updating</StatusIndicator>;
    case RenameStatus.Error:
      return <StatusIndicator type={'error'}>Error</StatusIndicator>;
    case RenameStatus.Success:
      return <StatusIndicator type={'success'}>Success</StatusIndicator>;
    default:
      return <StatusIndicator type={'warning'}>Unknown</StatusIndicator>;
  }
};

export default CsvNodeList;
