import { NodeTypes } from 'src/features/milestones/types/api-types';

const DUPLICATE_NAME_ERROR_MESSAGE = 'name is duplicate';
const NAME_LENGTH_ERROR_MESSAGE = 'name can be up to 96 characters';
const INVALID_NAME_ERROR_MESSAGE = 'needs to start with';
const INVALID_SERVERNAME_ERROR_MESSAGE = 'needs to match this pattern';
const CANNOT_RENAME_DECOMMISSIONED_RECORDER_ERROR_MESSAGE =
  'Cannot rename decommissioned recorders';

export function getDecommissionedRecorderErrorMessage(): string {
  return CANNOT_RENAME_DECOMMISSIONED_RECORDER_ERROR_MESSAGE;
}

export function getDuplicateNameErrorMessage(deviceType: string): string {
  return `${deviceType} ${DUPLICATE_NAME_ERROR_MESSAGE}`;
}

export function getNameLengthErrorMessage(deviceType: string): string {
  return `${deviceType} ${NAME_LENGTH_ERROR_MESSAGE}`;
}

export function getInvalidNameErrorMessage(
  deviceType: string,
  namingStandard?: string
): string {
  const errorMessage = [deviceType];
  if (NodeTypes.Recorder === deviceType) {
    errorMessage.push(INVALID_SERVERNAME_ERROR_MESSAGE);
  } else {
    errorMessage.push(INVALID_NAME_ERROR_MESSAGE);
  }

  if (namingStandard) {
    errorMessage.push(`- ${namingStandard}`);
  }
  return errorMessage.join(' ');
}
