/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import { useMutation } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  MilestoneRenameResponse,
  RenameHardwareCameraMutation,
  RenameHardwareCameraMutationVariables,
} from 'src/API';
import { renameHardwareCamera } from 'src/graphql/mutations';

const apiRenameCamera = async (
  variables: RenameHardwareCameraMutationVariables
): Promise<MilestoneRenameResponse> => {
  const response = (await API.graphql(
    graphqlOperation(renameHardwareCamera, variables)
  )) as GraphQLResult<RenameHardwareCameraMutation>;

  const renameCameraResponse = response.data?.renameHardwareCamera;
  if (renameCameraResponse?.status !== 200) {
    throw new Error(
      `Failed renameHardwareCamera with status: ${renameCameraResponse?.status}`
    );
  }

  if (!renameCameraResponse.device) {
    throw new Error(
      `Failed renameHardwareCamera with empty data ${renameCameraResponse?.device}`
    );
  }

  return renameCameraResponse;
};

export const useCameraRenaming = () => {
  const {
    mutate: renameCameraMutation,
    mutateAsync: renameCameraAsync,
    data: response,
    isSuccess,
    isError,
    isPending,
  } = useMutation({
    mutationFn: apiRenameCamera,
  });

  return {
    renameCameraMutation,
    renameCameraAsync,
    response,
    isSuccess,
    isError,
    isPending,
  };
};
