import { TopNavigationProps } from '@amzn/awsui-components-react/polaris/top-navigation';

export const labels = {
  alt: {
    LOGO: 'Amazon Corporate Security',
  },
  titles: {
    TOP_NAVIGATION: 'Milestones',
  },
  buttons: {
    SITES_VIEW: 'Sites View',
    RECORDERS_VIEW: 'Recorders View',
    SELECT_SITES: 'Select Site(s)',
    SWITCH_TO_ISC: 'Switch to ISC',
  },
};

export const ariaLabels = { ...labels.buttons, SELECT_SITES: 'Select Sites' };

export const elementIds = {
  MILESTONES_TOP_NAVIGATION_ID: 'app-header-milestones',
};

export const i18nStrings: TopNavigationProps.I18nStrings = {
  searchIconAriaLabel: 'Search',
  searchDismissIconAriaLabel: 'Close search',
  overflowMenuTriggerText: 'More',
  overflowMenuTitleText: 'All',
  overflowMenuBackIconAriaLabel: 'Back',
  overflowMenuDismissIconAriaLabel: 'Close menu',
};

//Implementing fixed navigation https://polaris.a2z.com/components/top-navigation/?tabId=api
export const styles = { TOP_NAVIGATION_Z_INDEX: 1002 };

export const TestIds = {
  MILESTONES_SHELL_TEST_ID: 'milestones-shell',
};
