import { Entity } from '@features/milestones/types/api-types';
import { duplicateName } from '@utils/helpers';
import {
  getDuplicateNameErrorMessage,
  getInvalidNameErrorMessage,
  getNameLengthErrorMessage,
} from 'src/utils/validations/constants/ErrorMessageConstant';

const encoderDeviceType = 'Encoder';

export const getEncoderNameFieldErrors = (
  allNodeNames: string[],
  node: Entity,
  newName: string
): string[] => {
  const errorMessages: string[] = [];
  if (node.name !== newName && duplicateName(allNodeNames, newName)) {
    errorMessages.push(getDuplicateNameErrorMessage(encoderDeviceType));
  }
  if (newName.length > 96) {
    errorMessages.push(getNameLengthErrorMessage(encoderDeviceType));
  }
  const siteCode: string | undefined = node?.siteCode;
  const regex = new RegExp(
    `^(?:uc_|dcmsn_)?${siteCode}-ENC-[a-zA-Z0-9]+-[a-zA-Z0-9]+`
  );
  if (!regex.test(newName)) {
    errorMessages.push(
      getInvalidNameErrorMessage(
        encoderDeviceType,
        '[uc_|dcmsn_]{SITECODE}-ENC-{FLOOR}-{MODEL}'
      )
    );
  }
  return errorMessages;
};
