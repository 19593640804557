/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { RecorderDeviceTuple } from 'src/API';
import { RecorderIdsBySiteCode, log } from 'src/utils/helpers';
import { CacheKeys, getArrayCacheKey } from './cache-keys';
import { getBatchQueryRecorderDevices } from './useBatchRecorderDevices';

export interface UseMultiRecorderDevicesResponse {
  errors: (Error | null)[];
  isError: boolean;
  isLoading: boolean;
  recordersMap: RecorderDeviceTuple[];
  refetchMultiRecorderDevices: () => void;
  removeQueriesForId: (id: string) => void;
}

export const useMultiRecorderDevices = (
  recorderServerIdsBySiteCode: RecorderIdsBySiteCode
): UseMultiRecorderDevicesResponse => {
  const queryClient = useQueryClient();

  // Note: getBatchRecorders can take an array of ids, but they need to belong to one site.
  // Therefore, execute multiple getBatchRecoderDevices in parallel
  const queries = useQueries({
    /*
    queries: Object.values(recorderServerIdsBySiteCode).map(ids =>
      getBatchQueryRecorderDevices(ids)
    ),
    */
    queries: Object.entries(recorderServerIdsBySiteCode).map(
      ([siteCode, ids]) => {
        log(
          `*** useMultiRecorderDevices: siteCode=${siteCode}; num of ids=${ids.length}`
        );
        return getBatchQueryRecorderDevices(ids);
      }
    ),
    combine: results => {
      return {
        isLoading: results.some(
          query => query.isLoading || query.isFetching || query.isRefetching
        ),
        isError: results.some(query => query.isError),
        errors: results.map(query => query.error),
        recordersMap: results
          .map(query => (query?.data?.length ? query.data : []))
          .flat(1),
      };
    },
  });

  function refetchMultiRecorderDevices() {
    Object.values(recorderServerIdsBySiteCode).forEach(ids => {
      const recorderServersCacheKey = getArrayCacheKey(ids);
      return queryClient.invalidateQueries({
        queryKey: [CacheKeys.BatchDevices, recorderServersCacheKey],
      });
    });
  }

  function removeQueriesForId(id: string) {
    Object.values(recorderServerIdsBySiteCode).forEach(ids => {
      if (ids.includes(id)) {
        const recorderServersCacheKey = getArrayCacheKey(ids);
        return queryClient.removeQueries({
          queryKey: [CacheKeys.BatchDevices, recorderServersCacheKey],
        });
      }
    });
  }

  return {
    errors: queries.errors,
    isError: queries.isError,
    isLoading: queries.isLoading,
    recordersMap: queries.recordersMap,
    refetchMultiRecorderDevices,
    removeQueriesForId,
  };
};
